<template>
  <div id="myChart" :style="{ height: '300px' , width: '1200px'}"></div>
</template>

<script>
  import Vue from 'vue';
  import config from '@/config.js';
  import { mapGetters } from "vuex";

  export default {
    components: {

    },
    data() {
      return {
        day:10,
        currency:"BTC",
        lineX:[],
        lineData:[],
        currencyData:[],

      }
    },
    props:{
      symbol:"",
    },
    created (){

    },

    destroyed(){

    },
    watch:{
      symbol(val){
        switch (val) {
          case '':
            break;
          default:
            this.currency = val.substring(3,6)
            break;
        }
      },
      currency(val){
        this.getCurrencyData(val);
      },
    },
    mounted() {
      if (this.symbol){
        this.currency = this.symbol.substring(3,6)
        this.getAssetLineData();
      }
      // this.getAssetLineData();
      // this.getCurrencyData()
    },
    computed:{
      ...mapGetters(['darkMode','time']),
    },
    methods: {
      // 获取折线图数据
      getAssetLineData(){
        this.lineData = [];
        this.lineX = [];
        let api = config.baseUrl + "/asset/private/getNAV?day=" + this.day;
        this.$http.get(api).then(res=>{
          let data = res.data;
          if (data.code == 200){
            for (var i = 0 ; i < data.result.length ; i++){
              for (let key in data.result[i]) {
                this.lineX.push(this.formatDate(Number(key)).substring(0,5) + " " + this.formatDate(Number(key)).substring(11,16));
                this.lineData.push(Number(data.result[i][key]).toFixed(2));
              }
            }
            // if(this.lineData.length > 0){
            //   var first = Number(this.lineData[0]);
            //   for (var i = 0;i< this.lineData.length;i++){
            //     this.lineData[i] = Number(this.lineData[i]) / first;
            //   }
            // }
            this.drawLine();
          }else {

          }
        })
      },
      // 获取货币价格数据
      getCurrencyData(){
        this.currencyData =[];
        let api = config.baseUrl + "/asset/private/getCurrencyPrice?currency="+this.currency+"&day=" + this.day;
        this.$http.get(api).then(res=>{
          let data = res.data;
          if (data.code == 200){
            for (var i = 0 ;i < data.result.length ;i++){
              this.currencyData.push(data.result[i].price)
            }
            // if(this.currencyData.length > 0){
            //   var first = Number(this.currencyData[0]);
            //   for (var i = 0;i< this.currencyData.length;i++){
            //     this.currencyData[i] = Number(this.currencyData[i]) / first;
            //   }
            // }
            this.drawLine();
          }else {

          }
        })
      },
      // 画图
      drawLine() {
        var sym = this.symbol + " ";
        // 基于准备好的dom，初始化echarts实例
        let myChart = this.$echarts.init(document.getElementById("myChart"), this.darkMode ? 'dark' : '');
        // 先清空echarts中的所有的组件以及图表，然后再重新绘制,不然图不改变
        myChart.clear();
        // 绘制图表
        myChart.setOption({
          // 显示数值
          tooltip: {
            trigger: 'axis',
          },
          // 图例
          legend: {
            data: ['NAV', this.currency]
          },
          xAxis: {
            type: 'category',
            data: this.lineX,
            axisLabel: {
              // interval:1,//横轴信息显示间隔
              showMinLabel: true,// 显示ticker最小的lable
              showMaxLabel: true,// 显示ticker最大的lable
            },
            axisTick: {
              show: false,
            },
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              formatter: '$ {value}'  //刻度标签的内容格式器，支持字符串模板和回调函数两种形式，按照自己需求设置
            },
          },
          series: [
            {
              name: "NAV",
              data: this.lineData,
              type: 'line',
              itemStyle: {
                normal: {
                  color: '#A9CBEE',
                  label: {
                    show: false, //开启显示
                    position: 'top', //在上方显示
                    textStyle: { //数值样式
                      color: this.darkMode ? 'white' : 'black',
                      fontSize: 9
                    },
                    // 格式化显示的数字
                    formatter: function (a) {
                      return ("$" + formatNumber(a.data));
                    },
                  },
                }
              }
            },
            {
              name: this.currency,
              data: this.currencyData,
              type: 'line',
              itemStyle: {
                normal: {
                  color: 'green',
                  label: {
                    show: false, //开启显示
                    position: 'top', //在上方显示
                    textStyle: { //数值样式
                      color: this.darkMode ? 'white' : 'black',
                      fontSize: 9
                    },
                    // 格式化显示的数字
                    formatter: function (a) {
                      return ("$" + formatNumber(a.data));
                    },
                  },
                }
              }
            },
          ],

        });
      },
      // 格式化时间
      formatDate(timestamp){
        if('UTCTime' == this.time){
          return formatUTCTime(timestamp);
        }else{
          return formatTime(timestamp);
        }
      },
    }
  }

</script>

<style>

</style>
