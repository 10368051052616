<template>
	<div>

	</div>
</template>
<style>

</style>
<script>
    import config from '@/config.js';
    import { mapGetters } from "vuex";
    export default {
        data(){
            return{
              tickerTimer: null,
              tickers:[],
              symbolPrice: {},
              api:null,
              environment: config.environment,
            }
        },
        created(){

        },
        mounted(){
          this.api = config.baseUrl + "/exchange/kraken/futures/public/getTickers";
          this.$store.dispatch('marketPriceHandler', null);
          this.getTicker();
          this.tickerTimer = setInterval(() => {
            this.getTicker();
          }, 8000);
        },
        computed: {
            ...mapGetters([ 'futuresDecimalNumbers', 'lastMarketPrice', 'responsive', 'networkStatus'])
        },
        watch:{

        },
        methods: {
          // 获取kraken市场价
          getTicker(){
            // 未联网时不发送请求
            if (!this.networkStatus) {
              return;
            }
            if(this.$route.path.indexOf('/futures') > -1 && this.api != null){
              this.$http.get(this.api).then(res=>{
                  let data = res.data;
                  if(data.result == "success" ){
                      for(var i = 0 ; i < data.tickers.length ; i++){
                          switch(data.tickers[i].symbol){
                              case "pi_xbtusd":
                                  this.tickers.push(data.tickers[i])
                                  break;
                              case "pi_ethusd":
                                  this.tickers.push(data.tickers[i])
                                  break;
                              case "pi_bchusd":
                                  this.tickers.push(data.tickers[i])
                                  break;
                              case "pi_ltcusd":
                                  this.tickers.push(data.tickers[i])
                                  break;
                              case "pi_xrpusd":
                                  this.tickers.push(data.tickers[i])
                                  break;
                              default:
                                  break;
                          }
                      }
                  }
                  if(this.tickers.length > 0){
                      for(var i = 0 ; i < this.tickers.length ; i++){
                          let symbol = this.getIobotsSymbol("kraken","futures",this.tickers[i].symbol.toUpperCase());
                          let currentPrice = Number(this.tickers[i].last).toFixed(this.futuresDecimalNumbers[symbol]);
                          let bidPrice = Number(this.tickers[i].bid).toFixed(this.futuresDecimalNumbers[symbol]);
                          let askPrice = Number(this.tickers[i].ask).toFixed(this.futuresDecimalNumbers[symbol]);
                          let lastCurrentPrice = 0;
                          let lastBidPrice = 0;
                          let lastAskPrice = 0;
                          let currentColor = 'green--text';
                          let bidColor = 'green--text';
                          let askColor = 'green--text';
                          if (this.lastMarketPrice != null && this.lastMarketPrice[symbol]) {
                              lastCurrentPrice = Number(this.lastMarketPrice[symbol].current.price);
                              lastBidPrice = Number(this.lastMarketPrice[symbol].bid.price);
                              lastAskPrice = Number(this.lastMarketPrice[symbol].ask.price);
                              // current
                              if (Number(currentPrice) > Number(lastCurrentPrice)) {
                                  currentColor = 'green--text';
                              } else if (Number(currentPrice) < Number(lastCurrentPrice)){
                                  currentColor = 'red--text';
                              } else {
                                  currentColor = this.lastMarketPrice[symbol].current.color;
                              }
                              // bid
                              if (Number(bidPrice) > Number(lastBidPrice)) {
                                  bidColor = 'green--text';
                              } else if (Number(bidPrice) < Number(lastBidPrice)){
                                  bidColor = 'red--text';
                              } else {
                                  bidColor = this.lastMarketPrice[symbol].bid.color;
                              }
                              // ask
                              if (Number(askPrice) > Number(lastAskPrice)) {
                                  askColor = 'green--text';
                              } else if (Number(askPrice) < Number(lastAskPrice)){
                                  askColor = 'red--text';
                              } else {
                                  askColor = this.lastMarketPrice[symbol].ask.color;
                              }
                          }
                          let current = {
                              'price': currentPrice,
                              'color': currentColor
                          };
                          let bid = {
                              'price': bidPrice,
                              'color': bidColor
                          };
                          let ask = {
                              'price': askPrice,
                              'color': askColor
                          };
                          this.symbolPrice[symbol] = {
                              'current': current,
                              'bid': bid,
                              'ask': ask
                          }
                      }
                      this.$store.dispatch('marketPriceHandler', this.symbolPrice);
                  }
                })
              }
            },
            // 把交易所符号对转为iobots的交易对
            getIobotsSymbol(exchangeCode, tradingCategory, symbol){
                return parseExchangeSymbolToIobotsSymbol(exchangeCode, tradingCategory, symbol);
            },
        },
        beforeDestroy() {
          if(this.tickerTimer) {
            clearInterval(this.tickerTimer);
          }
        },

    }
</script>
