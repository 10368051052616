<template>
    <div>
        <!-- *******************删除筛选放置的进入订单的弹窗******************* -->
        <v-row>
          <v-dialog v-model="deletePendingEntryDialog" persistent max-width="490" no-click-animation>
            <v-card>
              <v-card-title class="grey lighten-2">
                <span class="black--text font-md">{{$t("common.delete")}}</span>
                <v-spacer></v-spacer>
                <v-btn light icon @click="closeDeletePendingEntryDialog">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-container>
                <v-row justify="center">
                  <v-col cols="12" class="text-center" v-if="orderSearch.exchange == null && orderSearch.symbol == null && orderSearch.side == null && orderSearch.strategy == null && orderSearch.category == null">
                    {{ $t('common.noFilter') }}
                  </v-col>
                  <v-col cols="12" v-else>
                    <span>{{ $t('futuresTrading.deleteMessage1') }}</span>
                    <span><b>{{ orderSearch.exchange != null ? getExchangeName(orderSearch.exchange) + ' ' : ''}}</b></span>
                    <span><b>{{ orderSearch.symbol != null ? orderSearch.symbol  + ' ' : ''}}</b></span>
                    <span><b>{{ orderSearch.side != null ? orderSearch.side  + ' ' : '' }}</b></span>
                    <span><b>{{ orderSearch.strategy != null ? orderSearch.strategy  + ' ' : '' }}</b></span>
                    <span><b>{{ orderSearch.category != null ? orderSearch.category  + ' ' : '' }}</b></span>
                    <span>{{ $t('futuresTrading.deleteMessage2') }}</span>
                  </v-col>
                </v-row>
              </v-container>
              <v-divider v-if="orderSearch.exchange != null || orderSearch.symbol != null || orderSearch.side != null || orderSearch.strategy != null || orderSearch.category != null"></v-divider>
              <v-card-actions class="pr-5" v-if="orderSearch.exchange != null || orderSearch.symbol != null || orderSearch.side != null || orderSearch.strategy != null || orderSearch.category != null">
                <v-spacer></v-spacer>
                <v-btn small tile class="blue white--text" :loading="deleteLoading" @click="submitDelete">{{$t("common.ok")}}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
    </div>
</template>
<style>

</style>
<script>
    import Vue from 'vue'
    import config from '@/config.js';
    import { mapGetters } from "vuex";
    export default {
        data(){
            return{
              // 加载中
              deleteLoading: false
            }
        },
        // deletePendingEntryDialog: false
        // orderSearch: {
        //   exchange: null,
        //   symbol: null,
        //   side: null,
        //   strategy: null,
        //   category: null,
        // }
        props:[ 'deletePendingEntryDialog', 'orderSearch'],
        created(){

        },
        mounted(){

        },
        computed: {
          ...mapGetters(['exchanges']),
        },
        watch:{

        },
        methods: {
          // 提交请求
          submitDelete(){
            this.deleteLoading = true;
            let api = config.baseUrl + '/order/futures/private/entry/delete?1=1';
            if (this.orderSearch.exchange) {
              api = api + '&exchange=' + this.orderSearch.exchange;
            }
            if (this.orderSearch.symbol) {
              api = api + '&symbol=' + this.orderSearch.symbol;
            }
            if (this.orderSearch.side) {
              api = api + '&side=' + this.orderSearch.side;
            }
            if (this.orderSearch.strategy) {
              api = api + '&strategy=' + this.orderSearch.strategy;
            }
            if (this.orderSearch.category) {
              api = api + '&category=' + this.orderSearch.category;
            }
            this.$http.delete(api).then(response => {
              let data = response.data;
              this.closeDeletePendingEntryDialog();
              this.$store.dispatch('snackbarMessageHandler', data.message);
            }, error => {
              this.closeDeletePendingEntryDialog();
              this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
            });
          },
          // 关闭弹窗
          closeDeletePendingEntryDialog(){
            this.deleteLoading = false;
            this.$emit("closeDeletePendingEntryDialog", false);
          },
          // 交易所code转为交易所id
          getExchangeName(code) {
            for (let i = 0; i < this.exchanges.length; i++) {
              if (code == this.exchanges[i].code) {
                return this.exchanges[i].name;
              }
            }
          },
        },
    }
</script>
