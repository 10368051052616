<template>
    <div>
        <!-- *******************删除全部放置的进入订单的弹窗******************* -->
        <v-row>
          <v-dialog v-model="deleteAllPendingEntryDialog" persistent max-width="490" no-click-animation>
            <v-card>
              <v-card-title class="grey lighten-2">
                <span class="black--text font-md">{{$t("common.deleteAll")}}</span>
                <v-spacer></v-spacer>
                <v-btn light icon @click="closeDeleteAllPendingEntryDialog">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-container class="pb-0">
                <v-row justify="center">
                  <v-col cols="12">
                    <span>{{ $t('futuresTrading.deleteMessage') }}</span>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
              </v-container>
              <v-card-actions class="pr-5">
                <v-spacer></v-spacer>
                <v-btn small tile class="blue white--text" :loading="deleteAllLoading" @click="submitDeleteAll">{{$t("common.ok")}}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
    </div>
</template>
<style>

</style>
<script>
    import Vue from 'vue'
    import config from '@/config.js';
    export default {
        data(){
            return{
              // 加载中
              deleteAllLoading: false
            }
        },
        // deleteAllPendingEntryDialog: false
        props:[ 'deleteAllPendingEntryDialog' ],
        created(){

        },
        mounted(){

        },
        computed: {

        },
        watch:{

        },
        methods: {
          // 提交请求
          submitDeleteAll(){
            this.deleteAllLoading = true;
            let api = config.baseUrl + '/order/futures/private/entry/deleteAll';
            this.$http.delete(api).then(response => {
              let data = response.data;
              this.closeDeleteAllPendingEntryDialog();
              this.$store.dispatch('snackbarMessageHandler', data.message);
            }, error => {
              this.closeDeleteAllPendingEntryDialog();
              this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
            });
          },
          // 关闭弹窗
          closeDeleteAllPendingEntryDialog(){
            this.deleteAllLoading = false;
            this.$emit("closeDeleteAllPendingEntryDialog", false);
          }
        },
    }
</script>
