<template>
  <div>
    <div class="d-none">
      <!-- 订阅价格数据 -->
      <div v-if="exchangeCode == 'kraken'">
        <kraken-futures-market-price></kraken-futures-market-price>
      </div>
      <div v-if="exchangeCode == 'okex'">
        <okex-futures-market-price></okex-futures-market-price>
      </div>
      <div v-if="exchangeCode == 'binance'">
        <binance-futures-market-price></binance-futures-market-price>
      </div>
    </div>
    <v-container fluid class="grid-list-xl" :class="[!fullScreen?'mt-12':'pt-0 mt-12']" v-if="responsive == 'PC' && !fullScreen">
      <v-navigation-drawer app v-model="drawer" fixed :width="370" class="z-index flex-m" permanent :mini-variant.sync="mini"  mini-variant-width="0">
        <!-- <v-list dense> -->
        <template>
          <v-row class="mt-7 mr-0">
            <v-col cols="2" class="pr-2 mr-2">
              <v-card flat tile :class="[darkMode?'black':'']">
                <div class="grey--text text--lighten-1 fs-10" v-if="exchangeSupportedFuturesSymbols != null">
                  <div :class="[symbolObject.supported ? '' : 'cursor-none icon-grey']" v-for="(symbolObject, i) in exchangeSupportedFuturesSymbols[exchangeCode]" :key="i" v-if="symbolObject.supported">
                    <v-tooltip right open-delay="100" close-delay="100">
                      <template v-slot:activator="{ on }">
                        <span v-on="on">
                          <router-link class="del-underline" :to="'/trading/futures/' + exchangeCode + '/' + symbolObject.symbol">
                            <!-- <v-card hover tile flat class="del-underline symbol" :class="[symbolObject.symbol == symbol ? (darkMode ?'grey darken-4': 'white fs-22') : (darkMode?'darkbg':'greybg')]" @click="gotoFuturesOrder(symbolObject.symbol)">
                              <app-icon :symbol="symbolObject.symbol"></app-icon>
                            </v-card> -->
                            <v-card hover tile flat class="del-underline swap-coin-symbol" :class="[symbolObject.symbol == symbol ? (darkMode ?'grey darken-4': 'white fs-10') : (darkMode?'darkbg':'greybg')]" @click="gotoFuturesOrder(symbolObject.symbol)">
                              <span>{{ getBaseCurrency(symbolObject.symbol) }}</span>
                            </v-card>
                          </router-link>
                        </span>
                      </template>
                      <span>{{ symbolObject.symbol }}</span>
                    </v-tooltip>
                  </div>
                </div>
              </v-card>
            </v-col>
            <v-col cols="9" class="px-0 mx-0">
              <v-row no-gutters>
                <v-col cols="1" class="mt-3 pt-0">
                  <v-icon color="grey" small @click="gotoMoreSignals">mdi-arrow-left</v-icon>
                </v-col>
                <v-col cols="9" offset-md="1" class="mt-4 pt-0">
                  <div v-if="isSetApiKey && accounts != null" class="fs-12 text-right">
                    <div v-for="(symbolObject, i) in exchangeSupportedFuturesSymbols[exchangeCode]" :key="i">
                      <div v-if="symbolObject.symbol == symbol">
                        {{$t("common.equity")}}: {{ formatNum(accounts[symbolObject.baseCurrency].equity) + ' ' + symbolObject.baseCurrency }}<br>
                        {{$t("futuresTrading.avaliable")}}: {{ formatNum(accounts[symbolObject.baseCurrency].avaliable) + ' ' + symbolObject.baseCurrency }}<br>
                        {{$t("futuresTrading.balance")}}: {{ formatNum(accounts[symbolObject.baseCurrency].balance) + ' ' + symbolObject.baseCurrency }}
                      </div>
                    </div>
                  </div>
                  <div v-if="!isSetApiKey" class="text-lg-right fs-12">
                    {{$t("common.equity")}}: --<br>
                    {{$t("futuresTrading.avaliable")}}: --<br>
                    {{$t("futuresTrading.balance")}}: --
                  </div>
                  <div class="lightred--text mt-7 text-right">
                    <span class="fs-14">
                      <span class="mr-2">{{ symbol }} :</span>
                      <span v-if="marketPrice != null && marketPrice[symbol] != null && marketPrice[symbol].current != null" :class="marketPrice[symbol].current.color" class="pointer fs-18" @click="enterOrderPrice(marketPrice[symbol].current.price)">{{ formatNum(marketPrice[symbol].current.price)}}</span>
                      <span v-else>--</span>
                    </span>
                  </div>
                  <div class="mt-7 mr-3">
                    <change-percentage :currency="getBaseCurrency(symbol)"></change-percentage>
                  </div>
                </v-col>
              </v-row>
              <v-row class="mt-8" justify="center" dense>
                <!-- *******************发送订单的表单******************* -->
                <v-form ref="orderForm">
                  <v-col cols="12">
                    <div class="text-center grey--text text--darken-1 pointer">
                      <span class="pointer fs-20 mr-8" :class="[side == 'open' ? 'fs-28 grey--text darken-3' : '']"
                        @click="changeSide('open')">{{$t("futuresTrading.open")}} </span>
                      <span class="pointer fs-20" :class="[side == 'close' ? 'fs-28  grey--text darken-3' : '']" @click="changeSide('close')">{{$t("futuresTrading.close")}}</span>
                    </div>
                  </v-col>
                  <v-col cols="12" class="mb-10">
                    <div class="text-center grey--text text--darken-1 pointer mr-5">
                      <span class="pointer fs-20 mr-8" :class="[orderSide == 'buy' ? 'fs-36 teal--text darken-4' : '']"
                        @click="changeOrderSide('buy')">{{$t("futuresTrading.buy")}}</span>
                      <span class="pointer fs-20" :class="[orderSide == 'sell' ? 'fs-36 red--text' : '']" @click="changeOrderSide('sell')">{{$t("futuresTrading.sell")}}</span>
                    </div>
                  </v-col>
                  <v-col cols="12" class="py-0 px-6">
                    <v-select v-model="orderForm.exchangeId" :items="exchanges" :label="$t('futuresTrading.exchange')"
                      item-text="name" item-value="id" :rules="[v => !!v || $t('futuresTrading.requiredMessage.exchangeMsg')]"
                      required @change="changeExchange"></v-select>
                  </v-col>
                  <!-- strategy -->
                  <v-col cols="12" class="py-0 px-6">
                    <v-select v-model="orderForm.strategy" :items="strategies" :label="$t('common.strategy')" required>
                      <template v-slot:selection>
                          <span :style="{color: getColorByStrategy(orderForm.strategy)}">{{ orderForm.strategy }}</span>
                      </template>
                    </v-select>
                  </v-col>
                  <!-- category -->
                  <v-col cols="12" class="py-0 px-6">
                    <v-select v-model="orderForm.category" :items="getCategoriesByStrategy(orderForm.strategy)" :label="$t('common.category')"
                      required></v-select>
                  </v-col>
                  <!-- size -->
                  <v-col cols="12" class="py-0 px-6">
                    <v-row no-gutters dense>
                      <v-col cols="11" class="pl-1">
                        <v-text-field v-model="orderForm.size" class="orderForm" type="number" min="0" :label="$t('futuresTrading.size')" :suffix="sizeDollarValue" @keydown.enter="nextFocus('orderForm', $event)" required></v-text-field>
                      </v-col>
                      <v-col cols="1" class="mt-4 pl-1">
                        <v-menu :close-on-content-click="false" :nudge-width="120" offset-x nudge-left="180" nudge-top="180"
                          z-index="99">
                          <template v-slot:activator="{ on }">
                            <v-icon color="blue" small class="pointer" v-on="on">mdi-information</v-icon>
                          </template>
                          <v-card>
                            <div class="api mx-2 pa-3">
                              <div class="fs-16">{{$t('futuresTrading.contractFaceValue')}}</div>
                              <v-divider></v-divider>
                              <div>Kraken: </div>
                              <div>{{$t('futuresTrading.all')}}: 1 = $1</div>
                              <v-divider></v-divider>
                              <div>OKEx: </div>
                              <div>BTC: 1 = $100</div>
                              <div>{{$t('futuresTrading.other')}}: 1 = $10</div>
                              <v-divider></v-divider>
                              <div>Binance: </div>
                              <div>BTC: 1 = $100</div>
                              <div>{{$t('futuresTrading.other')}}: 1 = $10</div>
                            </div>
                          </v-card>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-col>
                  <!-- price -->
                  <v-col cols="12" class="py-0 px-6" v-if="orderForm.strategy == 'PP' || orderForm.strategy == 'CPP'">
                    <v-row dense>
                      <v-col cols="9">
                        <v-text-field v-model="orderForm.price" :step="getTickSize(orderForm.symbol)" :disabled="orderForm.priceType == 'bbo'" class="orderForm" type="number" min="0" :label="$t('futuresTrading.price')" @keydown.enter="nextFocus('orderForm', $event)" required></v-text-field>
                      </v-col>
                      <v-col cols="3" class="align-self-sm-center">
                        <v-btn small tile dark color="blue" :outlined="orderForm.priceType != 'bbo'" @click="changePriceType('new')">BBO</v-btn>
                      </v-col>
                    </v-row> 
                  </v-col>
                  <v-col cols="12" class="py-0 px-6" v-else>
                    <v-row dense>
                      <v-col cols="9">
                        <v-text-field v-model="orderForm.price" :step="getTickSize(orderForm.symbol)" :disabled="orderForm.priceType == 'bbo'" class="orderForm" type="number" min="0" :label="$t('futuresTrading.price')" @keydown.enter="nextFocus('orderForm', $event)"
                          hide-details required></v-text-field>
                      </v-col>
                      <v-col cols="3" class="align-self-sm-center">
                        <v-btn small tile dark color="blue" :outlined="orderForm.priceType != 'bbo'" @click="changePriceType('new')">BBO</v-btn>
                      </v-col>
                      <v-col cols="12">
                        <v-select v-model="orderForm.renkoEntryMultiplier" :items="renkoMultipliers" item-text="text" item-value="value" :disabled="orderForm.priceType == 'bbo'" :label="$t('common.renkoEntryMultiplier')" hide-details required></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                  <!-- Margin -->
                  <v-col cols="12" class="pb-0 px-6" v-if="orderForm.price > 0 && orderForm.size > 0">
                      <span>{{ $t('common.margin') + ': ' + requiredMargin + ' ' + getBaseCurrency(orderForm.symbol) }}</span>
                  </v-col>
                  <!-- take profit -->
                  <v-col cols="12" v-if="'open' == side" class="pb-0 px-6">
                    <v-row dense>
                      <v-col cols="6" class="pa-0 align-self-sm-center">
                        <v-checkbox dense color="lightred" v-model="takeProfitEnabled" :label="$t('futuresTrading.takeProfit')"></v-checkbox>
                      </v-col>
                      <v-col cols="6" class="pa-0">
                        <v-text-field dense class="orderForm" v-if="takeProfitEnabled" v-model="orderForm.takeProfitPrice" type="number" min="0" :step="getTickSize(orderForm.symbol)" :disabled="orderForm.priceType == 'bbo'" @keydown.enter="nextFocus('orderForm', $event)"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col col="4" class="mx-5 pa-0 ma-0 py-0" v-if="'open' == side">
                  </v-col>
                  <v-col cols="12" class="py-1 px-6">
                    <span v-if="accounts != null && accounts[getBaseCurrency(orderForm.symbol)].avaliable - requiredMargin >= 0">
                      <v-btn tile block :disabled="isDisabledOrderButton" :loading="submitBtnLoading" color="white--text"
                        class="orderForm" :class="['long' == buttonSide || 'buy' ==  buttonSide? 'green' : 'red']" @click="judgeParameters(true)">
                        {{ getOrderButtonSide() }}
                      </v-btn>
                    </span>
                    <span v-else>
                       <v-btn tile block disabled color="white--text" class="orderForm">
                          {{ $t('common.insufficientMargin') }}
                       </v-btn>
                      </span>
                  </v-col>
                </v-form>
              </v-row>
            </v-col>
          </v-row>
        </template>
        <!-- </v-list> -->
      </v-navigation-drawer>
    </v-container>
    <!-- 中间显示 -->
    <v-container fluid class="flex-m grid-list-xl px-0 pb-0 pt-0 pa-0" v-if="responsive == 'PC' && !fullScreen">
      <v-app-bar outlined class="Vue-toolbar mt-9 pt-3 z-index-1" fixed :class="[darkMode?'logsDarkTabBg':'grey lighten-3']">
        <div class="fs-28 fw-normal" :class="[fullScreen ? '' : ( mini ? 'pl-5' : 'ml-420')]"  style="display: flex; flex-wrap: wrap;">
          <span class="pl-0 pr-3 align-self-sm-center">
              <v-icon @click.stop="mini = !mini" v-if="!mini">mdi-chevron-left</v-icon>
              <v-icon @click.stop="mini = !mini" v-if="mini">mdi-chevron-right</v-icon>
          </span>
          <span class="pointer exchange-futures" @click="openExchangePage">{{ getExchangeName(exchangeCode)+" "+$t('common.futures') }}
          </span>
          <span> :</span>
          <span class="ml-2">{{ symbol }}</span>
          <span class="ml-2">
            <search-coin></search-coin>
          </span>
          <span class="ml-2">
            <v-btn x-small tile outlined class="pointer mr-2 mb-2 text-none" :class="childComponent == 'candle' ? 'lineblue--text' : ''" @click="childComponent = 'candle'">{{ $t('common.candle') }}</v-btn>
            <v-btn x-small tile outlined class="pointer mr-2 mb-2 text-none" :class="childComponent == 'heikinAshi' ? 'lineblue--text' : ''" @click="childComponent = 'heikinAshi'">{{ $t('common.heikinAshi') }}</v-btn>
            <v-btn x-small tile outlined class="pointer mr-2 mb-2 text-none" :class="childComponent == 'renko' ? 'lineblue--text' : ''" @click="childComponent = 'renko'">{{ $t('common.renko') }}</v-btn>
            <v-btn x-small tile outlined class="pointer mr-2 mb-2 text-none" :class="childComponent == 'summary'? 'lineblue--text' : ''" @click="childComponent = 'summary'">{{ $t('common.summary') }}</v-btn>
          </span>
        </div>
        <div class="navbar-right fs-36 mr-3 mb-2 txt-l">
          <sub class="fs-14">{{$t("futuresTrading.bid")}} </sub>
          <span class="green--text fw-normal">
            <span v-if="marketPrice != null  && marketPrice[symbol] != null && marketPrice[symbol].bid != null" :class="marketPrice[symbol].bid.color" class="pointer" @click="enterOrderPrice(marketPrice[symbol].bid.price)">{{ formatNum(marketPrice[symbol].bid.price)}}</span>
            <span v-else>--</span>
          </span>
          <span class="fw-normal mx-2"> |</span>
          <sub class="fs-14">{{$t("futuresTrading.ask")}} </sub>
          <span class="red--text fw-normal">
            <span v-if="marketPrice != null && marketPrice[symbol] != null && marketPrice[symbol].ask != null" :class="marketPrice[symbol].ask.color" class="pointer" @click="enterOrderPrice(marketPrice[symbol].ask.price)">{{ formatNum(marketPrice[symbol].ask.price)}}</span>
            <span v-else>--</span>
          </span>
        </div>
      </v-app-bar>
      <v-row class="px-2 mt-3">
        <v-col cols="12" class="mr-5" :class="[fullScreen ? '' : ( mini ? 'pl-5' : 'ml-420')]">
          <trading-candle-chart v-if="childComponent == 'candle'" :exchange="exchangeCode" :tradingCategory="'futures'" :symbol="orderForm.symbol" :currency="getBaseCurrency(orderForm.symbol)" :openPrices="getOpenPrices()" :openPositionsPrices="getOpenPositionsPrices()"></trading-candle-chart>
          <trading-heikin-ashi-chart v-if="childComponent == 'heikinAshi'" :exchange="exchangeCode" :tradingCategory="'futures'" :symbol="orderForm.symbol" :currency="getBaseCurrency(orderForm.symbol)" :openPrices="getOpenPrices()" :openPositionsPrices="getOpenPositionsPrices()"></trading-heikin-ashi-chart>
          <trading-renko-chart v-show="childComponent == 'renko'" ref='tradingRenkoChart' :strategy="orderForm.strategy" :symbol="orderForm.symbol" :tradingCategory="'futures'" :childComponent="childComponent" :takeProfitPrice="renkoChartTakeProfitPrice" :stopLossPrice="renkoChartStopLossPrice" @changeRenkoStrategy="receiveChangeRenkoStrategy" @transferPrice="receiveRenkoPrice" @resetTakeAndStopPrice="receiveResetTakeAndStopPrice"></trading-renko-chart>
          <asset-summary v-show="childComponent == 'summary'" :mini="mini" ref='summary' :hideNonePositions="hideNonePositions"></asset-summary>
        </v-col>
      </v-row>
    </v-container>
    <!-- 订单表格 -->
    <!-- <v-content class="flex-m" v-if="responsive == 'PC'" :class="[!fullScreen?'pt-0':'mt-0 pt-9']"> -->
      <v-container fluid class="flex-m grid-list-xl px-0 pb-0 pt-0 pa-0" v-if="responsive == 'PC'" :class="[!fullScreen?'pt-0':'mt-0 pt-10']">
      <v-row :class="[fullScreen ? 'ml-0' : ( mini ? 'pl-5' : 'ml-420')]">
        <v-col cols="12" class="pl-0 pt-0 pb-16 mb-16">
          <!-- tab的标签 -->
          <v-tabs slider-color="lightred" :slider-size="5" color="grey">
            <v-tab @click="tab='pendingEntryOrders'" class="px-3">
              <v-badge color="vividyellow" light :value="pendingEntryOrdersTotalElements">
                <template v-slot:badge>
                  <span v-if=" pendingEntryOrdersTotalElements > 0" class="black--text">{{pendingEntryOrdersTotalElements}}</span>
                </template>
                <span>{{$t("futuresTrading.pendingEntryOrders")}}</span>
              </v-badge>
            </v-tab>
            <!-- 第二页 -->
            <v-tab @click="tab='pendingExitOrders'" class="px-3">
              <v-badge color="vividyellow" :value="pendingExitOrdersTotalElements">
                <template v-slot:badge v-if="pendingExitOrdersTotalElements > 0">
                  <span class="black--text">{{pendingExitOrdersTotalElements}}</span>
                </template>
                <span>{{$t("futuresTrading.pendingExitOrders")}}</span>
              </v-badge>
            </v-tab>
            <!-- 第三页 -->
            <v-tab @click="tab='submittedExchangeOrders'" class="px-3">
              <span>{{$t("futuresTrading.submittedExchangeOrders")}}</span>
            </v-tab>
            <!-- 第四页 -->
            <v-tab @click="tab='unfilledExchangeOrders'" class="px-3">
              <v-badge color="vividyellow" :value="unfilledExchangeOrdersTotalElements">
                <template v-slot:badge v-if=" unfilledExchangeOrdersTotalElements > 0">
                  <span class="black--text">{{unfilledExchangeOrdersTotalElements}}</span>
                </template>
                <span>{{$t("futuresTrading.unfilledExchangeOrders")}}</span>
              </v-badge>
            </v-tab>
            <!-- 第五页 -->
            <v-tab @click="tab='filledExchangeOrders'" class="px-3">
              <span>{{$t("futuresTrading.filledExchangeOrders")}}</span>
            </v-tab>
            <!-- 第六页 -->
            <v-tab @click="tab='openPositions'" class="px-3">
              <v-badge color="vividyellow" :value="openPositionsOrdersTotalElements">
                <template v-slot:badge v-if=" openPositionsOrdersTotalElements > 0">
                  <span class="black--text">{{openPositionsOrdersTotalElements}}</span>
                </template>
                <span>{{$t("futuresTrading.openPositions")}}</span>
              </v-badge>
            </v-tab>
            <!-- 第七页 -->
            <v-tab @click="tab='openPositionsAtExchanges'" class="px-3">
              <v-badge color="vividyellow" :value="openPositionsAtExchanges.length">
                <template v-slot:badge v-if="openPositionsAtExchanges.length > 0">
                  <span class="black--text">{{openPositionsAtExchanges.length}}</span>
                </template>
                <span>{{$t("futuresTrading.openPositionsAtExchanges")}}</span>
              </v-badge>
            </v-tab>
            <v-spacer></v-spacer>
            <div class="mt-2">
              <!-- 刷新按钮 -->
              <v-btn :title="$t('futuresTrading.refresh')" icon @click="refreshAllFuturesOrders">
                <v-icon medium color="lightred">mdi-refresh</v-icon>
              </v-btn>
              <!-- 批量编辑 -->
              <!-- <v-btn :title="$t('futuresTrading.batchEdit')" icon @click="gotoBatchOrder">
                <v-icon medium color="lightred">mdi-pencil</v-icon>
              </v-btn> -->
              <!-- 隐藏未持仓货币 -->
              <v-btn :title="$t('common.hideNonePositions')" icon class="mx-0" @click="hideNonePositions = !hideNonePositions">
                <v-icon medium color="lightred" v-if="!hideNonePositions">mdi-checkbox-blank-outline</v-icon>
                <v-icon medium color="lightred" v-if="hideNonePositions">mdi-checkbox-marked</v-icon>
              </v-btn>
              <!-- 交易记录 -->
              <router-link class="del-underline" to="/user/logs#coin-swap-records">
                <v-btn :title="$t('log.orderHistory')" icon class="mx-0">
                  <v-icon medium color="lightred">mdi mdi-file-document-box</v-icon>
                </v-btn>
              </router-link>
              <!-- 全屏按钮 -->
              <v-btn :title="$t('futuresTrading.fullScreen')" icon class="mx-0" @click="fullScreen = !fullScreen">
                <v-icon medium color="lightred" v-if="!fullScreen">mdi-fullscreen</v-icon>
                <v-icon medium color="lightred" v-if="fullScreen">mdi-fullscreen-exit</v-icon>
              </v-btn>
            </div>
          </v-tabs>
          <!-- 内容 -->
          <v-tabs-items v-model="tab">
            <!-- 第一页未提交至交易所的订单 PENDING ENTRY ORDERS -->
            <v-tab-item value="pendingEntryOrders">
              <v-row>
                <v-col cols="12" class="ml-2">
                  <v-data-table dense :headers="pendingEntryOrdersHeader" :items="pendingEntryOrders"
                    :items-per-page="pageSize" hide-default-footer :no-data-text="pendingEntryLoading ? '':$t('futuresTrading.noDataAvailable')">
                    <template v-slot:item="props">
                      <tr @click="emitRenkoChartPrice(props.item)">
                        <td>
                          <v-tooltip bottom open-delay="100" close-delay="100">
                            <template v-slot:activator="{ on }">
                              <span v-on="on" :class="CommonJS.formatExchangeColor(props.item.exchangeName)">{{ props.item.exchangeName }}</span>
                            </template>
                            <span>{{ props.item.id }}</span>
                          </v-tooltip>
                        </td>
                        <td>{{ props.item.symbol }}</td>
                        <td :class="props.item.side == 'open_long'|| props.item.side == 'close_buy' ? 'green--text' : 'red--text' ">
                          <span v-side="props.item.side"></span>
                        </td>
                        <td class="text-right">
                          {{ (props.item.side == 'open_short'|| props.item.side == 'close_sell') ? '-' : '' }}{{ formatNum(props.item.size) }}
                        </td>
                        <td class="text-right">{{ formatNum(props.item.price.toFixed(futuresDecimalNumbers[props.item.symbol])) }}</td>
                        <!-- takeProfitPrice -->
                        <td class="text-right">
                          <!-- <span>{{ formatNum(props.item.takeProfitPrice > 0 ? props.item.takeProfitPrice.toFixed(futuresDecimalNumbers[props.item.symbol]) : '--') }}</span>
                          <span> / </span>
                          <span :class="[(props.item.side == 'open_long' && props.item.takeProfitPrice > 0 && props.item.takeProfitPrice < props.item.price) ? 'red--text' : '', (props.item.side == 'open_short' && props.item.takeProfitPrice > props.item.price) ? 'red--text' : '']">{{ calcTakeProfitEstimatedPL(props.item) }}</span> -->
                          <v-tooltip bottom open-delay="100" close-delay="100">
                            <template v-slot:activator="{ on }">
                              <span v-on="on">
                                <span v-if="props.item.takeProfitPrice == 0">--</span>
                                <span v-else-if="props.item.side == 'open_long'">{{ ((props.item.takeProfitPrice / props.item.price - 1) * 100).toFixed(2) + '%' }}</span>
                                <span v-else-if="props.item.side == 'open_short'">{{ ((props.item.price / props.item.takeProfitPrice - 1) * 100).toFixed(2) + '%' }}</span>
                              </span>
                            </template>
                            <span>{{ props.item.takeProfitPrice }}</span>
                          </v-tooltip>
                        </td>
                        <td class="text-right">
                          <!-- <span>{{ formatNum(props.item.stopLossPrice1 > 0 ? props.item.stopLossPrice1.toFixed(futuresDecimalNumbers[props.item.symbol]) : '--') }}</span>
                          <span> / </span>
                          <span :class="[(props.item.side == 'open_long' && props.item.stopLossPrice1 < props.item.price) ? 'red--text' : '', (props.item.side == 'open_short' && props.item.stopLossPrice1 > props.item.price) ? 'red--text' : '']">{{ calcSafetyNetEstimatedPL(props.item) }}</span> -->
                          <v-tooltip bottom open-delay="100" close-delay="100">
                            <template v-slot:activator="{ on }">
                              <span v-on="on">
                                <span>{{ (props.item.trailingStopLevel1 * 100).toFixed(2) + '%'  }}</span>
                              </span>
                            </template>
                            <span>{{ props.item.stopLossPrice1  }}</span>
                          </v-tooltip>
                        </td>
                        <td class="text-right">
                          <!-- <v-tooltip bottom open-delay="100" close-delay="100">
                            <template v-slot:activator="{ on }">
                              <span v-on="on" v-show="!props.item.beFixedStopLossPrice">
                                <span>{{ formatNum(props.item.stopLossPrice > 0 ? props.item.stopLossPrice.toFixed(futuresDecimalNumbers[props.item.symbol]) : '--') }}</span>
                                <span> / </span>
                                <span :class="[(props.item.side == 'open_long' && props.item.stopLossPrice < props.item.price) ? 'red--text' : '', (props.item.side == 'open_short' && props.item.stopLossPrice > props.item.price) ? 'red--text' : '']">{{ calcEstimatedPL(props.item) }}</span>
                              </span>
                              <span v-on="on" v-show="props.item.beFixedStopLossPrice">
                                <span>{{ formatNum(props.item.fixedStopLossPrice > 0 ? props.item.fixedStopLossPrice.toFixed(futuresDecimalNumbers[props.item.symbol]) : '--') }}</span>
                                <span> / </span>
                                <span :class="[(props.item.side == 'open_long' && props.item.fixedStopLossPrice < props.item.price) ? 'red--text' : '', (props.item.side == 'open_short' && props.item.fixedStopLossPrice > props.item.price) ? 'red--text' : '']">{{ calcEstimatedPL(props.item) }}</span>
                              </span>
                            </template>
                            <span v-show="!props.item.beFixedStopLossPrice">{{$t('common.trailing')}}</span>
                            <span v-show="props.item.beFixedStopLossPrice">{{$t('common.fixed')}}</span>
                          </v-tooltip> -->
                          <v-tooltip bottom open-delay="100" close-delay="100">
                            <template v-slot:activator="{ on }">
                              <span v-on="on">
                                <span>{{ (props.item.trailingStopLevel1 * 100).toFixed(2) + '%'  }}</span>
                              </span>
                            </template>
                            <span>{{ props.item.stopLossPrice  }}</span>
                          </v-tooltip>
                        </td>
                        <td>
                          <v-tooltip bottom open-delay="100" close-delay="100">
                            <template v-slot:activator="{ on }">
                              <span v-on="on" :class="currentTimestamp >= props.item.sendOrderStartTimestamp ? 'green--text' : 'grey--text'">
                                {{formatDate(props.item.sendOrderStartTimestamp)}}
                              </span>
                            </template>
                            <span>{{formatDate(props.item.sendOrderStartTimestamp)}}</span>
                            <span>-</span>
                            <span>
                              {{ -1 == props.item.sendOrderEndTimestamp ? $t('futuresTrading.infinity') : formatDate(props.item.sendOrderEndTimestamp)}}
                            </span>
                          </v-tooltip>
                        </td>
                        <td :style="{color: getColorByStrategy(props.item.strategy)}">{{ props.item.strategy }}</td>
                        <td :class="props.item.bgColor">{{ props.item.category }}</td>
                        <td>
                          <span>
                            <v-icon color="grey" small class="pointer" @click.stop="copyToClipboard(props.item.id)" :title="props.item.id">mdi-content-copy</v-icon>
                            <v-icon color="orange" small class="pointer ml-1" @click.stop="showChangeOrderDialog(props.item)"
                              :title="$t('common.edit')">mdi-pencil</v-icon>
                            <v-icon color="blue" small class="pointer ml-1" :disabled="props.item.strategy == 'PP' || props.item.strategy == 'CPP'"
                              @click.stop="showChangeTimeDialog(props.item)" :title="$t('common.time')">mdi-clock-outline</v-icon>
                            <v-icon color="blue-grey lighten-1" small class="pointer ml-1" @click.stop="showChangeNoteDialog('pendingEntry', props.item)"
                              :title="$t('common.strategy')">icon icon-s1</v-icon>
                            <v-icon color="red" small class="pointer ml-1" @click.stop="deleteUnsubmittedOrderById(props.item.id)"
                              :title="$t('common.cancel')">mdi-close</v-icon>
                          </span>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                  <!-- 加载状态 -->
                  <div class="text-center" v-show="pendingEntryLoading">
                    <div>
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </div>
                  </div>
                  <!-- 底部分页 -->
                  <!-- </v-card> -->
                </v-col>
                <v-row no-gutters :class="[darkMode?'logsDarkTabBg':'grey lighten-3']">
                  <v-col cols="auto" class="pa-4" v-show="pendingEntryOrders.length > 0">
                    <v-pagination color="lightred" circle prev-icon="mdi-menu-left" next-icon="mdi-menu-right" v-model="pendingEntryOrdersPageNo"
                      :length="pendingEntryOrdersTotalPage" :total-visible="10" @input="queryPendingEntryOrdersByPages"></v-pagination>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="1">
                    <v-select class="fs-14 mr-2" v-model="orderSearch.exchange" :label="$t('common.exchange')" :items="exchanges"
                      item-text="name" item-value="code" clearable></v-select>
                  </v-col>
                  <v-col cols="1">
                    <v-select class="fs-14 mr-2" v-model="orderSearch.symbol" :label="$t('common.symbol')" :items="futuresSymbols"
                      clearable></v-select>
                  </v-col>
                  <v-col cols="1">
                    <v-select class="fs-14 mr-2" v-model="orderSearch.side" :label="$t('common.side')" :items="orderSides"
                      clearable></v-select>
                  </v-col>
                  <v-col cols="1">
                    <v-select class="fs-14 mr-2" v-model="orderSearch.strategy" :label="$t('common.strategy')" :items="strategies"
                      clearable></v-select>
                  </v-col>
                  <v-col cols="1">
                    <v-select class="fs-14 mr-4" v-model="orderSearch.category" :label="$t('common.category')" :items="orderCategories"
                      clearable></v-select>
                  </v-col>
                </v-row>
              </v-row>
              <v-row no-gutters :class="[darkMode?'logsDarkTabBg':'grey lighten-3']" class="pb-3">
                    <v-spacer></v-spacer>
                    <v-col cols="auto">
                      <v-btn x-small tile :title="$t('common.deleteAll')" class="red accent-4" dark @click="deleteAllPendingEntryDialog = true">{{ $t('common.deleteAll') }}</v-btn>
                      <v-btn x-small tile :title="$t('common.delete')" class="blue darken-4 ml-5 mr-2" dark @click="deletePendingEntryDialog = true">{{ $t('common.delete') }}</v-btn>
                    </v-col>
              </v-row>
            </v-tab-item>
            <!-- 第二页 PENDING Exit ORDERS -->
            <v-tab-item value="pendingExitOrders">
              <v-row>
                <v-col cols="12" class="ml-2">
                  <!-- <v-card flat :class="[fullscreen?'h-vh':'']"> -->
                  <v-data-table dense :headers="pendingExitOrdersHeader" :items="pendingExitOrders"
                    hide-default-footer :items-per-page="pageSize" height="inherit" :no-data-text="pendingExitLoading ? '':$t('futuresTrading.noDataAvailable')">
                    <template v-slot:item="props">
                      <tr class="table-fixed" @click="emitRenkoChartPrice(props.item)">
                        <td>
                          <v-tooltip bottom open-delay="100" close-delay="100">
                            <template v-slot:activator="{ on }">
                              <span v-on="on" :class="CommonJS.formatExchangeColor(props.item.exchangeName)">{{ props.item.exchangeName }}</span>
                            </template>
                            <span>{{ props.item.id }}</span>
                          </v-tooltip>
                        </td>
                        <td>{{ props.item.symbol }}</td>
                        <td :class=" props.item.side == 'open_long'|| props.item.side == 'close_buy' ? 'green--text' : 'red--text' ">
                          <span v-side="props.item.side"></span>
                        </td>
                        <td class="text-right">
                          {{ (props.item.side == 'open_short'|| props.item.side == 'close_sell') ? '-' : '' }}{{ formatNum(props.item.size) }}
                        </td>
                        <td class="text-right">{{ formatNum(props.item.price.toFixed(futuresDecimalNumbers[props.item.symbol])) }}</td>
                        <td>
                          <v-tooltip bottom open-delay="100" close-delay="100">
                            <template v-slot:activator="{ on }">
                              <span v-on="on" :class="currentTimestamp >= props.item.sendOrderStartTimestamp ? 'green--text' : 'grey--text'">
                                {{formatDate(props.item.sendOrderStartTimestamp)}}
                              </span>
                            </template>
                            <span>{{formatDate(props.item.sendOrderStartTimestamp)}}</span>
                            <span>-</span>
                            <span>
                              {{ -1 == props.item.sendOrderEndTimestamp ? $t('futuresTrading.infinity') : formatDate(props.item.sendOrderEndTimestamp)}}
                            </span>
                          </v-tooltip>
                        </td>
                        <td :style="{color: getColorByStrategy(props.item.strategy)}">{{ props.item.strategy }}</td>
                        <td :class="props.item.bgColor">{{ props.item.category }}</td>
                        <td>
                          <span>
                            <v-icon color="grey" small class="pointer" @click.stop="copyToClipboard(props.item.id)" :title="props.item.id">mdi-content-copy</v-icon>
                            <v-icon color="orange" small class="pointer ml-1" @click.stop="showChangeOrderDialog(props.item)"
                              :title="$t('common.edit')">mdi-pencil</v-icon>
                            <v-icon color="blue" small class="pointer ml-1" :disabled="props.item.strategy != 'PP' || props.item.strategy != 'CPP'"
                              @click.stop="showChangeTimeDialog(props.item)" :title="$t('common.time')">mdi-clock-outline</v-icon>
                            <v-icon color="blue-grey lighten-1" small class="pointer ml-1" @click.stop="showChangeNoteDialog('pendingExit', props.item)"
                              :title="$t('common.strategy')">icon icon-s1</v-icon>
                            <v-icon color="red" small class="pointer ml-1" @click.stop="deleteUnsubmittedOrderById(props.item.id)"
                              :title="$t('common.cancel')">mdi-close</v-icon>
                          </span>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                  <!-- 加载状态 -->
                  <div class="text-center" v-show="pendingExitLoading">
                    <div>
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </div>
                  </div>
                  <!-- 底部分页 -->
                  <!-- </v-card> -->
                </v-col>
                <v-row no-gutters :class="[darkMode?'logsDarkTabBg':'grey lighten-3']" class="pb-3">
                  <v-col cols="auto" class="pa-4" v-show="pendingExitOrders.length > 0">
                    <v-pagination color="lightred" circle prev-icon="mdi-menu-left" next-icon="mdi-menu-right" v-model="pendingExitOrdersPageNo"
                      :length="pendingExitOrdersTotalPage" :total-visible="10" @input="queryPendingExitOrdersByPages"></v-pagination>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="1">
                    <v-select class="fs-14 mr-2" v-model="orderSearch.exchange" :label="$t('common.exchange')" :items="exchanges"
                      item-text="name" item-value="code" clearable></v-select>
                  </v-col>
                  <v-col cols="1">
                    <v-select class="fs-14 mr-2" v-model="orderSearch.symbol" :label="$t('common.symbol')" :items="futuresSymbols"
                      clearable></v-select>
                  </v-col>
                  <v-col cols="1">
                    <v-select class="fs-14 mr-2" v-model="orderSearch.side" :label="$t('common.side')" :items="orderSides"
                      clearable></v-select>
                  </v-col>
                  <v-col cols="1">
                    <v-select class="fs-14 mr-2" v-model="orderSearch.strategy" :label="$t('common.strategy')" :items="strategies"
                      clearable></v-select>
                  </v-col>
                  <v-col cols="1">
                    <v-select class="fs-14 mr-4" v-model="orderSearch.category" :label="$t('common.category')" :items="orderCategories"
                      clearable></v-select>
                  </v-col>
                </v-row>
              </v-row>
            </v-tab-item>
            <!-- 第三页提交至交易所的订单 -->
            <v-tab-item value="submittedExchangeOrders">
              <v-row>
                <v-col cols="12" class="ml-2">
                  <v-data-table dense :headers="submittedOrderHeader" :items="submittedOrders"
                    hide-default-footer :items-per-page="pageSize" :no-data-text="submittedExchangeOrdersLoading ? '':$t('futuresTrading.noDataAvailable')">
                    <template v-slot:item="props">
                      <tr>
                        <td>
                          <v-tooltip bottom open-delay="100" close-delay="100">
                            <template v-slot:activator="{ on }">
                              <span v-on="on" :class="CommonJS.formatExchangeColor(props.item.exchangeName)">{{ props.item.exchangeName }}</span>
                            </template>
                            <span>{{ props.item.futuresOrderId }}</span>
                          </v-tooltip>
                        </td>
                        <td>{{ props.item.symbol }}</td>
                        <td :class=" props.item.side == 'open_long'|| props.item.side == 'close_buy' ? 'green--text' : 'red--text' ">
                          <span v-side="props.item.side"></span>
                        </td>
                        <td class="text-right">
                          {{ (props.item.side == 'open_short'|| props.item.side == 'close_sell') ? '-' : '' }}{{ formatNum(props.item.size) }}
                        </td>
                        <td class="text-right">{{ formatNum(props.item.price.toFixed(futuresDecimalNumbers[props.item.symbol])) }}</td>
                        <!-- <td>{{ props.item.leverage }}</td> -->
                        <td><span v-status="props.item.status"></span></td>
                        <td>{{ formatDate(props.item.timestamp) }}</td>
                        <td :style="{color: getColorByStrategy(props.item.strategy)}">{{ props.item.strategy }}</td>
                        <td :class="props.item.bgColor">{{ props.item.category }}</td>
                        <td>
                          <v-icon color="grey" small class="pointer" @click="copyToClipboard(props.item.futuresOrderId)"
                            :title="props.item.futuresOrderId">mdi-content-copy</v-icon>
                          <!-- <v-icon color="blue-grey lighten-2" small class="pointer ml-1" @click="showChangeNoteDialog('submitted', props.item)"
                            :title="$t('common.note')">mdi-file-plus</v-icon> -->
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                  <!-- 加载状态 -->
                  <div class="text-center" v-show="submittedExchangeOrdersLoading">
                    <div>
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </div>
                  </div>
                  <!-- </v-card> -->
                </v-col>
              </v-row>
              <!-- 底部分页 -->
              <v-row no-gutters :class="[darkMode?'logsDarkTabBg':'grey lighten-3']" class="pb-3">
                <v-col cols="auto" class="pa-4" v-show="submittedOrders.length > 0">
                  <v-pagination color="lightred" circle prev-icon="mdi-menu-left" next-icon="mdi-menu-right" v-model="submittedOrdersPageNo"
                    :length="submittedOrdersTotalPage" :total-visible="10" @input="querySubmittedOrdersByPages"></v-pagination>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="1">
                  <v-select class="fs-14 mr-2" v-model="orderSearch.exchange" :label="$t('common.exchange')" :items="exchanges"
                    item-text="name" item-value="code" clearable></v-select>
                </v-col>
                <v-col cols="1">
                  <v-select class="fs-14 mr-2" v-model="orderSearch.symbol" :label="$t('common.symbol')" :items="futuresSymbols"
                    clearable></v-select>
                </v-col>
                <v-col cols="1">
                  <v-select class="fs-14 mr-2" v-model="orderSearch.side" :label="$t('common.side')" :items="orderSides"
                    clearable></v-select>
                </v-col>
                <v-col cols="1">
                    <v-select class="fs-14 mr-2" v-model="orderSearch.strategy" :label="$t('common.strategy')" :items="strategies"
                      clearable></v-select>
                  </v-col>
                <v-col cols="1">
                  <v-select class="fs-14 mr-4" v-model="orderSearch.category" :label="$t('common.category')" :items="orderCategories"
                    clearable></v-select>
                </v-col>
              </v-row>
            </v-tab-item>
            <!-- 第四页 UNFILLED AT EXCHANGES -->
            <v-tab-item value="unfilledExchangeOrders">
              <v-row>
                <v-col cols="12" class="ml-2">
                  <v-data-table dense :headers="unfilledExchangeOrdersHeader" :items="unfilledExchangeOrders"
                    :items-per-page="pageSize" hide-default-footer :no-data-text="unfilledAtExchangesLoading ? '':$t('futuresTrading.noDataAvailable')">
                    <template v-slot:item="props">
                      <tr @click="emitRenkoChartPrice(props.item)">
                        <td>
                          <v-tooltip bottom open-delay="100" close-delay="100">
                            <template v-slot:activator="{ on }">
                              <span v-on="on" :class="CommonJS.formatExchangeColor(props.item.exchangeName)">{{ props.item.exchangeName }}</span>
                            </template>
                            <span>{{ props.item.futuresOrderId }}</span>
                          </v-tooltip>
                        </td>
                        <td>{{ props.item.symbol }}</td>
                        <td :class=" props.item.side == 'open_long'|| props.item.side == 'close_buy' ? 'green--text' : 'red--text' ">
                          <span v-side="props.item.side"></span>
                        </td>
                        <td class="text-right">
                          {{ (props.item.side == 'open_short'|| props.item.side == 'close_sell') ? '-' : '' }}{{ formatNum(props.item.size) }}
                        </td>
                        <td class="text-right">{{ formatNum(props.item.price.toFixed(futuresDecimalNumbers[props.item.symbol])) }}</td>
                        <td class="text-right">
                          <span>{{ formatNum(props.item.takeProfitPrice > 0 ? props.item.takeProfitPrice.toFixed(futuresDecimalNumbers[props.item.symbol]) : '--') }}</span>
                          <span> / </span>
                          <span :class="[(props.item.side == 'open_long' && props.item.takeProfitPrice > 0 && props.item.takeProfitPrice < props.item.price) ? 'red--text' : '', (props.item.side == 'open_short' && props.item.takeProfitPrice > props.item.price) ? 'red--text' : '']">{{ calcTakeProfitEstimatedPL(props.item) }}</span>
                        </td>
                        <td class="text-right">
                          <span>{{ formatNum(props.item.stopLossPrice1 > 0 ? props.item.stopLossPrice1.toFixed(futuresDecimalNumbers[props.item.symbol]) : '--') }}</span>
                          <span> / </span>
                          <span :class="[(props.item.side == 'open_long' && props.item.stopLossPrice1 < props.item.price) ? 'red--text' : '', (props.item.side == 'open_short' && props.item.stopLossPrice1 > props.item.price) ? 'red--text' : '']">{{ calcSafetyNetEstimatedPL(props.item) }}</span>
                        </td>
                        <td class="text-right">
                          <v-tooltip bottom open-delay="100" close-delay="100">
                            <template v-slot:activator="{ on }">
                              <span v-on="on" v-show="!props.item.beFixedStopLossPrice">
                                <span>{{ formatNum(props.item.stopLossPrice > 0 ? props.item.stopLossPrice.toFixed(futuresDecimalNumbers[props.item.symbol]) : '--') }}</span>
                                <span> / </span>
                                <span :class="[(props.item.side == 'open_long' && props.item.stopLossPrice < props.item.price) ? 'red--text' : '', (props.item.side == 'open_short' && props.item.stopLossPrice > props.item.price) ? 'red--text' : '']">{{ calcEstimatedPL(props.item) }}</span>
                              </span>
                              <span v-on="on" v-show="props.item.beFixedStopLossPrice">
                                <span>{{ formatNum(props.item.fixedStopLossPrice > 0 ? props.item.fixedStopLossPrice.toFixed(futuresDecimalNumbers[props.item.symbol]) : '--') }}</span>
                                <span> / </span>
                                <span :class="[(props.item.side == 'open_long' && props.item.fixedStopLossPrice < props.item.price) ? 'red--text' : '', (props.item.side == 'open_short' && props.item.fixedStopLossPrice > props.item.price) ? 'red--text' : '']">{{ calcEstimatedPL(props.item) }}</span>
                              </span>
                            </template>
                            <span v-show="!props.item.beFixedStopLossPrice">{{$t('common.trailing')}}</span>
                            <span v-show="props.item.beFixedStopLossPrice">{{$t('common.fixed')}}</span>
                          </v-tooltip>
                        </td>
                        <!-- -->
                        <td :class="currentTimestamp >= props.item.timestamp ? 'green--text' : 'grey--text'">{{ formatDate(props.item.timestamp) }}</td>
                        <td :style="{color: getColorByStrategy(props.item.strategy)}">{{ props.item.strategy }}</td>
                        <td :class="props.item.bgColor">{{ props.item.category }}</td>
                        <td>
                          <v-icon color="grey" small class="pointer" @click.stop="copyToClipboard(props.item.futuresOrderId)"
                            :title="props.item.futuresOrderId">mdi-content-copy</v-icon>
                          <v-icon color="orange" small class="pointer ml-1" @click.stop="showChangeOrderDialog(props.item)"
                            :title="$t('common.edit')">mdi-pencil</v-icon>
                          <v-icon color="red" small class="pointer ml-1" @click.stop="deleteSubmittedOrderById(props.item.id)"
                            :title="$t('common.cancel')">mdi-close</v-icon>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                  <!-- 加载状态 -->
                  <div class="text-center" v-show="unfilledAtExchangesLoading">
                    <div>
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </div>
                  </div>
                  <!-- </v-card> -->
                </v-col>
                <!-- 底部分页 -->
                <v-row no-gutters :class="[darkMode?'logsDarkTabBg':'grey lighten-3']" class="pb-3">
                  <v-col cols="auto" class="pa-4" v-show="unfilledExchangeOrders.length > 0">
                    <v-pagination color="lightred" circle prev-icon="mdi-menu-left" next-icon="mdi-menu-right" v-model="unfilledExchangeOrdersPageNo"
                      :length="unfilledExchangeOrdersTotalPage" :total-visible="10" @input="queryUnfilledExchangeOrdersByPages"></v-pagination>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="1">
                    <v-select class="fs-14 mr-2" v-model="orderSearch.exchange" :label="$t('common.exchange')" :items="exchanges"
                      item-text="name" item-value="code" clearable></v-select>
                  </v-col>
                  <v-col cols="1">
                    <v-select class="fs-14 mr-2" v-model="orderSearch.symbol" :label="$t('common.symbol')" :items="futuresSymbols"
                      clearable></v-select>
                  </v-col>
                  <v-col cols="1">
                    <v-select class="fs-14 mr-2" v-model="orderSearch.side" :label="$t('common.side')" :items="orderSides"
                      clearable></v-select>
                  </v-col>
                  <v-col cols="1">
                    <v-select class="fs-14 mr-2" v-model="orderSearch.strategy" :label="$t('common.strategy')" :items="strategies"
                      clearable></v-select>
                  </v-col>
                  <v-col cols="1">
                    <v-select class="fs-14 mr-4" v-model="orderSearch.category" :label="$t('common.category')" :items="orderCategories"
                      clearable></v-select>
                  </v-col>
                </v-row>
              </v-row>
            </v-tab-item>
            <!-- 第五页 Filled ORDERS -->
            <v-tab-item value="filledExchangeOrders">
              <v-row>
                <v-col cols="12" class="ml-2">
                  <v-data-table dense :headers="filledExchangeOrdersHeader" :items="filledExchangeOrders"
                    :items-per-page="pageSize" hide-default-footer :no-data-text="filledExchangeOrdersLoading ? '':$t('futuresTrading.noDataAvailable')">
                    <template v-slot:item="props">
                      <tr>
                        <td>
                          <v-tooltip bottom open-delay="100" close-delay="100">
                            <template v-slot:activator="{ on }">
                              <span v-on="on" :class="CommonJS.formatExchangeColor(props.item.exchangeName)">{{ props.item.exchangeName }}</span>
                            </template>
                            <span>{{ props.item.futuresOrderId }}</span>
                          </v-tooltip>
                        </td>
                        <td>{{ props.item.symbol }}</td>
                        <td :class=" props.item.side == 'open_long'|| props.item.side == 'close_buy' ? 'green--text' : 'red--text' ">
                          <span v-side="props.item.side"></span>
                        </td>
                        <td class="text-right">
                          {{ (props.item.side == 'open_short'|| props.item.side == 'close_sell') ? '-' : '' }}{{ formatNum(props.item.size) }}
                        </td>
                        <td class="text-right">{{ formatNum(props.item.price.toFixed(futuresDecimalNumbers[props.item.symbol])) }}</td>
                        <td><span v-status="props.item.status"></span></td>
                        <td>{{ formatDate(props.item.timestamp) }}</td>
                        <td :style="{color: getColorByStrategy(props.item.strategy)}">{{ props.item.strategy }}</td>
                        <td :class="props.item.bgColor">{{ props.item.category }}</td>
                        <td>
                          <v-icon color="grey" small class="pointer" @click="copyToClipboard(props.item.futuresOrderId)"
                            :title="props.item.futuresOrderId">mdi-content-copy</v-icon>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                  <!-- 加载状态 -->
                  <div class="text-center" v-show="filledExchangeOrdersLoading">
                    <div>
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </div>
                  </div>
                </v-col>
                <!-- 底部分页 -->
                <v-row no-gutters :class="[darkMode?'logsDarkTabBg':'grey lighten-3']" class="pb-3">
                  <v-col cols="auto" class="pa-4" v-show="filledExchangeOrders.length > 0">
                    <v-pagination color="lightred" circle prev-icon="mdi-menu-left" next-icon="mdi-menu-right" v-model="filledExchangeOrdersPageNo"
                      :length="filledExchangeOrdersTotalPage" :total-visible="10" @input="queryFilledExchangeOrdersByPages"></v-pagination>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="1">
                    <v-select class="fs-14 mr-2" v-model="orderSearch.exchange" :label="$t('common.exchange')" :items="exchanges"
                      item-text="name" item-value="code" clearable></v-select>
                  </v-col>
                  <v-col cols="1">
                    <v-select class="fs-14 mr-2" v-model="orderSearch.symbol" :label="$t('common.symbol')" :items="futuresSymbols"
                      clearable></v-select>
                  </v-col>
                  <v-col cols="1">
                    <v-select class="fs-14 mr-2" v-model="orderSearch.side" :label="$t('common.side')" :items="orderSides"
                      clearable></v-select>
                  </v-col>
                  <v-col cols="1">
                    <v-select class="fs-14 mr-2" v-model="orderSearch.strategy" :label="$t('common.strategy')" :items="strategies"
                      clearable></v-select>
                  </v-col>
                  <v-col cols="1">
                    <v-select class="fs-14 mr-4" v-model="orderSearch.category" :label="$t('common.category')" :items="orderCategories"
                      clearable></v-select>
                  </v-col>
                </v-row>
              </v-row>
            </v-tab-item>
            <!-- 第六页持仓订单 -->
            <v-tab-item value="openPositions">
              <v-row>
                <v-col cols="12" class="ml-2">
                  <v-data-table dense hide-default-footer :headers="openPositionsOrdersHeader" :items="openPositionsOrders"
                    :items-per-page="pageSize" :no-data-text="openPositionsLoading ? '':$t('futuresTrading.noDataAvailable')">
                    <template v-slot:item="props">
                      <tr @click="emitRenkoChartPrice(props.item)">
                        <td>
                          <v-tooltip bottom open-delay="100" close-delay="100">
                            <template v-slot:activator="{ on }">
                              <span v-on="on" :class="CommonJS.formatExchangeColor(props.item.exchangeName)">{{ props.item.exchangeName }}</span>
                            </template>
                            <span>{{ props.item.futuresOrderId }}</span>
                          </v-tooltip>
                        </td>
                        <td>{{ props.item.symbol.toUpperCase() }}</td>
                        <td :class="props.item.side == 'open_long'|| props.item.side == 'close_buy' ? 'green--text' : 'red--text'">
                          <span v-side="props.item.side"></span>
                        </td>
                        <td class="text-right">
                          {{ (props.item.side == 'open_short'|| props.item.side == 'close_sell') ? '-' : '' }}{{ formatNum(props.item.size) }}
                        </td>
                        <td class="text-right">{{ formatNum(props.item.price.toFixed(futuresDecimalNumbers[props.item.symbol])) }}</td>
                        <td class="text-right">
                          <span>{{ formatNum(props.item.takeProfitPrice > 0 ? props.item.takeProfitPrice.toFixed(futuresDecimalNumbers[props.item.symbol]) : '--') }}</span>
                          <span> / </span>
                          <span :class="[(props.item.side == 'open_long' && props.item.takeProfitPrice > 0 && props.item.takeProfitPrice < props.item.price) ? 'red--text' : '', (props.item.side == 'open_short' && props.item.takeProfitPrice > props.item.price) ? 'red--text' : '']">{{ calcTakeProfitEstimatedPL(props.item) }}</span>
                        </td>
                        <td class="text-right">
                          <span>{{ formatNum(props.item.stopLossPrice1 > 0 ? props.item.stopLossPrice1.toFixed(futuresDecimalNumbers[props.item.symbol]) : '--') }}</span>
                          <span> / </span>
                          <span :class="[(props.item.side == 'open_long' && props.item.stopLossPrice1 < props.item.price) ? 'red--text' : '', (props.item.side == 'open_short' && props.item.stopLossPrice1 > props.item.price) ? 'red--text' : '']">{{ calcSafetyNetEstimatedPL(props.item) }}</span>
                        </td>
                        <td class="text-right">
                          <v-tooltip bottom open-delay="100" close-delay="100" v-if="props.item.beFixedStopLossPrice">
                            <template v-slot:activator="{ on }">
                              <span v-on="on">
                                <span>{{ formatNum(props.item.fixedStopLossPrice > 0 ? props.item.fixedStopLossPrice.toFixed(futuresDecimalNumbers[props.item.symbol]) : '--') }}</span>
                                <span> / </span>
                                <span :class="[(props.item.side == 'open_long' && props.item.fixedStopLossPrice < props.item.price) ? 'red--text' : '', (props.item.side == 'open_short' && props.item.fixedStopLossPrice > props.item.price) ? 'red--text' : '']">{{ calcEstimatedPL(props.item) }}</span>
                              </span>
                            </template>
                            <span>{{$t('common.fixed')}}</span>
                          </v-tooltip>
                          <v-tooltip bottom open-delay="100" close-delay="100" v-if="!props.item.beFixedStopLossPrice">
                            <template v-slot:activator="{ on }">
                              <span v-on="on">
                                <span>{{ formatNum(props.item.stopLossPrice > 0 ? props.item.stopLossPrice.toFixed(futuresDecimalNumbers[props.item.symbol]) : '--') }}</span>
                                <span> / </span>
                                <span :class="[(props.item.side == 'open_long' && props.item.stopLossPrice < props.item.price) ? 'red--text' : '', (props.item.side == 'open_short' && props.item.stopLossPrice > props.item.price) ? 'red--text' : '']">{{ calcEstimatedPL(props.item) }}</span>
                              </span>
                            </template>
                              <span>{{$t('common.trailing')}}</span>
                          </v-tooltip>
                        </td>
                        <td>
                          <v-tooltip bottom open-delay="100" close-delay="100">
                            <template v-slot:activator="{ on }">
                              <span v-on="on" :class="currentTimestamp >= props.item.stopLoss1StartTimestamp ? 'green--text' : 'grey--text text--lighten-1'">
                                {{formatDate(props.item.stopLoss1StartTimestamp)}}
                              </span>
                            </template>
                            <span>{{formatDate(props.item.stopLoss1StartTimestamp)}}</span>
                            <span>-</span>
                            <span>
                              {{ -1 == props.item.stopLoss1EndTimestamp ? $t('futuresTrading.infinity') : formatDate(props.item.stopLoss1EndTimestamp)}}
                            </span>
                          </v-tooltip>
                        </td>
                        <td v-show="!props.item.beFixedStopLossPrice">
                          <v-tooltip bottom open-delay="100" close-delay="100">
                            <template v-slot:activator="{ on }">
                              <span v-on="on" :class="currentTimestamp >= props.item.stopLossStartTimestamp ? 'green--text' : 'grey--text text--lighten-1'">
                                {{formatDate(props.item.stopLossStartTimestamp)}}
                              </span>
                            </template>
                            <span>{{formatDate(props.item.stopLossStartTimestamp)}}</span>
                            <span>-</span>
                            <span>
                              {{ -1 == props.item.stopLossEndTimestamp ? $t('futuresTrading.infinity') : formatDate(props.item.stopLossEndTimestamp)}}
                            </span>
                          </v-tooltip>
                        </td>
                        <td v-show="props.item.beFixedStopLossPrice">
                          <v-tooltip bottom open-delay="100" close-delay="100">
                            <template v-slot:activator="{ on }">
                              <span v-on="on" :class="currentTimestamp >= props.item.fixedStopLossStartTimestamp ? 'green--text' : 'grey--text text--lighten-1'">
                                {{formatDate(props.item.fixedStopLossStartTimestamp)}}
                              </span>
                            </template>
                            <span>{{formatDate(props.item.fixedStopLossStartTimestamp)}}</span>
                            <span>-</span>
                            <span>
                              {{ -1 == props.item.fixedStopLossEndTimestamp ? $t('futuresTrading.infinity') : formatDate(props.item.fixedStopLossEndTimestamp)}}
                            </span>
                          </v-tooltip>
                        </td>
                        <td :style="{color: getColorByStrategy(props.item.strategy)}">{{ props.item.strategy }}</td>
                        <td :class="props.item.bgColor">{{ props.item.category }}</td>
                        <td>
                          <v-icon color="grey" small class="pointer" @click.stop="copyToClipboard(props.item.futuresOrderId)"
                            :title="props.item.futuresOrderId">mdi-content-copy</v-icon>
                          <v-icon color="orange" small class="pointer ml-1" :title="$t('common.edit')" @click.stop="showChangeOrderDialog(props.item)">mdi-pencil</v-icon>
                          <v-icon color="green" small class="pointer ml-1" :title="$t('common.split')" @click.stop="showSplitOrderDialog(props.item)">mdi-content-cut</v-icon>
                          <v-icon color="blue" small class="pointer ml-1" :title="$t('common.time')" @click.stop="showChangeTimeDialog(props.item)">mdi-clock-outline</v-icon>
                          <v-icon color="blue-grey lighten-1" small class="pointer ml-1" @click.stop="showChangeNoteDialog('openPositions', props.item)"
                            :title="$t('common.strategy')">icon icon-s1</v-icon>
                          <v-icon color="red" small class="pointer ml-1" :title="$t('common.close')" @click.stop="showExitPositionOrderDialog(props.item)">mdi-close</v-icon>
                          <v-icon color="cyan lighten-1" small class="pointer ml-1" :title="$t('common.stopAndReverse')" @click.stop="showSARDialog(props.item)">mdi-rotate-left</v-icon>
                          <v-icon color="red lighten-1" small class="pointer ml-1" :title="$t('common.deletePositionOrder')" @click.stop="showDeletePositionOrderDialog(props.item.futuresOrderId)">mdi-power</v-icon>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                  <!-- 加载状态 -->
                  <div class="text-center" v-if="openPositionsLoading">
                    <div>
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </div>
                  </div>
                </v-col>
                <!-- 底部分页 -->
                <v-row no-gutters :class="[darkMode?'logsDarkTabBg':'grey lighten-3']" class="pb-3">
                  <v-col cols="auto" class="pa-4" v-show="openPositionsOrders.length > 0">
                    <v-pagination color="lightred" circle prev-icon="mdi-menu-left" next-icon="mdi-menu-right" v-model="openPositionsOrdersPageNo"
                      :length="openPositionsOrdersTotalPage" :total-visible="10" @input="queryOpenPositionsOrdersByPages"></v-pagination>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="1">
                    <v-select class="fs-14 mr-2" v-model="orderSearch.exchange" :label="$t('common.exchange')" :items="exchanges"
                      item-text="name" item-value="code" clearable></v-select>
                  </v-col>
                  <v-col cols="1">
                    <v-select class="fs-14 mr-2" v-model="orderSearch.symbol" :label="$t('common.symbol')" :items="futuresSymbols"
                      clearable></v-select>
                  </v-col>
                  <v-col cols="1">
                    <v-select class="fs-14 mr-2" v-model="orderSearch.side" :label="$t('common.side')" :items="orderSides"
                      clearable></v-select>
                  </v-col>
                  <v-col cols="1">
                    <v-select class="fs-14 mr-2" v-model="orderSearch.strategy" :label="$t('common.strategy')" :items="strategies"
                      clearable></v-select>
                  </v-col>
                  <v-col cols="1">
                    <v-select class="fs-14 mr-4" v-model="orderSearch.category" :label="$t('common.category')" :items="orderCategories"
                      clearable></v-select>
                  </v-col>
                </v-row>
              </v-row>
              <v-row no-gutters :class="[darkMode?'logsDarkTabBg':'grey lighten-3']" class="pb-3">
                  <v-spacer></v-spacer>
                  <v-col cols="auto">
                    <v-btn x-small tile :title="$t('futuresTrading.killAllOpenPositionsTitle')" class="red accent-4 white--text" :disabled="killAllOpenPositionsCountdown != null" @click="killAllOpenPositionsDialog = true">
                      <span v-show="killAllOpenPositionsCountdown != null">{{ killAllOpenPositionsCountdown }}</span>
                      <span v-show="killAllOpenPositionsCountdown == null">{{ $t('common.killAll') }}</span>
                    </v-btn>
                    <v-btn x-small tile :title="$t('futuresTrading.killOpenPositionsTitle')" class="kill ml-5 white--text" :disabled="killOpenPositionsCountdown != null" @click="killOpenPositionsDialog = true">
                      <span v-show="killOpenPositionsCountdown != null">{{ killOpenPositionsCountdown }}</span>
                      <span v-show="killOpenPositionsCountdown == null">{{ $t('common.kill') }}</span>
                    </v-btn>
                    <v-btn x-small tile :title="$t('common.killAndReverseAll')" class="teal ml-5 white--text" @click="karaDialog = true">{{ $t('common.kara') }}</v-btn>
                    <v-btn x-small tile :title="$t('common.killAndReverse')" class="orange darken-4 ml-5 mr-2 white--text" @click="karDialog = true">{{ $t('common.kar') }}</v-btn>
                  </v-col>
              </v-row>
            </v-tab-item>
            <!-- 第七页交易所持仓订单 -->
            <v-tab-item value="openPositionsAtExchanges">
              <v-row>
                <v-col cols="12" class="ml-2">
                  <v-data-table dense class="pr-3" :headers="openPositionsAtExchangesHeader" :items="openPositionsAtExchanges"
                    :items-per-page="openPositionsAtExchangesPagination.rowsPerPage" :options.sync="openPositionsAtExchangesPagination" hide-default-footer :no-data-text="openPositionsAtExchangesLoading ? '':$t('futuresTrading.noDataAvailable')">
                    <template v-slot:item="props">
                      <tr class="pointer" @click="exchangeCode = props.item.exchange.toLowerCase();symbol = props.item.symbol">
                        <td>
                          <span :class="CommonJS.formatExchangeColor(props.item.exchange)">{{ props.item.exchange }}</span>
                        </td>
                        <td>
                          <span>{{ props.item.symbol }}</span>
                          <v-btn class="ml-1 cursor-default" tile outlined x-small :class="CommonJS.formatExchangeColor(props.item.exchange)">
                            {{ props.item.leverage + 'x'}}
                          </v-btn>
                        </td>
                        <td :class="props.item.side == 'Long'|| props.item.side == 'buy' ? 'green--text' : 'red--text' ">{{ props.item.side }}</td>
                        <td class="text-right">{{ props.item.side == 'Short' ? '-' : '' }}{{ formatNum(props.item.size) }}</td>
                        <td class="text-right">{{ formatNum(props.item.price.toFixed(futuresDecimalNumbers[props.item.symbol])) }}</td>
                        <td class="text-right">{{ props.item.liquidationPrice == 0 ? '--' : formatNum(props.item.liquidationPrice.toFixed(futuresDecimalNumbers[props.item.symbol])) }}</td>
                        <!-- <td class="text-right" :title="props.item.effectiveLeverage">{{ props.item.effectiveLeverage > 0 ? (formatNum(props.item.effectiveLeverage.toFixed(2)) + 'x') : '--' }}</td> -->
                        <td class="text-right" :title="props.item.returnOnEquity" :class="props.item.returnOnEquity == 0 ? '' : props.item.returnOnEquity > 0 ? 'green--text' : 'red--text' ">{{ formatNum((props.item.returnOnEquity * 100).toFixed(2)) + '%'}}</td>
                        <td class="text-right" :title="props.item.profitAndLoss" :class="props.item.profitAndLoss == 0 ? '' : props.item.profitAndLoss > 0 ? 'green--text' : 'red--text' ">
                          <span>{{ formatNum(props.item.profitAndLoss.toFixed(8)) }} {{props.item.baseCurrency != null ? props.item.baseCurrency : ''}}</span>
                          <span> | </span>
                          <span v-if="props.item.profitAndLoss < 0">(</span>
                          <span>{{ '$' + formatNum(Math.abs(props.item.markPrice * props.item.profitAndLoss).toFixed(2)) }}</span>
                          <span v-if="props.item.profitAndLoss < 0">)</span>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                  <!-- 加载状态 -->
                  <div class="text-center" v-if="openPositionsAtExchangesLoading">
                    <div>
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </div>
                  </div>
                  <!-- 底部分页 -->
                </v-col>
                <v-row no-gutters :class="[darkMode?'logsDarkTabBg':'grey lighten-3']" class="pb-3">
                  <v-col cols="auto" class="pa-4" v-show="openPositionsAtExchanges.length > 0">
                    <v-pagination color="lightred" circle prev-icon="mdi-menu-left" next-icon="mdi-menu-right" v-model="openPositionsAtExchangesPagination.page"
                      :length="openPositionsAtExchangesTotalPage" :total-visible="10"></v-pagination>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="1">
                    <v-select class="fs-14 mr-2" v-model="openPositionsExchangeSearch" :items="exchanges" :label="$t('futuresTrading.exchange')"
                      item-text="name" item-value="code" clearable></v-select>
                  </v-col>
                  <v-col cols="1">
                    <v-select class="fs-14 mr-4" v-model="openPositionsSymbolSearch" :label="$t('futuresTrading.symbol')"
                      :items="futuresSymbols" clearable></v-select>
                  </v-col>
                </v-row>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-container>
    <!-- 窗口 -->
    <!-- *******************提示价格差距过大的模态框******************* -->
    <v-row>
      <v-dialog v-model="largePriceGapDialog" persistent width="490" no-click-animation>
        <v-card>
          <v-card-title class="grey lighten-2">
            <span class="black--text">{{$t("futuresTrading.waring")}}</span>
            <v-spacer></v-spacer>
            <v-btn light icon @click="largePriceGapDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-container class="pb-2">
            <div class="fs-16">{{$t("futuresTrading.largePriceWaring")}}</div>
            <v-divider></v-divider>
          </v-container>
          <v-card-actions class="mx-5 pb-5">
            <v-spacer></v-spacer>
            <v-btn tile small class="yellow darken-2 black--text" @click="largePriceGapDialog = false">
              {{$t("futuresTrading.continueEditing")}}
            </v-btn>
            <v-btn tile small v-if="responsive == 'PC'" class="flex-m blue darken-2 white--text" @click="judgeParameters(false)">
              {{$t("futuresTrading.confirm")}}
            </v-btn>
            <v-btn tile small v-if="responsive == 'mobile'" class="flex-b blue darken-2 white--text" @click="mobileJudgeParameters(false)">
              {{$t("futuresTrading.confirm")}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- *******************设置止损价的模态框******************* -->
    <v-row>
      <v-dialog v-model="trailingStopDialog" persistent max-width="490" no-click-animation>
        <v-card>
          <v-card-title class="grey lighten-2">
            <span class="black--text">{{$t("futuresTrading.stopLoss")}}</span>
            <v-spacer></v-spacer>
            <v-btn light icon @click="trailingStopDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-container>
            <v-row no-gutters v-if="orderForm.category != 'MR'" class="mb-9">
              <v-col cols="12" class="text-right">
                <v-btn-toggle dense v-model="planVal" tile>
                  <v-btn text small value="0">{{$t("futuresTrading.reset")}}</v-btn>
                  <v-btn text small value="1">{{$t("futuresTrading.planA")}}</v-btn>
                  <v-btn text small value="2">{{$t("futuresTrading.planB")}}</v-btn>
                  <v-btn text small value="3">{{$t("futuresTrading.planC")}}</v-btn>
                  <v-btn text small value="4">{{$t("futuresTrading.planD")}}</v-btn>
                  <v-btn text small value="5">{{$t("futuresTrading.planE")}}</v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <!-- 止损价百分比计划设置 -->
            <div v-if="'close' == side" class="px-3">
              <span class="fs-22">{{$t("futuresTrading.cannotSetStopPrice")}}</span>
            </div>
            <div v-if="'close' != side" class="px-3">
              <!-- Trailing Stop1 -->
              <v-row no-gutters dense class="red--text">
                <v-col cols="12" sm="6" md="6" class="align-self-center">{{$t("futuresTrading.safetyNet")}}</v-col>
                <v-col cols="12" sm="6" md="6" class="text-right">
                  <v-text-field dense v-model="trailingStopLevel1" class="stopLossDialog" type="number" min="0" max="100"
                    suffix="%" @keydown.enter="nextFocus('stopLossDialog', $event)"></v-text-field>
                </v-col>
              </v-row>
              <!-- Price2 -->
              <v-row no-gutters dense class="green--text" v-if="orderForm.category != 'MR'">
                <v-col cols="12" sm="6" md="6" class="align-self-center">{{$t("futuresTrading.priceLevel2")}}</v-col>
                <v-col cols="12" sm="6" md="6" class="text-right">
                  <v-text-field dense v-model="priceLevel2" class="stopLossDialog" type="number" min="0" max="100"
                    suffix="%" @keydown.enter="nextFocus('stopLossDialog', $event)"></v-text-field>
                </v-col>
              </v-row>
              <!-- Trailing Stop2 -->
              <v-row no-gutters dense class="red--text" v-if="orderForm.category != 'MR'">
                <v-col cols="12" sm="6" md="6" class="align-self-center">{{$t("futuresTrading.trailingStopLevel2")}}</v-col>
                <v-col cols="12" sm="6" md="6" class="text-right">
                  <v-text-field dense v-model="trailingStopLevel2" class="stopLossDialog" type="number" min="0" max="100"
                    suffix="%" @keydown.enter="nextFocus('stopLossDialog', $event)"></v-text-field>
                </v-col>
              </v-row>
              <!-- Price3 -->
              <v-row no-gutters dense class="green--text" v-if="orderForm.category != 'MR'">
                <v-col cols="12" sm="6" md="6" class="align-self-center">{{$t("futuresTrading.priceLevel3")}}</v-col>
                <v-col cols="12" sm="6" md="6" class="text-right">
                  <v-text-field dense v-model="priceLevel3" class="stopLossDialog" type="number" min="0" max="100"
                    suffix="%" @keydown.enter="nextFocus('stopLossDialog', $event)"></v-text-field>
                </v-col>
              </v-row>
              <!-- Trailing Stop3 -->
              <v-row no-gutters dense class="red--text" v-if="orderForm.category != 'MR'">
                <v-col cols="12" sm="6" md="6" class="align-self-center">{{$t("futuresTrading.trailingStopLevel3")}}</v-col>
                <v-col cols="12" sm="6" md="6" class="text-right">
                  <v-text-field dense v-model="trailingStopLevel3" class="stopLossDialog" type="number" min="0" max="100"
                    suffix="%" @keydown.enter="nextFocus('stopLossDialog', $event)"></v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-container>
          <v-card-actions class="px-5 pb-5">
            <v-spacer></v-spacer>
            <v-btn small tile class="blue white--text stopLossDialog" @click="setTrailingStop">{{$t("futuresTrading.setStops")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row dense>
      <!-- *******************下单确认弹窗******************* -->
      <v-dialog v-model="orderConfirmationDialog" persistent max-width="550" no-click-animation>
        <v-card>
          <v-card-title class="grey lighten-2">
            <span class="black--text">{{$t("futuresTrading.orderDetailsDialog")}}</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="cancelDialog">
              <v-icon color="black">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-container v-if="orderForm != null">
            <v-row no-gutters>
              <v-col cols="5">{{$t("futuresTrading.exchange")}} :</v-col>
              <v-col cols="7">{{ getExchangeNameById(orderForm.exchangeId) }}</v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row no-gutters>
              <v-col cols="5">{{$t("futuresTrading.symbol")}} :</v-col>
              <v-col cols="7">{{ orderForm.symbol }}</v-col>
            </v-row>
            <v-divider></v-divider>
            <!-- 数量 -->
            <v-row no-gutters>
              <v-col cols="5">{{$t("futuresTrading.size")}} :</v-col>
              <v-col cols="7">
                <div class="d-flex">
                  <span>{{ orderForm.side == 'open_short' || orderForm.side == 'close_sell' ? '-' : '' }}{{ formatNum(orderForm.size) }}</span>
                  <v-spacer></v-spacer>
                  <span>{{ sizeDollarValue }}</span>
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <!-- 价格 -->
            <v-row no-gutters>
              <v-col cols="5">{{$t("futuresTrading.price")}} :</v-col>
              <v-col cols="7">
                <span v-if="orderForm.priceType == 'bbo'" class="fw-bold">{{ $t('common.bestBidOffer') }}</span>
                <span v-else>{{ formatNum(orderForm.price) }}</span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <!-- 策略 -->
            <v-row no-gutters>
              <v-col cols="5">{{$t("common.strategy")}} :</v-col>
              <v-col cols="7" :style="{color: getColorByStrategy(orderForm.strategy)}">
                {{ orderForm.strategy }}
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row no-gutters>
              <v-col cols="5">{{$t("common.category")}} :</v-col>
              <v-col cols="7">
                {{ orderForm.category }}
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <!-- 止盈价 -->
            <v-row no-gutters dense v-if="'open' == side">
              <v-col cols="5">{{$t("futuresTrading.takeProfit")}} :</v-col>
              <v-col cols="7">
                <span v-if="orderForm.priceType == 'bbo'">--</span>
                <span v-else>
                  {{ formatNum(orderForm.takeProfitPrice == 0 ? '--': orderForm.takeProfitPrice) + ' / ' + calcTakeProfitEstimatedPL(orderForm) }}
                </span>
              </v-col>
            </v-row>
            <v-divider v-if="'open' == side"></v-divider>
            <!-- 止损1 -->
            <v-row no-gutters v-if="'open' == side">
              <v-col cols="5">{{$t("futuresTrading.stopLoss") + ' 1'}} :</v-col>
              <v-col cols="7">
                <div v-if="orderForm.priceType == 'bbo'">--</div>
                <div v-else>
                  <span v-if="'open_long' == orderForm.side">
                    {{ formatNum((orderForm.price * (1 - orderForm.trailingStopLevel1)).toFixed(futuresDecimalNumbers[symbol])) }}
                  </span>
                  <span v-else-if="'open_short' == orderForm.side">
                    {{ formatNum((orderForm.price * (1 + orderForm.trailingStopLevel1)).toFixed(futuresDecimalNumbers[symbol])) }}
                  </span>
                  <span v-else>--</span>
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters v-if="'open' == side">
              <!-- 止损2 -->
              <v-col cols="5">{{$t("futuresTrading.stopLoss") + ' 2'}} :</v-col>
              <v-col cols="7">
                <div v-if="orderForm.priceType == 'bbo'">--</div>
                <div v-else>
                  <span v-if="'open_long' == orderForm.side">
                    {{ formatNum((orderForm.price * (1 + orderForm.priceLevel2) * (1 - orderForm.trailingStopLevel2)).toFixed(futuresDecimalNumbers[symbol])) }}
                  </span>
                  <span v-else-if="'open_short' == orderForm.side">
                    {{ formatNum((orderForm.price * (1 - orderForm.priceLevel2) * (1 + orderForm.trailingStopLevel2)).toFixed(futuresDecimalNumbers[symbol])) }}
                  </span>
                  <span v-else>--</span>
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters v-if="'open' == side">
              <!-- 止损3 -->
              <v-col cols="5">{{$t("futuresTrading.stopLoss") + ' 3'}} :</v-col>
              <v-col cols="7">
                <div v-if="orderForm.priceType == 'bbo'">--</div>
                <div v-else>
                  <span v-if="'open_long' == orderForm.side">
                    {{ formatNum((orderForm.price * (1 + orderForm.priceLevel3) * (1 - orderForm.trailingStopLevel3)).toFixed(futuresDecimalNumbers[symbol])) }}
                  </span>
                  <span v-else-if="'open_short' == orderForm.side">
                    {{ formatNum((orderForm.price * (1 - orderForm.priceLevel3) * (1 + orderForm.trailingStopLevel3)).toFixed(futuresDecimalNumbers[symbol])) }}
                  </span>
                  <span v-else>--</span>
                  <span v-if="orderForm.trailingStopLevel1 == 0 && orderForm.trailingStopLevel2 == 0 && orderForm.trailingStopLevel3 == 0">{{$t("futuresTrading.noStopPriceNote")}}</span>
                </div>
              </v-col>
            </v-row>
            <v-divider v-if="'open' == side"></v-divider>
            <!-- 时间 -->
            <v-row no-gutters>
              <v-col cols="5" class="align-self-sm-center">{{ $t("futuresTrading.startTime") }} :</v-col>
              <v-col cols="7">
                <datetime :disabled="orderForm.strategy == 'PP' || orderForm.strategy == 'CPP'" input-id="orderFormSendOrderStartDatetime"
                  v-model="orderForm.sendOrderStartDatetime" class="timeBg" type="datetime" :week-start="7" format="MM/dd/yyyy HH:mm:ss"
                  :zone="currentTimeZone" :phrases="{ok: $t('common.ok'), cancel: $t('common.cancel')}" auto
                  :min-datetime="pageRefreshDateTime" :class="[(orderForm.strategy == 'PP' || orderForm.strategy == 'CPP') ? 'cursor-none' : '' ]"></datetime>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row no-gutters>
              <v-col cols="5">{{$t("futuresTrading.orderSide")}}:</v-col>
              <v-col cols="7" :class="[orderSide == 'buy' ?'green--text': 'red--text']">
                {{ side.toUpperCase() }}&nbsp;&nbsp;{{ ('open'==side?('buy'==orderSide?"long":"short"):orderSide).toUpperCase() }}
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-container>
          <v-card-actions v-if="responsive == 'PC'" class="flex-m text-right px-5 pt-0 pb-5">
            <v-spacer></v-spacer>
            <v-btn small tile class="yellow darken-2 black--text" @click="showTrailingStopDialog" v-if="'open' == side">
              {{$t("futuresTrading.continueEditing")}}
            </v-btn>
            <!-- 判断是否存在相同交易对订单 -->
            <v-btn small tile class="blue white--text" :loading="submittedOrderFormLoading" @click="submitOrder">
              {{$t("futuresTrading.send")}}
            </v-btn>
          </v-card-actions>
          <v-card-actions v-if="responsive == 'mobile'" class="flex-b">
           <v-spacer></v-spacer>
            <v-btn small tile class="yellow darken-2 ml-3 black--text" @click="gotoNextOrder3" v-if="'open' == side">
              {{$t("futuresTrading.continueEditing")}}
            </v-btn>
            <v-btn small tile class="blue white--text ml-3" :loading="submittedOrderFormLoading" @click="submitOrder">
              {{$t("futuresTrading.send")}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- *******************取消未提交订单的弹窗******************* -->
    <v-row>
      <v-dialog v-model="deleteUnsubmittedOrderDialog" persistent max-width="490" no-click-animation>
        <v-card>
          <v-card-title class="grey lighten-2">
            <span class="black--text font-md">{{$t("futuresTrading.deleteFuturesOrder")}}</span>
            <v-spacer></v-spacer>
            <v-btn light icon @click="deleteUnsubmittedOrderDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-actions class="px-5 pa-5">
            <v-spacer></v-spacer>
            <v-btn tile small class="blue white--text" :loading="deleteUnsubmittedLoading" @click="deleteUnsubmittedOrder">{{$t("futuresTrading.yes")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- *******************取消已提交订单的弹窗******************* -->
    <v-row>
      <v-dialog v-model="cancelSubmittedOrderDialog" persistent max-width="490" no-click-animation>
        <v-card>
          <v-card-title class="grey lighten-2">
            <span class="black--text font-md">{{$t("futuresTrading.futuresOrder")}}</span>
            <v-spacer></v-spacer>
            <v-btn light icon @click="cancelSubmittedOrderDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-actions class="px-5 pa-5">
            <v-spacer></v-spacer>
            <v-btn small tile class="blue white--text" :loading="cancelSubmittedLoading" @click="cancelSubmittedOrder">{{$t("futuresTrading.yes")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- *******************修改定时器的弹窗changeTimeDialog******************* -->
    <v-row>
      <v-dialog v-model="changeTimeDialog" persistent max-width="550" no-click-animation>
        <v-card>
          <v-card-title class="grey lighten-2">
            <span class="black--text">{{$t("futuresTrading.changeTime")}}</span>
            <v-spacer></v-spacer>
            <v-btn light icon @click="changeTimeDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-container>
            <!-- - - - - - 当前时间 - - - - - -->
            <v-row no-gutters class="fw-bold">
              <v-col cols="6">
                {{ $t("futuresTrading.currentTime") }} {{ time == 'UTCTime' ? '(UTC)' : '(Local)'}}:
              </v-col>
              <v-col cols="6" class="text-right">
                <span>{{ currentTime }}</span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <!-- - - - - - 发单时间 - - - - - -->
            <div v-show="changeTimeOrder.status=='unsubmitted'">
              <v-row no-gutters>
                <v-col cols="6" class="align-self-center">
                  {{ $t("futuresTrading.sendOrderStartTime") }}:
                </v-col>
                <v-col cols="6">
                  <datetime input-id="changeTimeOrderSendOrderStartDatetime" v-model="changeTimeOrder.sendOrderStartTimestamp"
                    type="datetime" :week-start="7" format="MM/dd/yyyy HH:mm:ss" :zone="currentTimeZone" :phrases="{ok: $t('common.ok'), cancel: $t('common.cancel')}"
                    auto class="timeBg" :min-datetime="changeTimeOrder.timestamp"></datetime>
                </v-col>
              </v-row>
              <v-row class="mt-3">
                <v-col cols="6" md="6">
                  {{ $t("futuresTrading.sendOrderEndTime") }}:
                </v-col>
                <v-col cols="3" md="3">
                  {{ $t("futuresTrading.infinity") }}:
                </v-col>
                <v-col cols="3" md="3">
                  <v-switch color="blue" small v-model="changeTimeOrder.sendOrderEndSwitch"></v-switch>
                </v-col>
              </v-row>
              <v-row no-gutters v-show="!changeTimeOrder.sendOrderEndSwitch">
                <v-spacer></v-spacer>
                <v-col cols="6">
                  <datetime input-id="changeTimeOrderSendOrderEndDatetime" v-model="changeTimeOrder.sendOrderEndTimestamp"
                    type="datetime" :week-start="7" format="MM/dd/yyyy HH:mm:ss" :zone="currentTimeZone" :phrases="{ok: $t('common.ok'), cancel: $t('common.cancel')}"
                    auto class="timeBg" :min-datetime="changeTimeOrder.sendOrderStartTimestamp"></datetime>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </div>
            <!-- - - - - - 止盈时间 - - - - - -->
            <div v-show="changeTimeOrder.status!='unsubmitted' && (changeTimeOrder.side=='open_long' || changeTimeOrder.side=='open_short')">
              <v-row no-gutters>
                <v-col cols="6" class="align-self-sm-center">
                  {{ $t("futuresTrading.takeProfitStartTime") }}:
                </v-col>
                <v-col cols="6">
                  <datetime input-id="changeTimeOrderTakeProfitStartTimestamp" v-model="changeTimeOrder.takeProfitStartTimestamp"
                    type="datetime" :week-start="7" format="MM/dd/yyyy HH:mm:ss" :zone="currentTimeZone" :phrases="{ok: $t('common.ok'), cancel: $t('common.cancel')}"
                    auto class="timeBg" :min-datetime="changeTimeOrder.timestamp"></datetime>
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-3">
                <v-col cols="6">
                  {{ $t("futuresTrading.takeProfitEndTime") }}:
                </v-col>
                <v-col cols="3">
                  {{ $t("futuresTrading.infinity") }}:
                </v-col>
                <v-col cols="3">
                  <v-switch color="blue" small v-model="changeTimeOrder.takeProfitEndSwitch"></v-switch>
                </v-col>
              </v-row>
              <v-row no-gutters v-show="!changeTimeOrder.takeProfitEndSwitch">
                <v-spacer></v-spacer>
                <v-col cols="6" align-self="end">
                  <datetime input-id="changeTimeOrderTakeProfitEndTimestamp" v-model="changeTimeOrder.takeProfitEndTimestamp"
                    type="datetime" :week-start="7" format="MM/dd/yyyy HH:mm:ss" :zone="currentTimeZone" :phrases="{ok: $t('common.ok'), cancel: $t('common.cancel')}" auto class="timeBg" :min-datetime="changeTimeOrder.takeProfitStartTimestamp"></datetime>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </div>
            <!-- - - - - - 保底止损时间 - - - - - -->
            <div v-show="changeTimeOrder.status!='unsubmitted' && (changeTimeOrder.side=='open_long' || changeTimeOrder.side=='open_short')">
              <v-row no-gutters>
                <v-col cols="6" class="align-self-sm-center">
                  {{ $t("futuresTrading.stopLoss1StartTime") }}:
                </v-col>
                <v-col cols="6">
                  <datetime input-id="changeTimeOrderStopLoss1StartTimestamp" v-model="changeTimeOrder.stopLoss1StartTimestamp"
                    type="datetime" :week-start="7" format="MM/dd/yyyy HH:mm:ss" :zone="currentTimeZone" :phrases="{ok: $t('common.ok'), cancel: $t('common.cancel')}"
                    auto class="timeBg" :min-datetime="changeTimeOrder.timestamp"></datetime>
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-3">
                <v-col cols="6">
                  {{ $t("futuresTrading.stopLoss1EndTime") }}:
                </v-col>
                <v-col cols="3">
                  {{ $t("futuresTrading.infinity") }}:
                </v-col>
                <v-col cols="3">
                  <v-switch color="blue" small v-model="changeTimeOrder.stopLoss1EndSwitch"></v-switch>
                </v-col>
              </v-row>
              <v-row no-gutters v-show="!changeTimeOrder.stopLoss1EndSwitch">
                <v-spacer></v-spacer>
                <v-col cols="6" align-self="end">
                  <datetime input-id="changeTimeOrderStopLoss1EndTimestamp" v-model="changeTimeOrder.stopLoss1EndTimestamp"
                    type="datetime" :week-start="7" format="MM/dd/yyyy HH:mm:ss" :zone="currentTimeZone" :phrases="{ok: $t('common.ok'), cancel: $t('common.cancel')}"
                    auto class="timeBg" :min-datetime="changeTimeOrder.stopLoss1StartTimestamp"></datetime>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </div>
            <!-- - - - - - 止损时间 - - - - - -->
            <div v-show="changeTimeOrder.status!='unsubmitted' && !changeTimeOrder.beFixedStopLossPrice && (changeTimeOrder.side=='open_long' || changeTimeOrder.side=='open_short')">
              <v-row no-gutters>
                <v-col cols="6" class="align-self-sm-center">
                  {{ $t("futuresTrading.stopLossStartTime") }}:
                </v-col>
                <v-col cols="6">
                  <datetime input-id="changeTimeOrderStopLossStartTimestamp" v-model="changeTimeOrder.stopLossStartTimestamp"
                    type="datetime" :week-start="7" format="MM/dd/yyyy HH:mm:ss" :zone="currentTimeZone" :phrases="{ok: $t('common.ok'), cancel: $t('common.cancel')}"
                    auto class="timeBg" :min-datetime="changeTimeOrder.timestamp"></datetime>
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-3">
                <v-col cols="6">
                  {{ $t("futuresTrading.stopLossEndTime") }}:
                </v-col>
                <v-col cols="3">
                  {{ $t("futuresTrading.infinity") }}:
                </v-col>
                <v-col cols="3">
                  <v-switch color="blue" small v-model="changeTimeOrder.stopLossEndSwitch"></v-switch>
                </v-col>
              </v-row>
              <v-row no-gutters v-show="!changeTimeOrder.stopLossEndSwitch">
                <v-spacer></v-spacer>
                <v-col cols="6" align-self="end">
                  <datetime input-id="changeTimeOrderStopLossEndTimestamp" v-model="changeTimeOrder.stopLossEndTimestamp"
                    type="datetime" :week-start="7" format="MM/dd/yyyy HH:mm:ss" :zone="currentTimeZone" :phrases="{ok: $t('common.ok'), cancel: $t('common.cancel')}"
                    auto class="timeBg" :min-datetime="changeTimeOrder.stopLossStartTimestamp"></datetime>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </div>
            <!-- - - - - - 固定止损时间 - - - - - -->
            <div v-show="changeTimeOrder.beFixedStopLossPrice && (changeTimeOrder.side=='open_long' || changeTimeOrder.side=='open_short')">
              <v-row no-gutters class="mt-3">
                <v-col cols="6" class="align-self-sm-center">
                  {{ $t("futuresTrading.fixedStopLossStartTime") }}:
                </v-col>
                <v-col cols="6">
                  <datetime input-id="changeTimeOrderFixedStopLossStartTimestamp" v-model="changeTimeOrder.fixedStopLossStartTimestamp"
                    type="datetime" :week-start="7" format="MM/dd/yyyy HH:mm:ss" :zone="currentTimeZone" :phrases="{ok: $t('common.ok'), cancel: $t('common.cancel')}"
                    auto class="timeBg" :min-datetime="changeTimeOrder.timestamp"></datetime>
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-3">
                <v-col cols="6">
                  {{ $t("futuresTrading.fixedStopLossEndTime") }}:
                </v-col>
                <v-col cols="3">
                  {{ $t("futuresTrading.infinity") }}:
                </v-col>
                <v-col cols="3">
                  <v-switch color="blue" small v-model="changeTimeOrder.fixedStopLossEndSwitch"></v-switch>
                </v-col>
              </v-row>
              <v-row no-gutters v-show="!changeTimeOrder.fixedStopLossEndSwitch">
                <v-spacer></v-spacer>
                <v-col cols="6" align-self="end">
                  <datetime input-id="changeTimeOrderFixedStopLossEndTimestamp" v-model="changeTimeOrder.fixedStopLossEndTimestamp"
                    type="datetime" :week-start="7" format="MM/dd/yyyy HH:mm:ss" :zone="currentTimeZone" :phrases="{ok: $t('common.ok'), cancel: $t('common.cancel')}"
                    auto class="timeBg" :min-datetime="changeTimeOrder.fixedStopLossStartTimestamp"></datetime>
                </v-col>
              </v-row>
            </div>
          </v-container>
          <v-card-actions class="px-5 pb-5">
            <v-spacer></v-spacer>
            <v-btn small tile class="blue white--text" :loading="changeTimeLoading" @click="submitChangeTimeOrder">
              {{ $t("futuresTrading.update") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- *******************修改订单的弹窗******************* -->
    <v-row>
      <v-dialog v-model="changeOrderDialog" persistent max-width="580">
        <v-card>
          <v-card-title class="grey lighten-2">
            <span class="black--text">{{$t("futuresTrading.changeThisFuturesOrder")}}</span>
            <v-spacer></v-spacer>
            <v-btn light icon @click="changeOrderDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-container class="pt-3 pb-0">
            <h5 class="fw-bold">
              <span v-show="'unsubmitted' == showChangedFuturesOrder.status && ('open_long' == showChangedFuturesOrder.side || 'open_short' == showChangedFuturesOrder.side)">{{$t("futuresTrading.pendingEntryOrderStatus")}}</span>
              <span v-show="'unsubmitted' == showChangedFuturesOrder.status && ('close_buy' == showChangedFuturesOrder.side || 'close_sell' == showChangedFuturesOrder.side)">{{$t("futuresTrading.pendingExitOrderStatus")}}</span>
              <span v-show="'submitted' == showChangedFuturesOrder.status">{{$t("futuresTrading.unfilledAtExchangesOrderStatus")}}</span>
              <span v-show="'partially_unfilled' == showChangedFuturesOrder.status">{{$t("futuresTrading.unfilledAtExchangesOrderStatus")}}</span>
              <span v-show="'fully_filled' == showChangedFuturesOrder.status">{{$t("futuresTrading.openPositionsOrderStatus")}}</span>
              <span v-show="'splited' == showChangedFuturesOrder.status">{{$t("futuresTrading.splitedOrderStatus")}}</span>
            </h5>
            <v-row dense class="mt-3">
              <v-col cols="6" sm="6" md="4">
                {{$t("futuresTrading.exchange")}}: {{ getExchangeNameById(showChangedFuturesOrder.exchangeId) }}
              </v-col>
              <v-col cols="6" sm="6" md="4">
                {{$t("futuresTrading.symbol")}}: {{ showChangedFuturesOrder.symbol }}
              </v-col>
              <v-col cols="6" sm="6" md="4">
                {{$t("futuresTrading.side")}}:
                <span class="ml-1" :class=" ('open_long' == showChangedFuturesOrder.side || 'close_buy' == showChangedFuturesOrder.side) ? 'green--text' : 'red--text'">{{ formatSide(showChangedFuturesOrder.side) }}</span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <!--******************** 编辑订单 ********************** -->
            <div class="mt-3">
              <!-- 未持仓之前才可以编辑价格 -->
              <!-- Edit Size -->
              <!-- 'fully_filled' != showChangedFuturesOrder.status && 'splited' != showChangedFuturesOrder.status -->
              <v-row no-gutters dense v-show="'unsubmitted' == showChangedFuturesOrder.status || 'submitted' == showChangedFuturesOrder.status">
                <v-col cols="5" align-self="center">
                  {{$t("futuresTrading.size")}}:
                </v-col>
                <v-col cols="7" class="text-right">
                  <span v-show="isEditingFuturesOrder">
                    <v-text-field dense v-model="changedFuturesOrderForm.size" type="number" min="0" :suffix="changedFuturesOrderForm.sizeDollarValue"
                      @input="calcChangeOrderSizeDollarValue"></v-text-field>
                  </span>
                  <span v-show="!isEditingFuturesOrder" class="mt-3">
                    {{ showChangedFuturesOrder.side == 'open_short' || showChangedFuturesOrder.side == 'close_sell' ? '-' : '' }}{{ formatNum(changedFuturesOrderForm.size) }}
                  </span>
                </v-col>
              </v-row>
              <v-row no-gutters dense v-show="'fully_filled' != showChangedFuturesOrder.status && 'splited' != showChangedFuturesOrder.status">
                <v-col cols="5" align-self="center">
                  <span v-show="showChangedFuturesOrder.side == 'open_long' || showChangedFuturesOrder.side == 'open_short'">{{$t("futuresTrading.entryPrice")}}:</span>
                  <span v-show="showChangedFuturesOrder.side == 'close_buy' || showChangedFuturesOrder.side == 'close_sell'">{{$t("futuresTrading.exitPrice")}}:</span>
                </v-col>
                <!-- Edit renko Price -->
                <v-col cols="3" class="text-right" v-show="showChangedFuturesOrder.strategy != 'PP' && showChangedFuturesOrder.strategy != 'CPP'">
                  <span v-show="isEditingFuturesOrder">
                    <v-text-field dense class="mr-3" single-line v-model="changedFuturesOrderForm.price" type="number"
                      min="0" :step="getTickSize(showChangedFuturesOrder.symbol)" :disabled="changedFuturesOrderForm.priceType == 'bbo'"></v-text-field>
                  </span>
                </v-col>
                <!-- Edit renko Range -->
                <v-col cols="4" class="text-right" v-show="showChangedFuturesOrder.strategy != 'PP' && showChangedFuturesOrder.strategy != 'CPP'">
                  <span v-if="isEditingFuturesOrder">
                    <div class="d-custom-flex">
                      <v-select dense v-model="changedFuturesOrderForm.renkoEntryMultiplier" :items="renkoMultipliers" item-text="text" item-value="value" :label="$t('common.renkoEntryMultiplier')" :disabled="changedFuturesOrderForm.priceType == 'bbo'" required></v-select>
                      <v-btn small tile dark color="blue" class="ml-2" :outlined="changedFuturesOrderForm.priceType != 'bbo'" @click="changePriceType('edit')">BBO</v-btn>
                    </div>
                  </span>
                  <span v-show="!isEditingFuturesOrder" class="mt-3">
                    <span v-if="changedFuturesOrderForm.priceType == 'bbo'">
                      {{ $t('common.bestBidOffer') }}
                    </span>
                    <span v-else>
                      {{ formatNum(changedFuturesOrderForm.price) }} [{{ changedFuturesOrderForm.renkoEntryMultiplier }}]
                    </span>
                  </span>
                </v-col>
                <!-- Edit 非renko Price -->
                <v-col cols="7" class="text-right" v-show="showChangedFuturesOrder.strategy == 'PP' || showChangedFuturesOrder.strategy == 'CPP'">
                  <span v-show="isEditingFuturesOrder">
                    <div class="d-custom-flex">
                      <v-text-field dense v-model="changedFuturesOrderForm.price" :step="getTickSize(showChangedFuturesOrder.symbol)" type="number" min="0" :disabled="changedFuturesOrderForm.priceType == 'bbo'"></v-text-field>
                      <v-btn small tile dark color="blue" class="ml-2" :outlined="changedFuturesOrderForm.priceType != 'bbo'" @click="changePriceType('edit')">BBO</v-btn>
                    </div>
                  </span>
                  <span v-show="!isEditingFuturesOrder" class="mt-3">
                    <span v-if="changedFuturesOrderForm.priceType == 'bbo'">
                      {{ $t('common.bestBidOffer') }}
                    </span>
                    <span v-else>
                      {{ formatNum(changedFuturesOrderForm.price) }}
                    </span>
                  </span>
                </v-col>
              </v-row>
              <!-- Edit Take Ptofit -->
              <v-row no-gutters dense v-show="'open_long' == showChangedFuturesOrder.side || 'open_short' == showChangedFuturesOrder.side">
                <v-col cols="5" align-self="center">
                  {{$t("futuresTrading.takeProfit")}}:
                </v-col>
                <v-col cols="7" class="text-right">
                  <span v-show="isEditingFuturesOrder">
                    <v-text-field dense v-model="changedFuturesOrderForm.takeProfitPrice" :step="getTickSize(showChangedFuturesOrder.symbol)"
                      type="number" min="0" id="green_font"></v-text-field>
                  </span>
                  <span v-show="!isEditingFuturesOrder" class="mt-3">
                    {{ formatNum(changedFuturesOrderForm.takeProfitPrice) }}
                  </span>
                </v-col>
              </v-row>
            </div>
            <!-- *********************编辑订单计算止损价************* -->
            <div v-show="!changedFuturesOrderForm.beFixedStopLossPrice">
              <v-row no-gutters dense v-show="'open_long' == showChangedFuturesOrder.side || 'open_short' == showChangedFuturesOrder.side">
                <h5 v-show="!isEditingFuturesOrder" class="mt-5">
                  {{ $t("futuresTrading.estimatedResultsNote") }}:
                </h5>
              </v-row>
              <!-- Trailing Stop1 -->
              <v-row no-gutter dense class="red--text" v-show="'open_long' == showChangedFuturesOrder.side || 'open_short' == showChangedFuturesOrder.side">
                <v-col cols="5" align-self="center">{{$t("futuresTrading.safetyNet")}}</v-col>
                <v-col cols="7" class="text-right px-0">
                  <span v-show="isEditingFuturesOrder">
                    <v-text-field dense v-model="changedFuturesOrderTrailingStopForm.trailingStopLevel1" type="number"
                      min="0" max="100" suffix="%" :rules="trailingStopLevelRules"></v-text-field>
                  </span>
                  <span v-show="!isEditingFuturesOrder" class="mt-3">
                    <span v-if="changedFuturesOrderForm.priceType == 'bbo'">
                      {{ changedFuturesOrderTrailingStopForm.trailingStopLevel1 + '%' }}
                    </span>
                    <span v-else>{{ changedFuturesOrderFormTrailingStopLevel1Result }}</span>
                  </span>
                </v-col>
              </v-row>
              <!-- Price2 -->
              <v-row no-gutter dense class="green--text" v-show="'open_long' == showChangedFuturesOrder.side || 'open_short' == showChangedFuturesOrder.side">
                <v-col cols="5" align-self="center">{{$t("futuresTrading.priceLevel2")}}</v-col>
                <v-col cols="7" class="text-right px-0">
                  <span v-show="isEditingFuturesOrder">
                    <v-text-field dense v-model="changedFuturesOrderTrailingStopForm.priceLevel2" type="number" min="0"
                      max="100" suffix="%" :rules="trailingStopLevelRules"></v-text-field>
                  </span>
                  <span v-show="!isEditingFuturesOrder" class="mt-3">
                    <span v-if="changedFuturesOrderForm.priceType == 'bbo'">
                      {{ changedFuturesOrderTrailingStopForm.priceLevel2 + '%' }}
                    </span>
                    <span v-else>{{ changedFuturesOrderFormPriceLevel2Result }}</span>
                  </span>
                </v-col>
              </v-row>
              <!-- Trailing Stop2 -->
              <v-row no-gutter dense class="red--text" v-show="'open_long' == showChangedFuturesOrder.side || 'open_short' == showChangedFuturesOrder.side">
                <v-col cols="5" align-self="center">{{$t("futuresTrading.trailingStopLevel2")}}</v-col>
                <v-col cols="7" class="text-right px-0">
                  <span v-show="isEditingFuturesOrder">
                    <v-text-field dense v-model="changedFuturesOrderTrailingStopForm.trailingStopLevel2" type="number"
                      min="0" max="100" suffix="%" :rules="trailingStopLevelRules"></v-text-field>
                  </span>
                  <span v-show="!isEditingFuturesOrder" class="mt-3">
                    <span v-if="changedFuturesOrderForm.priceType == 'bbo'">
                      {{ changedFuturesOrderTrailingStopForm.trailingStopLevel2 + '%' }}
                    </span>
                    <span v-else>{{ changedFuturesOrderFormTrailingStopLevel2Result }}</span>
                  </span>
                </v-col>
              </v-row>
              <!-- Price3 -->
              <v-row no-gutter dense class="green--text" v-show="'open_long' == showChangedFuturesOrder.side || 'open_short' == showChangedFuturesOrder.side">
                <v-col cols="5" align-self="center">{{$t("futuresTrading.priceLevel3")}}</v-col>
                <v-col cols="7" class="text-right px-0">
                  <span v-show="isEditingFuturesOrder">
                    <v-text-field dense v-model="changedFuturesOrderTrailingStopForm.priceLevel3" type="number" min="0"
                      max="100" suffix="%" :rules="trailingStopLevelRules"></v-text-field>
                  </span>
                  <span v-show="!isEditingFuturesOrder" class="mt-3">
                    <span v-if="changedFuturesOrderForm.priceType == 'bbo'">
                      {{ changedFuturesOrderTrailingStopForm.priceLevel3 + '%' }}
                    </span>
                    <span v-else>{{ changedFuturesOrderFormPriceLevel3Result }}</span>
                  </span>
                </v-col>
              </v-row>
              <!-- Trailing Stop3 -->
              <v-row no-gutter dense class="red--text" v-show="'open_long' == showChangedFuturesOrder.side || 'open_short' == showChangedFuturesOrder.side">
                <v-col cols="5" align-self="center" no-gutter>{{$t("futuresTrading.trailingStopLevel3")}}</v-col>
                <v-col cols="7" class="text-right px-0" no-gutter>
                  <span v-show="isEditingFuturesOrder">
                    <v-text-field dense v-model="changedFuturesOrderTrailingStopForm.trailingStopLevel3" type="number"
                      min="0" max="100" suffix="%" :rules="trailingStopLevelRules"></v-text-field>
                  </span>
                  <span v-show="!isEditingFuturesOrder" class="mt-3">
                    <span v-if="changedFuturesOrderForm.priceType == 'bbo'">
                      {{ changedFuturesOrderTrailingStopForm.trailingStopLevel3 + '%' }}
                    </span>
                    <span v-else>{{ changedFuturesOrderFormTrailingStopLevel3Result }}</span>
                  </span>
                </v-col>
              </v-row>
            </div>
            <!-- *********************切换止损比例和固定止损************ -->
            <v-row no-gutter dense v-show="'open_long' == showChangedFuturesOrder.side || 'open_short' == showChangedFuturesOrder.side">
              <v-col cols="5">
                <span v-show="isEditingFuturesOrder">
                  <v-checkbox dense class="fs-16 red--text" v-model="changedFuturesOrderForm.beFixedStopLossPrice"
                    :label="$t('futuresTrading.beFixedStopLossPrice')"></v-checkbox>
                </span>
                <span v-show="changedFuturesOrderForm.beFixedStopLossPrice && !isEditingFuturesOrder">
                  {{ $t('futuresTrading.fixedStopLossPrice') }}:
                </span>
              </v-col>
              <v-col cols="4" class="text-right px-0" v-show="showChangedFuturesOrder.strategy != 'PP' && showChangedFuturesOrder.strategy != 'CPP'">
                <span v-show="changedFuturesOrderForm.beFixedStopLossPrice && isEditingFuturesOrder">
                  <v-text-field dense class="mr-3" v-model="changedFuturesOrderForm.fixedStopLossPrice" type="number"
                    min="0" id="red_font" :step="getTickSize(showChangedFuturesOrder.symbol)"></v-text-field>
                </span>
              </v-col>
              <v-col cols="3" class="text-right px-0" v-show="showChangedFuturesOrder.strategy != 'PP' && showChangedFuturesOrder.strategy != 'CPP'">
                <span v-show="changedFuturesOrderForm.beFixedStopLossPrice && isEditingFuturesOrder">
                  <v-select dense v-model="changedFuturesOrderForm.renkoExitMultiplier" :items="renkoMultipliers"
                    item-text="text" item-value="value" :label="$t('common.renkoExitMultiplier')" required></v-select>
                </span>
                <span v-show="changedFuturesOrderForm.beFixedStopLossPrice && !isEditingFuturesOrder" class="mt-3">
                  {{ formatNum(changedFuturesOrderForm.fixedStopLossPrice) }} [{{ changedFuturesOrderForm.renkoExitMultiplier }}]
                </span>
              </v-col>
              <v-col cols="7" class="text-right" v-show="showChangedFuturesOrder.strategy == 'PP' || showChangedFuturesOrder.strategy == 'CPP'">
                <span v-show="changedFuturesOrderForm.beFixedStopLossPrice && isEditingFuturesOrder">
                  <v-text-field dense v-model="changedFuturesOrderForm.fixedStopLossPrice" :step="getTickSize(showChangedFuturesOrder.symbol)"
                    type="number" min="0" id="red_font"></v-text-field>
                </span>
                <span v-show="changedFuturesOrderForm.beFixedStopLossPrice && !isEditingFuturesOrder" class="mt-3">
                  {{ formatNum(changedFuturesOrderForm.fixedStopLossPrice) }}
                </span>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions class="px-5 pb-5">
            <v-spacer></v-spacer>
            <v-btn tile small v-show="isEditingFuturesOrder" class="blue white--text" @click="isEditingFuturesOrder = !isEditingFuturesOrder">{{$t("futuresTrading.confirm")}}</v-btn>
            <v-btn tile small v-show="!isEditingFuturesOrder" class="yellow darken-2 black--text" @click="isEditingFuturesOrder = !isEditingFuturesOrder">{{$t("futuresTrading.continueEditing")}}</v-btn>
            <v-btn tile small v-show="!isEditingFuturesOrder" class="blue white--text ml-3" :loading="changeOrderLoading"
              @click="submitChangedFuturesOrder">{{$t("futuresTrading.update")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- *******************切割订单的弹窗******************* -->
    <v-row>
      <v-dialog v-model="splitOrderDialog" persistent max-width="550">
        <v-card>
          <v-card-title class="grey lighten-2">
            <span class="black--text">{{$t("futuresTrading.splitThisFuturesOrder")}}</span>
            <v-spacer></v-spacer>
            <v-btn light icon @click="closeSplitOrderDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-container>
            <h5>{{$t("futuresTrading.openPositionsOrderStatus")}}</h5>
            <v-row no-gutter dense>
              <v-col cols="6" sm="4" md="4">
                {{$t("futuresTrading.exchange")}}: {{ getExchangeNameById(showSplitFuturesOrder.exchangeId) }}
              </v-col>
              <v-col cols="6" sm="4" md="4">
                {{$t("futuresTrading.symbol")}}: {{ showSplitFuturesOrder.symbol }}
              </v-col>
              <v-col cols="6" sm="4" md="4">
                {{$t("futuresTrading.side")}}:
                <span class="ml-1" :class=" ('open_long' == showSplitFuturesOrder.side || 'close_buy' == showSplitFuturesOrder.side) ? 'green--text' : 'red--text'">{{ showSplitFuturesOrder.side }}</span>
              </v-col>
            </v-row>
            <v-row no-gutter dense>
              <v-col cols="6" sm="4" md="4">
                {{$t("futuresTrading.leverage")}}: {{ showSplitFuturesOrder.leverage }}
              </v-col>
              <v-col cols="6" sm="4" md="4">
                {{$t("futuresTrading.size")}}: {{ formatNum(showSplitFuturesOrder.size) }}
              </v-col>
              <v-col cols="6" sm="4" md="4">
                {{$t("futuresTrading.price")}}: {{ formatNum(showSplitFuturesOrder.price) }}
              </v-col>
            </v-row>
            <v-row no-gutter dense>
              <v-col cols="6" sm="6" md="6">
                {{$t("futuresTrading.takeProfit")}}: {{ formatNum(showSplitFuturesOrder.takeProfitPrice > 0 ? showSplitFuturesOrder.takeProfitPrice : '--') }}
              </v-col>
              <v-col cols="6" sm="6" md="6">
                {{$t("futuresTrading.stopLoss")}}: {{ formatNum(showSplitFuturesOrder.stopLossPrice > 0 ? showSplitFuturesOrder.stopLossPrice : '--') }}
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <h4 class="mt-4 font-weight-bold">{{$t("futuresTrading.split")}}</h4>
            <!--******************** 编辑分割订单 ********************** -->
            <v-row no-gutter dense>
              <v-col cols="6" sm="6" md="5" align-self="center">
                {{$t("futuresTrading.size")}}:
              </v-col>
              <v-col cols="6" sm="6" md="6" class="text-right">
                <v-text-field dense single-line v-model="splitFuturesOrderForm.size" type="number" min="0" :suffix="splitFuturesOrderForm.sizeDollarValue"
                  @input="calcSplitOrderSizeDollarValue"></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutter dense v-show="'open_long' == showSplitFuturesOrder.side || 'open_short' == showSplitFuturesOrder.side">
              <v-col cols="6" sm="6" md="5" align-self="center">
                {{$t("futuresTrading.takeProfit")}}:
              </v-col>
              <v-col cols="6" sm="6" md="6" class="text-right">
                <v-text-field dense single-line v-model="splitFuturesOrderForm.takeProfitPrice" :step="getTickSize(showSplitFuturesOrder.symbol)"
                  type="number" min="0"></v-text-field>
              </v-col>
            </v-row>
            <!-- Trailing Stop1 -->
            <v-row no-gutter dense class="red--text" v-show="'open_long' == showSplitFuturesOrder.side || 'open_short' == showSplitFuturesOrder.side">
              <v-col cols="6" sm="6" md="5" align-self="center">{{$t("futuresTrading.safetyNet")}}</v-col>
              <v-col cols="6" sm="6" md="6" class="text-right">
                <v-text-field dense single-line v-model="splitFuturesOrderTrailingStopForm.trailingStopLevel1" type="number"
                  min="0" max="100" suffix="%" :rules="trailingStopLevelRules"></v-text-field>
              </v-col>
            </v-row>
            <!-- Price2 -->
            <v-row no-gutter dense class="green--text" v-show="'open_long' == showSplitFuturesOrder.side || 'open_short' == showSplitFuturesOrder.side">
              <v-col cols="6" sm="6" md="5" align-self="center">{{$t("futuresTrading.priceLevel2")}}</v-col>
              <v-col cols="6" sm="6" md="6" class="text-right">
                <v-text-field dense single-line v-model="splitFuturesOrderTrailingStopForm.priceLevel2" type="number"
                  min="0" max="100" suffix="%" :rules="trailingStopLevelRules"></v-text-field>
              </v-col>
            </v-row>
            <!-- Trailing Stop2 -->
            <v-row no-gutter dense class="red--text" v-show="'open_long' == showSplitFuturesOrder.side || 'open_short' == showSplitFuturesOrder.side">
              <v-col cols="6" sm="6" md="5" align-self="center">{{$t("futuresTrading.trailingStopLevel2")}}</v-col>
              <v-col cols="6" sm="6" md="6" class="text-right">
                <v-text-field dense single-line v-model="splitFuturesOrderTrailingStopForm.trailingStopLevel2" type="number"
                  min="0" max="100" suffix="%" :rules="trailingStopLevelRules"></v-text-field>
              </v-col>
            </v-row>
            <!-- Price3 -->
            <v-row no-gutter dense class="green--text" v-show="'open_long' == showSplitFuturesOrder.side || 'open_short' == showSplitFuturesOrder.side">
              <v-col cols="6" sm="6" md="5" align-self="center">{{$t("futuresTrading.priceLevel3")}}</v-col>
              <v-col cols="6" sm="6" md="6" class="text-right">
                <v-text-field dense single-line v-model="splitFuturesOrderTrailingStopForm.priceLevel3" type="number"
                  min="0" max="100" suffix="%" :rules="trailingStopLevelRules"></v-text-field>
              </v-col>
            </v-row>
            <!-- Trailing Stop3 -->
            <v-row no-gutter dense class="red--text" v-show="'open_long' == showSplitFuturesOrder.side || 'open_short' == showSplitFuturesOrder.side">
              <v-col cols="6" sm="6" md="5" align-self="center">{{$t("futuresTrading.trailingStopLevel3")}}</v-col>
              <v-col cols="6" sm="6" md="6" class="text-right">
                <v-text-field dense single-line v-model="splitFuturesOrderTrailingStopForm.trailingStopLevel3" type="number"
                  min="0" max="100" suffix="%" :rules="trailingStopLevelRules"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions class="mx-5 pb-5">
            <v-spacer></v-spacer>
            <v-btn small tile class="blue white--text ml-3" :loading="splitOrderLoading" @click="splitFuturesOrder">{{$t("futuresTrading.split")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- *******************退出仓位的订单的弹窗******************* -->
    <v-row>
      <v-dialog v-model="exitPositionOrderDialog" persistent max-width="550">
        <v-card>
          <v-card-title class="grey lighten-2">
            <span class="black--text">{{$t("futuresTrading.exitOrder")}}</span>
            <v-spacer></v-spacer>
            <v-btn light icon @click="exitPositionOrderDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-container>
            <h5>{{$t("futuresTrading.openPositionsOrderStatus")}}</h5>
            <v-row no-gutter dense>
              <v-col cols="6" sm="4" md="4">
                {{$t("futuresTrading.exchange")}}: {{ getExchangeNameById(showExitPositionOrder.exchangeId) }}
              </v-col>
              <v-col cols="6" sm="4" md="4">
                {{$t("futuresTrading.symbol")}}: {{ showExitPositionOrder.symbol }}
              </v-col>
              <v-col cols="6" sm="4" md="4">
                {{$t("futuresTrading.side")}}:
                <span class="ml-1" :class=" ('open_long' == showExitPositionOrder.side || 'close_buy' == showExitPositionOrder.side) ? 'green--text' : 'red--text'">{{ showExitPositionOrder.side }}</span>
              </v-col>
            </v-row>
            <v-row no-gutter dense>
              <v-col cols="6" sm="4" md="4">
                {{$t("futuresTrading.leverage")}}: {{ showExitPositionOrder.leverage }}
              </v-col>
              <v-col cols="6" sm="4" md="4">
                {{$t("futuresTrading.size")}}: {{ showExitPositionOrder.size }}
              </v-col>
              <v-col cols="6" sm="4" md="4">
                {{$t("futuresTrading.entryPrice")}}: {{ showExitPositionOrder.price }}
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row no-gutter dense>
              <v-col cols="4" align-self="center">
                {{$t("futuresTrading.closeOrExit")}}
              </v-col>
              <v-col cols="8" sm="8" xs="3" class="text-right">
                <v-btn small outlined tile :disabled="!isEditingExitPositionOrder" @click="exitPositionOrderForm.priceType = 'manually'"  v-show="isEditingExitPositionOrder || exitPositionOrderForm.priceType == 'manually'">
                  <v-icon small class="mr-2" v-if="exitPositionOrderForm.priceType == 'manually'">mdi-check</v-icon>
                  {{$t("futuresTrading.manually")}}
                </v-btn>
                <v-btn class="ml-3" small outlined tile :disabled="!isEditingExitPositionOrder" @click="exitPositionOrderForm.priceType = 'bbo'" v-show="isEditingExitPositionOrder || exitPositionOrderForm.priceType == 'bbo'">
                  <v-icon small class="mr-2" v-if="exitPositionOrderForm.priceType == 'bbo'">mdi-check</v-icon>
                  {{$t("futuresTrading.bestBidOffer")}}
                </v-btn>
              </v-col>
            </v-row>
            <v-row no-gutter dense>
              <v-col cols="7" align-self="center">
                {{$t("futuresTrading.exitPrice")}}
              </v-col>
              <!-- price -->
              <v-col cols="4" class="text-right" v-show="isEditingExitPositionOrder && exitPositionOrderForm.priceType == 'manually'">
                <v-text-field dense single-line v-model="exitPositionOrderForm.price" :step="getTickSize(showExitPositionOrder.symbol)"
                  type="number" min="0"></v-text-field>
              </v-col>
              <v-col cols="1" class="text-right" v-show="isEditingExitPositionOrder && exitPositionOrderForm.priceType == 'manually'">
                <v-icon class="mt-2" @click="getExitPositionBestBidOffer">mdi-refresh</v-icon>
              </v-col>
              <v-col cols="5" class="text-right" v-show="isEditingExitPositionOrder && exitPositionOrderForm.priceType == 'bbo'">
                <span class="mt-4">{{$t("futuresTrading.bestBidOffer")}}</span>
              </v-col>
              <v-col cols="5" class="text-right" v-show="!isEditingExitPositionOrder">
                <span class="mt-4" v-if="exitPositionOrderForm.priceType == 'manually'">
                  {{ exitPositionOrderForm.price }}
                </span>
                <span class="mt-4" v-if="exitPositionOrderForm.priceType == 'bbo'">
                  {{$t("futuresTrading.bestBidOffer")}}
                </span>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions class="px-5 pb-5">
            <v-spacer></v-spacer>
            <v-btn small tile v-show="isEditingExitPositionOrder" class="blue white--text" @click="isEditingExitPositionOrder = !isEditingExitPositionOrder">
              {{$t("futuresTrading.confirm")}}
            </v-btn>
            <v-btn small tile v-show="!isEditingExitPositionOrder" class="orange white--text" @click="isEditingExitPositionOrder = !isEditingExitPositionOrder">
              {{$t("futuresTrading.change")}}
            </v-btn>
            <v-btn small tile v-show="!isEditingExitPositionOrder" class="blue white--text ml-3" :loading="exitPositionOrderLoading"
              @click="submitExitOrder">
              {{$t("futuresTrading.send")}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- *******************修改备注的弹窗******************* -->
    <v-row>
      <v-dialog v-model="changeNoteDialog" persistent max-width="400">
        <v-card>
          <v-card-title class="grey lighten-2">
            <span class="black--text">{{$t("futuresTrading.changeNoteTitle")}}</span>
            <v-spacer></v-spacer>
            <v-btn light icon @click="changeNoteDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-container>
            <v-row justify="center">
              <v-col cols="6">
                <v-select v-model="changeNoteForm.strategy" :items="strategies" required dense></v-select>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="6">
                <v-select v-model="changeNoteForm.category" :items="getCategoriesByStrategy(changeNoteForm.strategy)"
                  required dense> </v-select>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="6">
                <v-btn small tile block color="blue white--text" :loading="changeNoteLoading" @click="updateStrategyAndCategory">{{$t("common.ok")}}</v-btn>
              </v-col>
            </v-row>
            <!-- <v-card-actions class="justify-center">
              <v-btn small tile color="blue white--text" :loading="changeNoteLoading" @click="updateStrategyAndCategory">{{$t("common.ok")}}</v-btn>
            </v-card-actions> -->
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- *******************一键平仓的弹窗******************* -->
    <v-row>
      <v-dialog v-model="killAllOpenPositionsDialog" persistent max-width="400">
        <v-card>
          <v-card-title class="grey lighten-2">
            <span class="black--text">{{ $t('futuresTrading.killAllOpenPositionsTitle') }}</span>
            <v-spacer></v-spacer>
            <v-btn light icon @click="killAllOpenPositionsDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-container>
            <v-row justify="center">
              <v-col cols="12">
                <span v-html="$t('futuresTrading.killAllOpenPositionsMessage')"></span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-card-actions class="justify-center">
              <v-spacer></v-spacer>
              <v-btn small tile color="blue white--text" :loading="killAllOpenPositionsLoading" @click="killAllOpenPositions">{{$t("common.ok")}}</v-btn>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- *******************筛选一键平仓的弹窗******************* -->
    <v-row>
      <v-dialog v-model="killOpenPositionsDialog" persistent max-width="400">
        <v-card>
          <v-card-title class="grey lighten-2">
            <span class="black--text">{{ $t('futuresTrading.killOpenPositionsTitle') }}</span>
            <v-spacer></v-spacer>
            <v-btn light icon @click="killOpenPositionsDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-container>
            <v-row justify="center">
              <v-col cols="12" class="text-center" v-if="orderSearch.exchange == null && orderSearch.symbol == null && orderSearch.side == null && orderSearch.strategy == null && orderSearch.category == null">
                {{ $t('common.noFilter') }}
              </v-col>
              <v-col cols="12" v-else>
                <span>{{ $t('futuresTrading.killOpenPositionsMessage1') }}</span>
                <span><b>{{ orderSearch.exchange != null ? getExchangeName(orderSearch.exchange) + ' ' : ''}}</b></span>
                <span><b>{{ orderSearch.symbol != null ? orderSearch.symbol  + ' ' : ''}}</b></span>
                <span><b>{{ orderSearch.side != null ? orderSearch.side  + ' ' : '' }}</b></span>
                <span><b>{{ orderSearch.strategy != null ? orderSearch.strategy  + ' ' : '' }}</b></span>
                <span><b>{{ orderSearch.category != null ? orderSearch.category  + ' ' : '' }}</b></span>
                <span>{{ $t('futuresTrading.killOpenPositionsMessage2') }}</span>
              </v-col>
            </v-row>
            <div v-if="orderSearch.exchange != null || orderSearch.symbol != null || orderSearch.side != null || orderSearch.strategy != null || orderSearch.category != null">
              <v-divider></v-divider>
              <v-card-actions class="justify-center">
                <v-spacer></v-spacer>
                <v-btn small tile color="blue white--text" :loading="killOpenPositionsLoading" @click="killOpenPositions">{{$t("common.ok")}}</v-btn>
              </v-card-actions>
            </div>
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- *******************平仓并开反向单的弹窗******************* -->
    <v-row>
      <v-dialog v-model="sarDialog" persistent max-width="490" no-click-animation>
        <v-card>
          <v-card-title class="grey lighten-2">
            <span class="black--text font-md">{{$t("common.stopAndReverse")}}</span>
            <v-spacer></v-spacer>
            <v-btn light icon @click="sarDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-container class="pb-0">
            <v-row justify="center">
              <v-col cols="12">
                <span>{{ $t('futuresTrading.sarMessage') }}</span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-container>
          <v-card-actions class="pr-5">
            <v-spacer></v-spacer>
            <v-btn small tile class="blue white--text" :loading="sarLoading" @click="submitSAR">{{$t("common.ok")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- *******************一键全平仓并开反向单的弹窗******************* -->
    <swap-coin-kill-and-reverse-all-dialog :karaDialog="karaDialog" @closeKARADialog="receiveKARADialog"></swap-coin-kill-and-reverse-all-dialog>
    <!-- *******************筛选平仓并开反向单的弹窗******************* -->
    <swap-coin-kill-and-reverse-dialog :karDialog="karDialog" :orderSearch="orderSearch" @closeKARDialog="receiveKARDialog"></swap-coin-kill-and-reverse-dialog>
    <!-- *******************删除全部放置的进入订单的弹窗******************* -->
    <swap-coin-delete-all-pending-entry-dialog :deleteAllPendingEntryDialog="deleteAllPendingEntryDialog" @closeDeleteAllPendingEntryDialog="receiveDeleteAllPendingEntryDialog"></swap-coin-delete-all-pending-entry-dialog>
    <!-- *******************删除筛选放置的进入订单的弹窗******************* -->
    <swap-coin-delete-pending-entry-dialog :deletePendingEntryDialog="deletePendingEntryDialog" :orderSearch="orderSearch" @closeDeletePendingEntryDialog="receiveDeletePendingEntryDialog"></swap-coin-delete-pending-entry-dialog>
    <!-- *******************删除持仓订单的弹窗******************* -->
    <swap-coin-delete-position-order-dialog :deletePositionOrderDialog="deletePositionOrderDialog" :deletePositionFuturesOrderId="deletePositionFuturesOrderId" @closeDeletePositionOrderDialog="receiveDeletePositionOrderDialog"></swap-coin-delete-position-order-dialog>
    <!-- mobile 手机 -->
    <v-container v-if="responsive == 'mobile' && bottomNav =='trading'" class="flex-b grid-list-xs pt-0 pl-0 pr-0 mb-5 pb-5">
      <v-row>
        <v-col cols="12" sm="12" md="12" class="pl-0 pt-8 pb-0 mb-0" v-if="nextOrder!='next3'">
          <v-row>
            <v-col cols="12" sm="12" md="12" class="mx-3">
              <v-card flat>
                <v-row class="mx-2">
                  <v-col cols="12" class="pb-0">
                    <v-row dense>
                      <v-col cols="1" class="mt-2">
                        <v-card flat v-if="nextOrder=='next1'">
                          <v-icon color="grey" small @click="gotoMoreSignals">mdi-arrow-left</v-icon>
                        </v-card>
                        <v-card flat v-if="nextOrder=='next2'">
                          <v-icon color="grey" small @click="nextOrder='next1'">mdi-arrow-left</v-icon>
                        </v-card>
                      </v-col>
                      <v-col cols="5" v-if="nextOrder=='next1'">
                        <trading-type-change></trading-type-change>
                      </v-col>
                      <v-col cols="5" v-if="nextOrder=='next1'" class="text-right">
                        <div v-if="isSetApiKey && accounts != null" class="fs-12">
                          <div v-for="(symbolObject, i) in exchangeSupportedFuturesSymbols[exchangeCode]" :key="i">
                            <div v-if="symbolObject.symbol == symbol">
                              {{$t("common.equity")}}: {{ formatNum(accounts[symbolObject.baseCurrency].equity.toFixed(4)) + ' ' + symbolObject.baseCurrency }}<br>
                              {{$t("futuresTrading.avaliable")}}: {{ formatNum(accounts[symbolObject.baseCurrency].avaliable.toFixed(4)) + ' ' + symbolObject.baseCurrency }}<br>
                              {{$t("futuresTrading.balance")}}: {{ formatNum(accounts[symbolObject.baseCurrency].balance.toFixed(4)) + ' ' + symbolObject.baseCurrency }}
                            </div>
                          </div>
                        </div>
                        <div v-if="!isSetApiKey" class="text-lg-right mx-5 fs-12">
                          {{$t("common.equity")}}: --<br>
                          {{$t("futuresTrading.avaliable")}}: --<br>
                          {{$t("futuresTrading.balance")}}: --
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="mx-3 mt-5">
                  <v-col cols="6">
                    <app-icon :symbol="symbol"></app-icon>
                    <span class="pa-1 fs-20">{{ symbol }}</span>
                  </v-col>
                  <v-col cols="5" class="text-right">
                    <span class="fs-20" v-if="marketPrice != null && marketPrice[symbol] != null && marketPrice[symbol].current != null"
                      :class="marketPrice[symbol].current.color">{{ formatNum(marketPrice[symbol].current.price)}}</span>
                    <span v-else>--</span>
                  </v-col>
                </v-row>
                <v-row class="mx-3 mt-5">
                  <v-col cols="12" class="text-center">
                    <span>
                      <sub class="fs-12">{{$t("futuresTrading.bid")}} </sub>
                      <span class="fs-24 green--text">
                        <span v-if="marketPrice != null && marketPrice[symbol] != null && marketPrice[symbol].bid != null"
                          :class="marketPrice[symbol].bid.color">{{ formatNum(marketPrice[symbol].bid.price)}}</span>
                        <span v-else>--</span>
                      </span>
                      <span class="mx-2 fs-24 fw-bold"> |</span>
                      <sub class="fs-12">{{$t("futuresTrading.ask")}} </sub>
                      <span class="fs-24 red--text">
                        <span v-if="marketPrice != null && marketPrice[symbol] != null && marketPrice[symbol].ask != null"
                          :class="marketPrice[symbol].ask.color">{{ formatNum(marketPrice[symbol].ask.price)}}</span>
                        <span v-else>--</span>
                      </span>
                    </span>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="mx-3 text-right pr-10">
          <!-- 交易对菜单 -->
          <v-menu offset-y :nudge-width="100" :max-height="350" v-if="nextOrder=='next1' && exchangeSupportedFuturesSymbols != null">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" fab dark x-small color="lightred black--text">
                <v-icon dense>mdi-menu</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(symbolObject, i) in exchangeSupportedFuturesSymbols[exchangeCode]" :key="i" @click="gotoFuturesOrder(symbolObject.symbol)">
                <router-link class="del-underline" :to="'/trading/futures/' + exchangeCode + '/' + symbolObject.symbol">
                  <v-card flat class="del-underline">
                    {{ symbolObject.symbol }}
                  </v-card>
                </router-link>
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- *******************手机发送订单的表单******************* -->
          <v-form ref="mobileOrderForm">
            <v-row v-if="nextOrder=='next1'" justify="center" align="center" no-gutters>
              <!-- open/close -->
              <v-col cols="12" class="text-center mb-5">
                <div class="grey--text text--lighten-1 pointer">
                  <span class="pointer fs-20 mr-4" :class="[side == 'open' ? 'fs-36 grey--text darken-3' : '']" @click="changeSide('open')">{{$t("futuresTrading.open")}}
                  </span>
                  <span class="pointer fs-20 " :class="[side == 'close' ? 'fs-36 grey--text darken-3' : '']" @click="changeSide('close')">{{$t("futuresTrading.close")}}</span>
                </div>
              </v-col>
              <!-- buy/sell -->
              <v-col cols="12" class="text-center mb-10">
                <div class="grey--text text--lighten-1 pointer">
                  <span class="pointer fs-16 mr-4" :class="[orderSide == 'buy' ? 'fs-36 teal--text darken-4' : '']"
                    @click="changeOrderSide('buy')">{{$t("futuresTrading.buy")}}</span>
                  <span class="pointer fs-16" :class="[orderSide == 'sell' ? 'fs-36 red--text' : '']" @click="changeOrderSide('sell')">{{$t("futuresTrading.sell")}}</span>
                </div>
              </v-col>
              <!-- exchange -->
              <v-col cols="6" sm="6" md="6" class="mx-5 mb-0 pb-0">
                <v-select dense v-model="orderForm.exchangeId" :items="exchanges" :label="$t('futuresTrading.exchange')"
                  item-text="name" item-value="id" :rules="[v => !!v || $t('futuresTrading.requiredMessage.exchangeMsg')]"
                  required @change="changeExchange"></v-select>
              </v-col>
              <!-- strategy -->
              <v-col cols="6" sm="6" md="6" class="mx-5 py-0 my-0">
                <v-select dense v-model="orderForm.strategy" :items="strategies" :label="$t('common.strategy')"
                  required></v-select>
              </v-col>
              <!--  category-->
              <v-col cols="6" sm="6" md="6" class="mx-5 py-0 my-0">
                <v-select dense v-model="orderForm.category" :items="getCategoriesByStrategy(orderForm.strategy)"
                  :label="$t('common.category')" required></v-select>
              </v-col>
              <!-- btn -->
              <v-col cols="6" sm="6" md="6" class="mb-12 text-center">
                <v-btn tile block v-if="'open' == side" color="indigo darken-4" depressed class="white--text" @click="nextOrder='next2';takeProfitEnabled = true">
                  {{$t("futuresTrading.continue")}}
                </v-btn>
                <v-btn tile block v-if="'close' == side" color="indigo darken-4" depressed class="white--text" @click="nextOrder='next2';takeProfitEnabled = false">
                  {{$t("futuresTrading.continue")}}
                </v-btn>
              </v-col>
            </v-row>
            <!-- Next 2 -->
            <v-row v-if="nextOrder=='next2'" justify="center" align="center" no-gutters>
              <v-col cols="6" sm="6" md="6" class="mx-5">
                <v-row dense>
                  <!-- Size -->
                  <v-col cols="10" class="mr-1">
                    <v-text-field dense v-model="orderForm.size" type="number" min="0" :label="$t('futuresTrading.size')"
                      :suffix="sizeDollarValue" required></v-text-field>
                  </v-col>
                  <v-col cols="1">
                    <v-menu :close-on-content-click="false" :nudge-width="120" offset-x nudge-left="180" nudge-top="30"
                      z-index="99">
                      <template v-slot:activator="{ on }">
                        <v-icon color="blue" small class="pointer" v-on="on">mdi-information</v-icon>
                      </template>
                      <v-card>
                        <div class="api mx-2 pa-3">
                          <div class="fs-16">{{$t('futuresTrading.contractFaceValue')}}</div>
                          <v-divider></v-divider>
                          <div>Kraken: </div>
                          <div>{{$t('futuresTrading.all')}}: 1 = $1</div>
                          <v-divider></v-divider>
                          <div>OKEx: </div>
                          <div>BTC: 1 = $100</div>
                          <div>{{$t('futuresTrading.other')}}: 1 = $10</div>
                          <v-divider></v-divider>
                          <div>Binance: </div>
                          <div>BTC: 1 = $100</div>
                          <div>{{$t('futuresTrading.other')}}: 1 = $10</div>
                        </div>
                      </v-card>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
              <!-- renko范围 -->
              <v-col cols="6" sm="6" md="6" class="mx-5 py-0 my-0" v-if="orderForm.strategy != 'PP' && orderForm.strategy != 'CPP'">
                <v-select dense v-model="orderForm.renkoEntryMultiplier" :items="renkoMultipliers" item-text="text"
                  item-value="value" :disabled="orderForm.priceType == 'bbo'" :label="$t('common.renkoEntryMultiplier')" required></v-select>
              </v-col>
              <!-- price -->
              <v-col cols="6" sm="6" md="6" class="mx-5 py-0 my-0">
                <v-text-field dense v-model="orderForm.price" type="number" min="0" :label="$t('futuresTrading.price')" :step="getTickSize(orderForm.symbol)" :disabled="orderForm.priceType == 'bbo'" required></v-text-field>
              </v-col>
              <!-- Margin -->
              <v-col cols="6" sm="6" md="6" class="mx-5 py-0 my-0 text-left" v-if="orderForm.price > 0 && orderForm.size > 0 && requiredMargin > 0">
                <span>{{ $t('common.margin') + ': ' + requiredMargin + ' ' + getBaseCurrency(orderForm.symbol) }}</span>
              </v-col>
              <!-- BBO -->
              <v-col cols="6" sm="6" md="6" class="mx-5 py-0 my-0">
                <v-btn small tile dark block color="blue" :outlined="orderForm.priceType != 'bbo'" @click="changePriceType('new')">BBO</v-btn>
              </v-col>
              <v-col cols="6" sm="6" md="6" class="mx-5 py-0 my-0" v-if="'open' == side">
              </v-col>
              <!-- 下单 -->
              <v-col cols="6" sm="6" md="6" class="mb-12 text-center mt-10">
                <span v-if="accounts != null && accounts[getBaseCurrency(orderForm.symbol)].avaliable - requiredMargin >= 0">
                  <v-btn small tile block :disabled="isDisabledOrderButton" :loading="submitBtnLoading" color="white--text"
                    :class="['long' == buttonSide || 'buy' ==  buttonSide? 'green' : 'red']" @click="mobileJudgeParameters(true)">
                    {{ getOrderButtonSide() }}
                  </v-btn>
                </span>
                <span v-else>
                  <v-btn tile block disabled color="white--text" class="orderForm">
                    {{ $t('common.insufficientMargin') }}
                  </v-btn>
                </span>
              </v-col>
            </v-row>
            <!-- Next 3 -->
            <v-row v-if="nextOrder=='next3'" class="mt-5">
              <v-col cols="2" class="mt-8">
                <v-icon color="grey" @click="nextOrder='next2'">mdi-arrow-left</v-icon>
              </v-col>
              <v-col cols="12" sm="12" md="12" v-if="'open' == side">
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-row justify="center" align="center">
                      <!-- 止盈价 -->
                      <v-col cols="6" sm="6" md="6">
                        <v-text-field dense :step="getTickSize(orderForm.symbol)" v-model="orderForm.takeProfitPrice" type="number" min="0" :disabled="orderForm.priceType == 'bbo'" :label="$t('futuresTrading.takeProfit')"></v-text-field>
                      </v-col>
                      <!-- 止损价百分比计划设置 -->
                      <v-col cols="12" sm="6" md="6" v-if="orderForm.category != 'MR'" class="text-center">
                        <v-btn-toggle v-model="planVal" tile>
                          <v-btn text value="0" x-small>{{$t("futuresTrading.reset")}}</v-btn>
                          <v-btn text value="1" x-small>{{$t("futuresTrading.planA")}}</v-btn>
                          <v-btn text value="2" x-small>{{$t("futuresTrading.planB")}}</v-btn>
                          <v-btn text value="3" x-small>{{$t("futuresTrading.planC")}}</v-btn>
                          <v-btn text value="4" x-small>{{$t("futuresTrading.planD")}}</v-btn>
                          <v-btn text value="5" x-small>{{$t("futuresTrading.planE")}}</v-btn>
                        </v-btn-toggle>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <div v-if="'close' == side" class="px-3">
                      <span class="fs-22">{{$t("futuresTrading.cannotSetStopPrice")}}</span>
                    </div>
                    <div v-if="'close' != side">
                      <!-- Trailing Stop1 -->
                      <v-row class="red--text" justify="center" align="center">
                        <v-col cols="6" sm="6" md="6" class="mx-5 py-0 my-0">
                          <v-text-field dense :label="$t('futuresTrading.safetyNet')" v-model="trailingStopLevel1" type="number"
                            min="0" max="100" suffix="%"></v-text-field>
                        </v-col>
                      </v-row>
                      <!-- Price2 -->
                      <v-row justify="center" align="center" class="green--text" v-if="orderForm.category != 'MR'">
                        <v-col cols="6" sm="6" md="6" class="mx-5 py-0 my-0">
                          <v-text-field dense :label="$t('futuresTrading.priceLevel2')" v-model="priceLevel2" type="number"
                            min="0" max="100" suffix="%"></v-text-field>
                        </v-col>
                      </v-row>
                      <!-- Trailing Stop2 -->
                      <v-row justify="center" align="center" class="red--text" v-if="orderForm.category != 'MR'">
                        <v-col cols="6" sm="6" md="6" class="mx-5 py-0 my-0">
                          <v-text-field dense :label="$t('futuresTrading.trailingStopLevel2')" v-model="trailingStopLevel2"
                            type="number" min="0" max="100" suffix="%"></v-text-field>
                        </v-col>
                      </v-row>
                      <!-- Price3 -->
                      <v-row justify="center" align="center" class="green--text" v-if="orderForm.category != 'MR'">
                        <v-col cols="6" sm="6" md="6" class="mx-5 py-0 my-0">
                          <v-text-field dense :label="$t('futuresTrading.priceLevel3')" v-model="priceLevel3" type="number"
                            min="0" max="100" suffix="%"></v-text-field>
                        </v-col>
                      </v-row>
                      <!-- Trailing Stop3 -->
                      <v-row justify="center" align="center" class="red--text" v-if="orderForm.category != 'MR'">
                        <v-col cols="6" sm="6" md="6" class="mx-5 py-0 my-0">
                          <v-text-field dense :label="$t('futuresTrading.trailingStopLevel3')" v-model="trailingStopLevel3 "
                            type="number" min="0" max="100" suffix="%"></v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                    <v-row justify="center" align="center">
                      <v-col cols="6" sm="6" md="6" class="py-0 my-0 text-center">
                        <v-btn block tile class="indigo darken-4 white--text" @click="setTrailingStop">
                          {{$t("futuresTrading.continue")}}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
      <!-- <v-row class="symbol-fixed" v-if="nextOrder=='next1'" justify="center" align-content="center">
        <v-col cols="12" sm="12" md="12" xs="12" class="pb-0 px-0">
          <v-card class="d-flex" :class="[darkMode?'grey darken-2':'']">
            <v-col class="d-flex py-3 px-5 fs-20 justify-center" v-if="exchangeSupportedFuturesSymbols != null">
              <div class="px-2" :class="[symbolObject.supported ? '' : 'cursor-none']" v-for="(symbolObject, i) in exchangeSupportedFuturesSymbols[exchangeCode]"
                :key="i">
                <div @click="gotoFuturesOrder(symbolObject.symbol)" :class="[symbolObject.symbol == symbol ? 'icon-red' : 'icon-grey']">
                  <app-icon :symbol="symbolObject.symbol"></app-icon>
                </div>
              </div>
            </v-col>
          </v-card>
        </v-col>
      </v-row> -->
    </v-container>
    <v-container v-if="responsive == 'mobile' && bottomNav =='orders'" class="flex-b pl-0 mt-6 pr-0 pb-0 mb-0">
      <v-row class="mt-5 mx-3" no-gutters>
        <v-col cols="1">
          <v-menu offset-y :nudge-width="240" :max-height="300">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" fab dark x-small color="lightred black--text">
                <v-icon dense>mdi-menu</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <!-- 1 -->
              <v-list-item @click="orders='pendingEntryOrders'">
                <v-badge color="lightred" :value="pendingEntryOrdersTotalElements" class="mt-2">
                  <template v-slot:badge v-if=" pendingEntryOrdersTotalElements > 0">
                    <span class="fs-12 black--text">{{pendingEntryOrdersTotalElements}}</span>
                  </template>
                  <v-list-item-title class="fs-14">
                    {{$t("futuresTrading.pendingEntryOrders")}}
                  </v-list-item-title>
                </v-badge>
              </v-list-item>
              <!-- 2 -->
              <v-list-item @click="orders='pendingExitOrders'">
                <v-badge color="lightred" :value="pendingExitOrdersTotalElements">
                  <template v-slot:badge v-if=" pendingExitOrdersTotalElements > 0">
                    <span class="fs-12 black--text">{{pendingExitOrdersTotalElements}}</span>
                  </template>
                  <v-list-item-title class="fs-14">
                    {{$t("futuresTrading.pendingExitOrders")}}
                  </v-list-item-title>
                </v-badge>
              </v-list-item>
              <!-- 3 -->
              <v-list-item @click="orders='submittedExchangeOrders'">
                <v-list-item-title class="fs-14">
                  {{$t("futuresTrading.submittedExchangeOrders")}}
                </v-list-item-title>
              </v-list-item>
              <!-- 4 -->
              <v-list-item @click="orders='unfilledExchangeOrders'">
                <v-badge color="lightred" :value="unfilledExchangeOrdersTotalElements">
                  <template v-slot:badge v-if=" unfilledExchangeOrdersTotalElements > 0">
                    <span class="fs-12 black--text">{{unfilledExchangeOrdersTotalElements}}</span>
                  </template>
                  <v-list-item-title class="fs-14">
                    {{$t("futuresTrading.unfilledExchangeOrders")}}
                  </v-list-item-title>
                </v-badge>
              </v-list-item>
              <!-- 5 -->
              <v-list-item @click="orders='filledExchangeOrders'">
                <v-list-item-title class="fs-14">
                  {{$t("futuresTrading.filledExchangeOrders")}}
                </v-list-item-title>
              </v-list-item>
              <!-- 6 -->
              <v-list-item @click="orders='openPositions'">
                <v-badge color="lightred" :value="openPositionsOrdersTotalElements">
                  <template v-slot:badge v-if="openPositionsOrdersTotalElements > 0">
                    <span class="fs-12 black--text">{{openPositionsOrdersTotalElements}}</span>
                  </template>
                  <v-list-item-title class="fs-14">
                    {{$t("futuresTrading.openPositions")}}
                  </v-list-item-title>
                </v-badge>
              </v-list-item>
              <!-- 7 -->
              <v-list-item @click="orders='openPositionsAtExchanges'">
                <v-badge color="lightred" :value="openPositionsAtExchanges.length">
                  <template v-slot:badge v-if="openPositionsAtExchanges.length > 0">
                    <span class="fs-12 black--text">{{openPositionsAtExchanges.length}}</span>
                  </template>
                  <v-list-item-title class="fs-14">
                    {{$t("futuresTrading.openPositionsAtExchanges")}}
                  </v-list-item-title>
                </v-badge>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <v-col cols="8" class="align-self-sm-center my-2 ml-3">
          {{formatStr(orders)}}
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="1">
          <v-btn color="lightred black--text" fab dark x-small title="Refresh" @click="refreshAllFuturesOrders">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <!--******************************** 手机第一页 ********************************-->
      <v-row v-if="orders=='pendingEntryOrders'">
        <v-col cols="12" no-gutters>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="5" class="mr-2">
              <v-select dense class="fs-12" v-model="orderSearch.exchange" :label="$t('common.exchange')" :items="exchanges"
                item-text="name" item-value="code" clearable></v-select>
            </v-col>
            <v-col cols="5" class="mr-5">
              <v-select dense class="fs-12" v-model="orderSearch.symbol" :label="$t('common.symbol')" :items="futuresSymbols"
                clearable></v-select>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="5" class="mr-2">
              <v-select dense class="fs-12" v-model="orderSearch.side" :label="$t('common.side')" :items="orderSides"
                clearable></v-select>
            </v-col>
            <v-col cols="5" class="mr-5">
              <v-select dense class="fs-12" v-model="orderSearch.strategy" :label="$t('common.strategy')" :items="strategies"
                clearable></v-select>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="5" class="mr-2">
              <v-select dense class="fs-12" v-model="orderSearch.category" :label="$t('common.category')" :items="orderCategories"
                clearable></v-select>
            </v-col>
            <v-col cols="5" class="mr-5">
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="10" class="mr-3 text-right my-2">
              <v-btn x-small tile :title="$t('common.deleteAll')" class="red accent-4" dark @click="deleteAllPendingEntryDialog = true">{{ $t('common.deleteAll') }}</v-btn>
              <v-btn x-small tile :title="$t('common.delete')" class="blue darken-4 ml-5 mr-2" dark @click="deletePendingEntryDialog = true">{{ $t('common.delete') }}</v-btn>
            </v-col>
          </v-row>
          <v-row no-gutters justify="center" align="center">
            <v-card flat v-for="(pendingEntryOrder, i) in pendingEntryOrders" :key="i" class="mt-2 my-3 mx-3">
              <v-row class="ma-3" no-gutters>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.exchange")}}
                  </span>
                  <span class="float-right">
                    <span :class="CommonJS.formatExchangeColor(pendingEntryOrder.exchangeName)">{{ pendingEntryOrder.exchangeName }}</span>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.symbol")}}
                  </span>
                  <span class="float-right">
                    {{ pendingEntryOrder.symbol }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.side")}}
                  </span>
                  <span class="float-right" :class="pendingEntryOrder.side == 'open_long' || pendingEntryOrder.side == 'close_buy' ? 'green--text' : 'red--text'">
                    <span v-side="pendingEntryOrder.side"></span>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.size")}}
                  </span>
                  <span class="float-right">
                    {{ (pendingEntryOrder.side == 'open_short'|| pendingEntryOrder.side == 'close_sell') ? '-' : '' }}{{ formatNum(pendingEntryOrder.size) }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.price")}}
                  </span>
                  <span class="float-right">
                    {{ formatNum(pendingEntryOrder.price.toFixed(futuresDecimalNumbers[pendingEntryOrder.symbol])) }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.takeProfit")}}
                  </span>
                  <span class="float-right">
                    <span v-if="pendingEntryOrder.takeProfitPrice == 0">--</span>
                    <span v-else-if="pendingEntryOrder.side == 'open_long'">{{ ((pendingEntryOrder.takeProfitPrice / pendingEntryOrder.price - 1) * 100).toFixed(2) + '%' }}</span>
                    <span v-else-if="pendingEntryOrder.side == 'open_short'">{{ ((pendingEntryOrder.price / pendingEntryOrder.takeProfitPrice - 1) * 100).toFixed(2) + '%' }}</span>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.safetyNet")}}
                  </span>
                  <span class="float-right">
                    {{ (pendingEntryOrder.trailingStopLevel1 * 100).toFixed(2) + '%' }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.stopLoss")}}
                  </span>
                  <span class="float-right">
                    {{ (pendingEntryOrder.trailingStopLevel1 * 100).toFixed(2) + '%' }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters :class="currentTimestamp >= pendingEntryOrder.sendOrderStartTimestamp ? 'green--text' : 'grey--text text--lighten-1'">
                  <v-row no-gutters>
                    <v-col cols="4">
                      <span class="grey--text text--lighten-1">
                        {{$t("futuresTrading.effectiveTime")}}
                      </span>
                    </v-col>
                    <v-col cols="8" class="text-right">
                      <span>
                        {{ formatDate(pendingEntryOrder.sendOrderStartTimestamp) }}
                      </span>
                      <span> - </span>
                      <span>
                        {{ -1 == pendingEntryOrder.sendOrderEndTimestamp ? $t('futuresTrading.infinity') : formatDate(pendingEntryOrder.sendOrderEndTimestamp) }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("common.strategy")}}
                  </span>
                  <span class="float-right" :style="{color: getColorByStrategy(pendingEntryOrder.strategy)}">
                    {{ pendingEntryOrder.strategy }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("common.category")}}
                  </span>
                  <span class="float-right" :class="pendingEntryOrder.bgColor">
                    {{ pendingEntryOrder.category }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.action")}}
                  </span>
                  <span class="float-right">
                    <v-icon color="grey" small class="pointer ml-3" @click="copyToClipboard(pendingEntryOrder.id)">mdi-content-copy</v-icon>
                    <v-icon color="orange" small class="pointer ml-3" @click="showChangeOrderDialog(pendingEntryOrder)"
                      title="Edit">mdi-pencil</v-icon>
                    <v-icon color="blue" small class="pointer ml-3" :disabled="pendingEntryOrder.strategy == 'PP' || pendingEntryOrder.strategy == 'CPP'"
                      @click="showChangeTimeDialog(pendingEntryOrder)" title="Time">mdi-clock-outline</v-icon>
                    <v-icon color="blue-grey lighten-1" small class="pointer ml-3" @click="showChangeNoteDialog('pendingEntry', pendingEntryOrder)"
                      :title="$t('common.strategy')">icon icon-s1</v-icon>
                    <v-icon color="red" small class="pointer ml-3" @click="deleteUnsubmittedOrderById(pendingEntryOrder.id)"
                      title="Cancel">mdi-close</v-icon>
                  </span>
                </v-col>
              </v-row>
            </v-card>
            <!-- 加载状态 -->
            <div class="text-center" v-show="pendingEntryLoading">
              <div>
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </div>
            </div>
            <div class="mt-3 mb-5" v-show="pendingEntryOrders.length > 0">
              <v-pagination color="lightred" circle prev-icon="mdi-menu-left" next-icon="mdi-menu-right" v-model="pendingEntryOrdersPageNo"
                :length="pendingEntryOrdersTotalPage" :total-visible="6" @input="queryPendingEntryOrdersByPages"></v-pagination>
            </div>
          </v-row>
          <!-- No data available -->
          <v-card flat class="mt-2 my-3 text-center" v-show="!pendingEntryLoading  && pendingEntryOrders.length <= 0 ">
            <v-row>
              <v-col cols="12" sm="12" md="12" class="ma-2">
                {{ $t('futuresTrading.noDataAvailable') }}
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <!--******************************** 手机第二页 ********************************-->
      <v-row v-if="orders=='pendingExitOrders'">
        <v-col cols="12" no-gutters>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="5" class="mr-2">
              <v-select dense class="fs-12" v-model="orderSearch.exchange" :label="$t('common.exchange')" :items="exchanges"
                item-text="name" item-value="code" clearable></v-select>
            </v-col>
            <v-col cols="5" class="mr-5">
              <v-select dense class="fs-12" v-model="orderSearch.symbol" :label="$t('common.symbol')" :items="futuresSymbols"
                clearable></v-select>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="5" class="mr-2">
              <v-select dense class="fs-12" v-model="orderSearch.side" :label="$t('common.side')" :items="orderSides"
                clearable></v-select>
            </v-col>
            <v-col cols="5" class="mr-5">
              <v-select dense class="fs-12" v-model="orderSearch.strategy" :label="$t('common.strategy')" :items="strategies"
                clearable></v-select>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="5" class="mr-2">
              <v-select dense class="fs-12" v-model="orderSearch.category" :label="$t('common.category')" :items="orderCategories"
                clearable></v-select>
            </v-col>
            <v-col cols="5" class="mr-5">
            </v-col>
          </v-row>
          <v-row no-gutters justify="center" align="center">
            <v-card flat v-for="(pendingExitOrder, i) in pendingExitOrders" :key="i" class="mt-2 my-3 mx-3">
              <v-row class="ma-3" no-gutters>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.exchange")}}
                  </span>
                  <span class="float-right">
                    <span :class="CommonJS.formatExchangeColor(pendingExitOrder.exchangeName)">{{ pendingExitOrder.exchangeName }}</span>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.symbol")}}
                  </span>
                  <span class="float-right">
                    {{ pendingExitOrder.symbol }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.side")}}
                  </span>
                  <span class="float-right" :class="pendingExitOrder.side == 'open_long' || pendingExitOrder.side == 'close_buy' ? 'green--text' : 'red--text'">
                    <span v-side="pendingExitOrder.side"></span>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.size")}}
                  </span>
                  <span class="float-right">
                    {{ (pendingExitOrder.side == 'open_short'|| pendingExitOrder.side == 'close_sell') ? '-' : '' }}{{ formatNum(pendingExitOrder.size) }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.price")}}
                  </span>
                  <span class="float-right">
                    {{ formatNum(pendingExitOrder.price.toFixed(getDecimalPlace(pendingExitOrder.exchange, pendingExitOrder.symbol))) }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters :class="currentTimestamp >= pendingExitOrder.sendOrderStartTimestamp ? 'green--text' : 'grey--text text--lighten-1'">
                  <v-row no-gutters>
                    <v-col cols="4">
                      <span class="grey--text text--lighten-1">
                        {{$t("futuresTrading.effectiveTime")}}
                      </span>
                    </v-col>
                    <v-col cols="8" class="text-right">
                      <span>
                        {{ formatDate(pendingExitOrder.sendOrderStartTimestamp) }}
                      </span>
                      <span> - </span>
                      <span>
                        {{ -1 == pendingExitOrder.sendOrderEndTimestamp ? $t('futuresTrading.infinity') : formatDate(pendingExitOrder.sendOrderEndTimestamp) }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("common.strategy")}}
                  </span>
                  <span class="float-right" :style="{color: getColorByStrategy(pendingExitOrder.strategy)}">
                    {{ pendingExitOrder.strategy }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("common.category")}}
                  </span>
                  <span class="float-right" :class="pendingExitOrder.bgColor">
                    {{ pendingExitOrder.category }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.action")}}
                  </span>
                  <span class="float-right">
                    <v-icon color="grey" small class="pointer" @click="copyToClipboard(pendingExitOrder.id)" :title="pendingExitOrder.id">mdi-content-copy</v-icon>
                    <v-icon color="orange" small class="pointer ml-3" @click="showChangeOrderDialog(pendingExitOrder)"
                      title="Edit">mdi-pencil</v-icon>
                    <v-icon color="blue" small class="pointer ml-3" :disabled="pendingExitOrder.strategy == 'PP' || pendingExitOrder.strategy == 'CPP'"
                      @click="showChangeTimeDialog(pendingExitOrder)" title="Time">mdi-clock-outline</v-icon>
                    <v-icon color="blue-grey lighten-1" small class="pointer ml-3" @click="showChangeNoteDialog('pendingExit', pendingExitOrder)"
                      :title="$t('common.strategy')">icon icon-s1</v-icon>
                    <v-icon color="red" small class="pointer ml-3" @click="deleteUnsubmittedOrderById(pendingExitOrder.id)"
                      title="Cancel">mdi-close</v-icon>
                  </span>
                </v-col>
              </v-row>
            </v-card>
            <!-- 加载状态 -->
            <div class="text-center" v-show="pendingExitLoading">
              <div>
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </div>
            </div>
            <div class="mt-3 mb-5" v-show="pendingExitOrders.length > 0">
              <v-pagination color="lightred" circle prev-icon="mdi-menu-left" next-icon="mdi-menu-right" v-model="pendingExitOrdersPageNo"
                :length="pendingExitOrdersTotalPage" :total-visible="6" @input="queryPendingExitOrdersByPages"></v-pagination>
            </div>
          </v-row>
          <!-- No data available -->
          <v-card flat class="mt-2 my-3 text-center" v-show="!pendingExitLoading  && pendingExitOrders.length <= 0 ">
            <v-row>
              <v-col cols="12" sm="12" md="12" class="ma-2">
                {{ $t('futuresTrading.noDataAvailable') }}
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <!--******************************** 手机第三页 ********************************-->
      <v-row v-if="orders=='submittedExchangeOrders'">
        <v-col cols="12" no-gutters>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="5" class="mr-2">
              <v-select dense class="fs-12" v-model="orderSearch.exchange" :label="$t('common.exchange')" :items="exchanges"
                item-text="name" item-value="code" clearable></v-select>
            </v-col>
            <v-col cols="5" class="mr-5">
              <v-select dense class="fs-12" v-model="orderSearch.symbol" :label="$t('common.symbol')" :items="futuresSymbols"
                clearable></v-select>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="5" class="mr-2">
              <v-select dense class="fs-12" v-model="orderSearch.side" :label="$t('common.side')" :items="orderSides"
                clearable></v-select>
            </v-col>
            <v-col cols="5" class="mr-5">
              <v-select dense class="fs-12" v-model="orderSearch.strategy" :label="$t('common.strategy')" :items="strategies"
                clearable></v-select>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="5" class="mr-2">
              <v-select dense class="fs-12" v-model="orderSearch.category" :label="$t('common.category')" :items="orderCategories"
                clearable></v-select>
            </v-col>
            <v-col cols="5" class="mr-5">
            </v-col>
          </v-row>
          <v-row no-gutters justify="center" align="center">
            <v-card flat v-for="(submittedOrder, i) in submittedOrders" :key="i" class="mt-2 my-3 mx-3">
              <v-row class="ma-3" no-gutters>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.exchange")}}
                  </span>
                  <span class="float-right">
                    <span :class="CommonJS.formatExchangeColor(submittedOrder.exchangeName)">{{ submittedOrder.exchangeName }}</span>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.symbol")}}
                  </span>
                  <span class="float-right">
                    {{ submittedOrder.symbol }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.side")}}
                  </span>
                  <span class="float-right" :class="submittedOrder.side == 'open_long' || submittedOrder.side == 'close_buy' ? 'green--text' : 'red--text'">
                    <span v-side="submittedOrder.side"></span>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.size")}}
                  </span>
                  <span class="float-right">
                    {{ (submittedOrder.side == 'open_short'|| submittedOrder.side == 'close_sell') ? '-' : '' }}{{ formatNum(submittedOrder.size) }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.price")}}
                  </span>
                  <span class="float-right">
                    {{ formatNum(submittedOrder.price.toFixed(futuresDecimalNumbers[submittedOrder.symbol])) }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.time")}}
                  </span>
                  <span class="float-right">
                    {{ formatDate(submittedOrder.timestamp) }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.status")}}
                  </span>
                  <span class="float-right" v-status="submittedOrder.status"></span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("common.strategy")}}
                  </span>
                  <span class="float-right" :style="{color: getColorByStrategy(submittedOrder.strategy)}">
                    {{ submittedOrder.strategy }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("common.category")}}
                  </span>
                  <span class="float-right" :class="submittedOrder.bgColor">
                    {{ submittedOrder.category }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.action")}}
                  </span>
                  <span class="float-right">
                    <v-icon color="grey" small class="pointer" @click="copyToClipboard(submittedOrder.futuresOrderId)"
                      :title="submittedOrder.futuresOrderId">mdi-content-copy</v-icon>
                  </span>
                </v-col>
              </v-row>
            </v-card>
            <!-- 加载状态 -->
            <div class="text-center" v-show="submittedExchangeOrdersLoading">
              <div>
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </div>
            </div>
            <div class="mt-3 mb-5" v-show="submittedOrders.length > 0">
              <v-pagination color="lightred" circle prev-icon="mdi-menu-left" next-icon="mdi-menu-right" v-model="submittedOrdersPageNo"
                :length="submittedOrdersTotalPage" :total-visible="6" @input="querySubmittedOrdersByPages"></v-pagination>
            </div>
          </v-row>
          <!-- No data available -->
          <v-card flat class="mt-2 my-3 text-center" v-show="!submittedExchangeOrdersLoading  && submittedOrders.length <= 0 ">
            <v-row>
              <v-col cols="12" sm="12" md="12" class="ma-2">
                {{ $t('futuresTrading.noDataAvailable') }}
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <!--******************************** 手机第四页 ********************************-->
      <v-row v-if="orders=='unfilledExchangeOrders'">
        <v-col cols="12" no-gutters>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="5" class="mr-2">
              <v-select dense class="fs-12" v-model="orderSearch.exchange" :label="$t('common.exchange')" :items="exchanges"
                item-text="name" item-value="code" clearable></v-select>
            </v-col>
            <v-col cols="5" class="mr-5">
              <v-select dense class="fs-12" v-model="orderSearch.symbol" :label="$t('common.symbol')" :items="futuresSymbols"
                clearable></v-select>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="5" class="mr-2">
              <v-select dense class="fs-12" v-model="orderSearch.side" :label="$t('common.side')" :items="orderSides"
                clearable></v-select>
            </v-col>
            <v-col cols="5" class="mr-5">
              <v-select dense class="fs-12" v-model="orderSearch.strategy" :label="$t('common.strategy')" :items="strategies"
                clearable></v-select>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="5" class="mr-2">
              <v-select dense class="fs-12" v-model="orderSearch.category" :label="$t('common.category')" :items="orderCategories"
                clearable></v-select>
            </v-col>
            <v-col cols="5" class="mr-5">
            </v-col>
          </v-row>
          <v-row no-gutters justify="center" align="center">
            <v-card flat v-for="(unfilledExchangeOrder, i) in unfilledExchangeOrders" :key="i" class="mt-2 my-3 mx-3">
              <v-row class="ma-3" no-gutters>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.exchange")}}
                  </span>
                  <span class="float-right">
                    <span :class="CommonJS.formatExchangeColor(unfilledExchangeOrder.exchangeName)">{{ unfilledExchangeOrder.exchangeName }}</span>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.symbol")}}
                  </span>
                  <span class="float-right">
                    {{ unfilledExchangeOrder.symbol }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <!-- side -->
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.side")}}
                  </span>
                  <span class="float-right" :class="unfilledExchangeOrder.side == 'open_long' || unfilledExchangeOrder.side == 'close_buy' ? 'green--text' : 'red--text'">
                    <span v-side="unfilledExchangeOrder.side"></span>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <!-- size -->
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.size")}}
                  </span>
                  <span class="float-right">
                    {{ (unfilledExchangeOrder.side == 'open_short'|| unfilledExchangeOrder.side == 'close_sell') ? '-' : '' }}{{ formatNum(unfilledExchangeOrder.size) }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <!-- price -->
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.price")}}
                  </span>
                  <span class="float-right">
                    {{ formatNum(unfilledExchangeOrder.price.toFixed(futuresDecimalNumbers[unfilledExchangeOrder.symbol])) }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <!--  takeProfit -->
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.takeProfit")}}
                  </span>
                  <span class="float-right">
                    {{ formatNum(unfilledExchangeOrder.takeProfitPrice > 0 ? unfilledExchangeOrder.takeProfitPrice.toFixed(futuresDecimalNumbers[unfilledExchangeOrder.symbol]) : '--') }}
                    <span> / </span>
                    <span :class="[(unfilledExchangeOrder.side == 'open_long' && unfilledExchangeOrder.takeProfitPrice > 0 && unfilledExchangeOrder.takeProfitPrice < unfilledExchangeOrder.price) ? 'red--text' : '', (unfilledExchangeOrder.side == 'open_short' && unfilledExchangeOrder.takeProfitPrice > unfilledExchangeOrder.price) ? 'red--text' : '']">{{ calcTakeProfitEstimatedPL(unfilledExchangeOrder) }}</span>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <!-- safetyNet -->
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.safetyNet")}}
                  </span>
                  <span class="float-right">
                    <span>{{ formatNum(unfilledExchangeOrder.stopLossPrice1 > 0 ? unfilledExchangeOrder.stopLossPrice1.toFixed(futuresDecimalNumbers[unfilledExchangeOrder.symbol]) : '--') }}</span>
                    <span> / </span>
                    <span :class="[(unfilledExchangeOrder.side == 'open_long' && unfilledExchangeOrder.stopLossPrice1 < unfilledExchangeOrder.price) ? 'red--text' : '', (unfilledExchangeOrder.side == 'open_short' && unfilledExchangeOrder.stopLossPrice1 > unfilledExchangeOrder.price) ? 'red--text' : '']">{{ calcSafetyNetEstimatedPL(unfilledExchangeOrder) }}</span>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.stopLoss")}}
                    <span v-show="!unfilledExchangeOrder.beFixedStopLossPrice">({{$t("common.trailing")}})</span>
                    <span v-show="unfilledExchangeOrder.beFixedStopLossPrice">({{$t("common.fixed")}})</span>
                  </span>
                  <span class="float-right">
                    <span v-show="!unfilledExchangeOrder.beFixedStopLossPrice">
                      <span>{{ formatNum(unfilledExchangeOrder.stopLossPrice > 0 ? unfilledExchangeOrder.stopLossPrice.toFixed(futuresDecimalNumbers[unfilledExchangeOrder.symbol]) : '--') }}</span>
                      <span> / </span>
                      <span :class="[(unfilledExchangeOrder.side == 'open_long' && unfilledExchangeOrder.stopLossPrice < unfilledExchangeOrder.price) ? 'red--text' : '', (unfilledExchangeOrder.side == 'open_short' && unfilledExchangeOrder.stopLossPrice > unfilledExchangeOrder.price) ? 'red--text' : '']">{{ calcEstimatedPL(unfilledExchangeOrder) }}</span>
                    </span>
                    <span v-show="unfilledExchangeOrder.beFixedStopLossPrice">
                      <span>{{ formatNum(unfilledExchangeOrder.fixedStopLossPrice > 0 ? unfilledExchangeOrder.fixedStopLossPrice.toFixed(futuresDecimalNumbers[unfilledExchangeOrder.symbol]) : '--') }}</span>
                      <span> / </span>
                      <span :class="[(unfilledExchangeOrder.side == 'open_long' && unfilledExchangeOrder.fixedStopLossPrice < unfilledExchangeOrder.price) ? 'red--text' : '', (unfilledExchangeOrder.side == 'open_short' && unfilledExchangeOrder.fixedStopLossPrice > unfilledExchangeOrder.price) ? 'red--text' : '']">{{ calcEstimatedPL(unfilledExchangeOrder) }}</span>
                    </span>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <!-- time -->
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.time")}}
                  </span>
                  <span class="float-right">
                    {{ formatDate(unfilledExchangeOrder.timestamp) }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <!-- strategy -->
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("common.strategy")}}
                  </span>
                  <span class="float-right" :style="{color: getColorByStrategy(unfilledExchangeOrder.strategy)}">
                    {{ unfilledExchangeOrder.strategy }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <!--  category -->
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("common.category")}}
                  </span>
                  <span class="float-right" :class="unfilledExchangeOrder.bgColor">
                    {{ unfilledExchangeOrder.category }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <!-- action -->
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.action")}}
                  </span>
                  <span class="float-right">
                    <v-icon color="grey" small class="pointer" @click="copyToClipboard(unfilledExchangeOrder.futuresOrderId)"
                      :title="unfilledExchangeOrder.futuresOrderId">mdi-content-copy</v-icon>
                    <v-icon color="orange" small class="pointer ml-3" @click="showChangeOrderDialog(unfilledExchangeOrder)"
                      title="Edit">mdi-pencil</v-icon>
                    <v-icon color="red" small class="pointer ml-3" @click="deleteSubmittedOrderById(unfilledExchangeOrder.id)"
                      title="Cancel">mdi-close</v-icon>
                  </span>
                </v-col>
              </v-row>
            </v-card>
            <!-- 加载状态 -->
            <div class="text-center" v-show="unfilledAtExchangesLoading">
              <div>
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </div>
            </div>
            <div class="mt-3 mb-5" v-show="unfilledExchangeOrders.length > 0">
              <v-pagination color="lightred" circle prev-icon="mdi-menu-left" next-icon="mdi-menu-right" v-model="unfilledExchangeOrdersPageNo"
                :length="unfilledExchangeOrdersTotalPage" :total-visible="6" @input="queryUnfilledExchangeOrdersByPages"></v-pagination>
            </div>
          </v-row>
          <!-- No data available -->
          <v-card flat class="mt-2 my-3 text-center" v-show="!unfilledAtExchangesLoading  && unfilledExchangeOrders.length <= 0 ">
            <v-row>
              <v-col cols="12" sm="12" md="12" class="ma-2">
                {{ $t('futuresTrading.noDataAvailable') }}
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <!--******************************** 手机第五页 ********************************-->
      <v-row v-if="orders=='filledExchangeOrders'">
        <v-col cols="12" no-gutters>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="5" class="mr-2">
              <v-select dense class="fs-12" v-model="orderSearch.exchange" :label="$t('common.exchange')" :items="exchanges"
                item-text="name" item-value="code" clearable></v-select>
            </v-col>
            <v-col cols="5" class="mr-5">
              <v-select dense class="fs-12" v-model="orderSearch.symbol" :label="$t('common.symbol')" :items="futuresSymbols"
                clearable></v-select>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="5" class="mr-2">
              <v-select dense class="fs-12" v-model="orderSearch.side" :label="$t('common.side')" :items="orderSides"
                clearable></v-select>
            </v-col>
            <v-col cols="5" class="mr-5">
              <v-select dense class="fs-12" v-model="orderSearch.strategy" :label="$t('common.strategy')" :items="strategies"
                clearable></v-select>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="5" class="mr-2">
              <v-select dense class="fs-12" v-model="orderSearch.category" :label="$t('common.category')" :items="orderCategories"
                clearable></v-select>
            </v-col>
            <v-col cols="5" class="mr-5">
            </v-col>
          </v-row>
          <v-row no-gutters justify="center" align="center">
            <v-card flat v-for="(filledExchangeOrder, i) in filledExchangeOrders" :key="i" class="mt-2 my-3 mx-3">
              <v-row class="ma-3" no-gutters>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.exchange")}}
                  </span>
                  <span class="float-right">
                    <span :class="CommonJS.formatExchangeColor(filledExchangeOrder.exchangeName)">{{ filledExchangeOrder.exchangeName }}</span>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.symbol")}}
                  </span>
                  <span class="float-right">
                    {{ filledExchangeOrder.symbol }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <!-- side -->
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.side")}}
                  </span>
                  <span class="float-right" :class="filledExchangeOrder.side == 'open_long' || filledExchangeOrder.side == 'close_buy' ? 'green--text' : 'red--text'">
                    <span v-side="filledExchangeOrder.side"></span>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <!-- size -->
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.size")}}
                  </span>
                  <span class="float-right">
                    {{ (filledExchangeOrder.side == 'open_short'|| filledExchangeOrder.side == 'close_sell') ? '-' : '' }}{{ formatNum(filledExchangeOrder.size) }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <!-- price -->
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.price")}}
                  </span>
                  <span class="float-right">
                    {{ formatNum(filledExchangeOrder.price.toFixed(futuresDecimalNumbers[filledExchangeOrder.symbol])) }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <!-- time -->
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.time")}}
                  </span>
                  <span class="float-right">
                    {{ formatDate(filledExchangeOrder.timestamp) }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <!--  status -->
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.status")}}
                  </span>
                  <span class="float-right" v-status="filledExchangeOrder.status"></span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <!--  strategy-->
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("common.strategy")}}
                  </span>
                  <span class="float-right" :style="{color: getColorByStrategy(filledExchangeOrder.strategy)}">
                    {{ filledExchangeOrder.strategy }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <!-- category -->
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("common.category")}}
                  </span>
                  <span class="float-right" :class="filledExchangeOrder.bgColor">
                    {{ filledExchangeOrder.category }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <!-- action -->
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.action")}}
                  </span>
                  <span class="float-right">
                    <v-icon color="grey" small class="pointer" @click="copyToClipboard(filledExchangeOrder.futuresOrderId)"
                      :title="filledExchangeOrder.futuresOrderId">mdi-content-copy</v-icon>
                  </span>
                </v-col>
              </v-row>
            </v-card>
            <!-- 加载状态 -->
            <div class="text-center" v-show="filledExchangeOrdersLoading">
              <div>
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </div>
            </div>
            <div class="mt-3 mb-5" v-show="filledExchangeOrders.length > 0">
              <v-pagination color="lightred" circle prev-icon="mdi-menu-left" next-icon="mdi-menu-right" v-model="filledExchangeOrdersPageNo"
                :length="filledExchangeOrdersTotalPage" :total-visible="6" @input="queryFilledExchangeOrdersByPages"></v-pagination>
            </div>
          </v-row>
          <!-- No data available -->
          <v-card flat class="mt-2 my-3 text-center" v-show="!filledExchangeOrdersLoading && filledExchangeOrders.length <= 0">
            <v-row>
              <v-col cols="12" sm="12" md="12" class="ma-2">
                {{ $t('futuresTrading.noDataAvailable') }}
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <!--******************************** 手机第六页 ********************************-->
      <v-row v-if="orders=='openPositions'">
        <v-col cols="12" no-gutters>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="5" class="mr-2">
              <v-select dense class="fs-12" v-model="orderSearch.exchange" :label="$t('common.exchange')" :items="exchanges"
                item-text="name" item-value="code" clearable></v-select>
            </v-col>
            <v-col cols="5" class="mr-5">
              <v-select dense class="fs-12" v-model="orderSearch.symbol" :label="$t('common.symbol')" :items="futuresSymbols"
                clearable></v-select>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="5" class="mr-2">
              <v-select dense class="fs-12" v-model="orderSearch.side" :label="$t('common.side')" :items="orderSides"
                clearable></v-select>
            </v-col>
            <v-col cols="5" class="mr-5">
              <v-select dense class="fs-12" v-model="orderSearch.strategy" :label="$t('common.strategy')" :items="strategies"
                clearable></v-select>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="5" class="mr-5">
              <v-select dense class="fs-12" v-model="orderSearch.category" :label="$t('common.category')" :items="orderCategories"
                clearable></v-select>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="10" class="mr-3 text-right my-2">
               <v-btn x-small tile class="red accent-4 white--text" :disabled="killAllOpenPositionsCountdown != null" @click="killAllOpenPositionsDialog = true">
                 <span v-show="killAllOpenPositionsCountdown != null">{{ killAllOpenPositionsCountdown }}</span>
                 <span v-show="killAllOpenPositionsCountdown == null">{{ $t('common.killAll') }}</span>
               </v-btn>
              <v-btn x-small tile class="kill ml-3 white--text" :disabled="killOpenPositionsCountdown != null" @click="killOpenPositionsDialog = true">
                <span v-show="killOpenPositionsCountdown != null">{{ killOpenPositionsCountdown }}</span>
                <span v-show="killOpenPositionsCountdown == null">{{ $t('common.kill') }}</span>
              </v-btn>
              <v-btn x-small tile class="teal ml-3 white--text" @click="karaDialog = true">{{ $t('common.kara') }}</v-btn>
              <v-btn x-small tile class="orange darken-4 ml-3 mr-2 white--text" @click="karDialog = true">{{ $t('common.kar') }}</v-btn>
            </v-col>
          </v-row>
          <v-row no-gutters justify="center" align="center">
            <v-card flat v-for="(openPositionsOrder, i) in openPositionsOrders" :key="i" class="mt-2 my-3 mx-3">
              <v-row class="ma-3" no-gutters>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.exchange")}}
                  </span>
                  <span class="float-right">
                    <span :class="CommonJS.formatExchangeColor(openPositionsOrder.exchangeName)">{{ openPositionsOrder.exchangeName }}</span>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.symbol")}}
                  </span>
                  <span class="float-right">
                    {{ formatNum(openPositionsOrder.symbol) }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.side")}}
                  </span>
                  <span class="float-right" :class="openPositionsOrder.side == 'open_long' || openPositionsOrder.side == 'close_buy' ? 'green--text' : 'red--text'">
                    <span v-side="openPositionsOrder.side"></span>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.size")}}
                  </span>
                  <span class="float-right">
                    {{ (openPositionsOrder.side == 'open_short'|| openPositionsOrder.side == 'close_sell') ? '-' : '' }}{{ formatNum(openPositionsOrder.size) }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <!-- price -->
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.price")}}
                  </span>
                  <span class="float-right">
                    {{ formatNum(openPositionsOrder.price.toFixed(futuresDecimalNumbers[openPositionsOrder.symbol])) }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <!-- takeProfit -->
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.takeProfit")}}
                  </span>
                  <span class="float-right">
                    {{ formatNum(openPositionsOrder.takeProfitPrice > 0 ? openPositionsOrder.takeProfitPrice.toFixed(futuresDecimalNumbers[openPositionsOrder.symbol]) : '--') }}
                    <span> / </span>
                    <span :class="[(openPositionsOrder.side == 'open_long' && openPositionsOrder.takeProfitPrice > 0 && openPositionsOrder.takeProfitPrice < openPositionsOrder.price) ? 'red--text' : '', (openPositionsOrder.side == 'open_short' && openPositionsOrder.takeProfitPrice > openPositionsOrder.price) ? 'red--text' : '']">{{ calcTakeProfitEstimatedPL(openPositionsOrder) }}</span>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <!-- safetyNet -->
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.safetyNet")}}
                  </span>
                  <span class="float-right">
                    <span>{{ formatNum(openPositionsOrder.stopLossPrice1 > 0 ? openPositionsOrder.stopLossPrice1.toFixed(futuresDecimalNumbers[openPositionsOrder.symbol]) : '--') }}</span>
                    <span> / </span>
                    <span :class="[(openPositionsOrder.side == 'open_long' && openPositionsOrder.stopLossPrice1 < openPositionsOrder.price) ? 'red--text' : '', (openPositionsOrder.side == 'open_short' && openPositionsOrder.stopLossPrice1 > openPositionsOrder.price) ? 'red--text' : '']">{{ calcSafetyNetEstimatedPL(openPositionsOrder) }}</span>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <!-- stop loss -->
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.stopLoss")}}
                    <span v-show="!openPositionsOrder.beFixedStopLossPrice">({{$t("common.trailing")}})</span>
                    <span v-show="openPositionsOrder.beFixedStopLossPrice">({{$t("common.fixed")}})</span>
                  </span>
                  <span class="float-right">
                    <span v-show="!openPositionsOrder.beFixedStopLossPrice">
                      <span>{{ formatNum(openPositionsOrder.stopLossPrice > 0 ? openPositionsOrder.stopLossPrice.toFixed(futuresDecimalNumbers[openPositionsOrder.symbol]) : '--') }}</span>
                      <span> / </span>
                      <span :class="[(openPositionsOrder.side == 'open_long' && openPositionsOrder.stopLossPrice < openPositionsOrder.price) ? 'red--text' : '', (openPositionsOrder.side == 'open_short' && openPositionsOrder.stopLossPrice > openPositionsOrder.price) ? 'red--text' : '']">{{ calcEstimatedPL(openPositionsOrder) }}</span>
                    </span>
                    <span v-show="openPositionsOrder.beFixedStopLossPrice">
                      <span>{{ formatNum(openPositionsOrder.fixedStopLossPrice > 0 ? openPositionsOrder.fixedStopLossPrice.toFixed(futuresDecimalNumbers[openPositionsOrder.symbol]) : '--') }}</span>
                      <span> / </span>
                      <span :class="[(openPositionsOrder.side == 'open_long' && openPositionsOrder.fixedStopLossPrice < openPositionsOrder.price) ? 'red--text' : '', (openPositionsOrder.side == 'open_short' && openPositionsOrder.fixedStopLossPrice > openPositionsOrder.price) ? 'red--text' : '']">{{ calcEstimatedPL(openPositionsOrder) }}</span>
                    </span>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <!--  takeProfitEffectiveTime -->
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.takeProfitEffectiveTime")}}
                  </span>
                  <span class="float-right">
                    <v-tooltip bottom open-delay="100" close-delay="100">
                      <template v-slot:activator="{ on }">
                        <span v-on="on" :class="currentTimestamp >= openPositionsOrder.takeProfitStartTimestamp ? 'green--text' : 'grey--text text--lighten-1'">
                          {{formatDate(openPositionsOrder.takeProfitStartTimestamp)}}
                        </span>
                      </template>
                      <span>{{formatDate(openPositionsOrder.takeProfitStartTimestamp)}}</span>
                      <span>-</span>
                      <span>
                        {{ -1 == openPositionsOrder.takeProfitEndTimestamp ? $t('futuresTrading.infinity') : formatDate(openPositionsOrder.takeProfitEndTimestamp)}}
                      </span>
                    </v-tooltip>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <!-- stopLoss1EffectiveTime -->
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.snEffectiveTime")}}
                  </span>
                  <span class="float-right">
                    <v-tooltip bottom open-delay="100" close-delay="100">
                      <template v-slot:activator="{ on }">
                        <span v-on="on" :class="currentTimestamp >= openPositionsOrder.stopLoss1StartTimestamp ? 'green--text' : 'grey--text text--lighten-1'">
                          {{formatDate(openPositionsOrder.stopLoss1StartTimestamp)}}
                        </span>
                      </template>
                      <span>{{formatDate(openPositionsOrder.stopLoss1StartTimestamp)}}</span>
                      <span>-</span>
                      <span>
                        {{ -1 == openPositionsOrder.stopLoss1EndTimestamp ? $t('futuresTrading.infinity') : formatDate(openPositionsOrder.stopLoss1EndTimestamp)}}
                      </span>
                    </v-tooltip>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <!-- stopLossEffectiveTime -->
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.stopLossEffectiveTime")}}
                  </span>
                  <span class="float-right" v-show="!openPositionsOrder.beFixedStopLossPrice">
                    <v-tooltip bottom open-delay="100" close-delay="100">
                      <template v-slot:activator="{ on }">
                        <span v-on="on" :class="currentTimestamp >= openPositionsOrder.stopLossStartTimestamp ? 'green--text' : 'grey--text text--lighten-1'">
                          {{formatDate(openPositionsOrder.stopLossStartTimestamp)}}
                        </span>
                      </template>
                      <span>{{formatDate(openPositionsOrder.stopLossStartTimestamp)}}</span>
                      <span>-</span>
                      <span>
                        {{ -1 == openPositionsOrder.stopLossEndTimestamp ? $t('futuresTrading.infinity') : formatDate(openPositionsOrder.stopLossEndTimestamp)}}
                      </span>
                    </v-tooltip>
                  </span>
                  <span class="float-right" v-show="openPositionsOrder.beFixedStopLossPrice">
                    <v-tooltip bottom open-delay="100" close-delay="100">
                      <template v-slot:activator="{ on }">
                        <span v-on="on" :class="currentTimestamp >= openPositionsOrder.fixedStopLossStartTimestamp ? 'green--text' : 'grey--text text--lighten-1'">
                          {{formatDate(openPositionsOrder.fixedStopLossStartTimestamp)}}
                        </span>
                      </template>
                      <span>{{formatDate(openPositionsOrder.fixedStopLossStartTimestamp)}}</span>
                      <span>-</span>
                      <span>
                        {{ -1 == openPositionsOrder.fixedStopLossEndTimestamp ? $t('futuresTrading.infinity') : formatDate(openPositionsOrder.fixedStopLossEndTimestamp)}}
                      </span>
                    </v-tooltip>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <!-- strategy -->
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("common.strategy")}}
                  </span>
                  <span class="float-right" :style="{color: getColorByStrategy(openPositionsOrder.strategy)}">
                    {{ openPositionsOrder.strategy }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("common.category")}}
                  </span>
                  <span class="float-right" :class="openPositionsOrder.bgColor">
                    {{ openPositionsOrder.category }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.action")}}
                  </span>
                  <span class="float-right">
                    <v-icon color="grey" small class="pointer" @click="copyToClipboard(openPositionsOrder.futuresOrderId)"
                      :title="openPositionsOrder.futuresOrderId">mdi-content-copy</v-icon>
                    <v-icon color="orange" small class="pointer ml-3" :title="$t('common.edit')" @click="showChangeOrderDialog(openPositionsOrder)">mdi-pencil</v-icon>
                    <v-icon color="green" small class="pointer ml-3" :title="$t('common.split')" @click="showSplitOrderDialog(openPositionsOrder)">mdi-content-cut</v-icon>
                    <v-icon color="blue" small class="pointer ml-3" :title="$t('common.time')" @click="showChangeTimeDialog(openPositionsOrder)">mdi-clock-outline</v-icon>
                    <v-icon color="blue-grey lighten-1" small class="pointer ml-3" @click="showChangeNoteDialog('openPositions', openPositionsOrder)"
                      :title="$t('common.strategy')">icon icon-s1</v-icon>
                    <v-icon color="red" small class="pointer ml-3" :title="$t('common.close')" @click="showExitPositionOrderDialog(openPositionsOrder)">mdi-close</v-icon>
                    <v-icon color="cyan lighten-1" small class="pointer ml-3" :title="$t('common.stopAndReverse')" @click="showSARDialog(openPositionsOrder)">mdi-rotate-left</v-icon>
                    <v-icon color="red lighten-1" small class="pointer ml-3" :title="$t('common.deletePositionOrder')" @click.stop="showDeletePositionOrderDialog(openPositionsOrder.futuresOrderId)">mdi-power</v-icon>
                  </span>
                </v-col>
              </v-row>
            </v-card>
            <!-- 加载状态 -->
            <div class="text-center" v-show="openPositionsLoading">
              <div>
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </div>
            </div>
            <div class="mt-3 mb-5" v-show="openPositionsOrders.length > 0">
              <v-pagination color="lightred" circle prev-icon="mdi-menu-left" next-icon="mdi-menu-right" v-model="openPositionsOrdersPageNo"
                :length="openPositionsOrdersTotalPage" :total-visible="6" @input="queryOpenPositionsOrdersByPages"></v-pagination>
            </div>
          </v-row>
          <!-- No data available -->
          <v-card flat class="mt-2 my-3 text-center" v-show="!openPositionsLoading  && openPositionsOrders.length <= 0 ">
            <v-row>
              <v-col cols="12" sm="12" md="12" class="ma-2">
                {{ $t('futuresTrading.noDataAvailable') }}
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <!--******************************** 手机第七页 ********************************-->
      <v-row v-if="orders=='openPositionsAtExchanges'">
        <v-col cols="12" no-gutters>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="5" class="mr-2">
              <v-select class="fs-12" v-model="openPositionsExchangeSearch" :items="exchanges" :label="$t('futuresTrading.exchange')"
                item-text="name" item-value="code" clearable></v-select>
            </v-col>
            <v-col cols="5" class="mr-5">
              <v-select class="fs-12" v-model="openPositionsSymbolSearch" :label="$t('futuresTrading.symbol')" :items="futuresSymbols"
                clearable></v-select>
            </v-col>
          </v-row>
          <v-row no-gutters justify="center" align="center">
            <v-card flat v-for="(openPositionsAtExchange, i) in openPositionsAtExchanges" :key="i" class="mt-2 my-3 mx-3"
              v-show="!openPositionsAtExchangesLoading">
              <v-row class="ma-3" no-gutters>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.exchange")}}
                  </span>
                  <span class="float-right">
                    <span :class="CommonJS.formatExchangeColor(openPositionsAtExchange.exchange)">{{ openPositionsAtExchange.exchange }}</span>
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.symbol")}}
                  </span>
                  <span class="float-right">
                    {{ openPositionsAtExchange.symbol }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.side")}}
                  </span>
                  <span class="float-right" :class="openPositionsAtExchange.side == 'Long' || openPositionsAtExchange.side == 'buy' ? 'green--text' : 'red--text'">
                    {{ openPositionsAtExchange.side }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.size")}}
                  </span>
                  <span class="float-right">
                    {{ openPositionsAtExchange.side == 'Short' ? '-' : '' }}{{ formatNum(openPositionsAtExchange.size) }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <!-- avgPrice -->
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.avgPrice")}}
                  </span>
                  <span class="float-right">
                    {{ formatNum(openPositionsAtExchange.price.toFixed(futuresDecimalNumbers[openPositionsAtExchange.symbol])) }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <!-- liqPrice -->
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.liquidationPrice")}}
                  </span>
                  <span class="float-right">
                    {{ openPositionsAtExchange.liquidationPrice == 0 ? '--' : formatNum(openPositionsAtExchange.liquidationPrice.toFixed(futuresDecimalNumbers[openPositionsAtExchange.symbol])) }}
                  </span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <!-- RoE -->
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.roe")}}
                  </span>
                  <span class="float-right" :title="openPositionsAtExchange.returnOnEquity" :class="openPositionsAtExchange.returnOnEquity == 0 ? '' : openPositionsAtExchange.returnOnEquity > 0 ? 'green--text' : 'red--text' ">{{ formatNum((openPositionsAtExchange.returnOnEquity * 100).toFixed(2)) + '%'}}</span>
                </v-col>
                <v-col cols="12" no-gutters class="my-0 py-0">
                  <v-divider></v-divider>
                </v-col>
                <!-- pl -->
                <v-col cols="12" no-gutters>
                  <span class="grey--text text--lighten-1">
                    {{$t("futuresTrading.pl")}}
                  </span>
                  <span class="float-right" :title="openPositionsAtExchange.profitAndLoss" :class="openPositionsAtExchange.profitAndLoss == 0 ? '' : openPositionsAtExchange.profitAndLoss >= 0 ? 'green--text' : 'red--text' ">
                    <span>{{ formatNum(openPositionsAtExchange.profitAndLoss.toFixed(8)) }} {{openPositionsAtExchange.baseCurrency != null ? openPositionsAtExchange.baseCurrency : ''}}</span>
                    <span> | </span>
                    <span v-if="openPositionsAtExchange.profitAndLoss < 0">(</span>
                    <span>{{ '$' + formatNum(Math.abs(openPositionsAtExchange.markPrice * openPositionsAtExchange.profitAndLoss).toFixed(2)) }}</span>
                    <span v-if="openPositionsAtExchange.profitAndLoss < 0">)</span>
                  </span>
                </v-col>
              </v-row>
            </v-card>
            <!-- 加载状态 -->
            <div class="text-center" v-show="openPositionsAtExchangesLoading">
              <div>
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </div>
            </div>
          </v-row>
          <!-- No data available -->
          <v-card flat class="mt-2 my-3 text-center" v-show="!openPositionsAtExchangesLoading  && openPositionsAtExchanges <= 0 ">
            <v-row>
              <v-col cols="12" sm="12" md="12" class="ma-2">
                {{ $t('futuresTrading.noDataAvailable') }}
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="responsive == 'mobile'" class="flex-b pl-0 mt-5 pr-0 mb-10 pb-5">
      <trading-candle-chart v-show="bottomNav == 'trading'" :exchange="exchangeCode" :tradingCategory="'futures'" :symbol="orderForm.symbol" :currency="getBaseCurrency(orderForm.symbol)" :openPrices="getOpenPrices()" :openPositionsPrices="getOpenPositionsPrices()"></trading-candle-chart>
      <v-divider v-show="bottomNav =='trading'" class="py-3"></v-divider>
      <trading-heikin-ashi-chart v-show="bottomNav == 'trading'" :exchange="exchangeCode" :tradingCategory="'futures'" :symbol="orderForm.symbol" :currency="getBaseCurrency(orderForm.symbol)" :openPrices="getOpenPrices()" :openPositionsPrices="getOpenPositionsPrices()"></trading-heikin-ashi-chart>
      <v-divider v-show="bottomNav =='trading'" class="py-3"></v-divider>
      <trading-renko-chart v-show="bottomNav =='trading'" ref='tradingRenkoChart' :strategy="orderForm.strategy" :symbol="orderForm.symbol" :tradingCategory="'futures'" @changeRenkoStrategy="receiveChangeRenkoStrategy" @transferPrice="receiveRenkoPrice" @resetTakeAndStopPrice="receiveResetTakeAndStopPrice"></trading-renko-chart>
      <asset-summary ref='summary' v-show="bottomNav =='summary'"></asset-summary>
    </v-container>
    <v-bottom-navigation v-if="responsive == 'mobile'" v-model="bottomNav" fixed :class="[darkMode?'grey darken-3':'']" class="mt-4 flex-b text-center">
      <v-btn color="lightred" text tile x-large value="trading" class="mx-5">
        <span>{{$t("futuresTrading.trading")}}</span>
      </v-btn>
      <v-btn color="lightred" text tile x-large value="orders">
        <span>{{$t("futuresTrading.orders")}}</span>
      </v-btn>
      <v-btn color="lightred" text tile x-large value="summary" class="mx-3">
        <span>{{$t("futuresTrading.summary")}}</span>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>
<script>
  import Vue from 'vue'
  import config from '@/config.js'
  import { mapGetters } from "vuex";
  import KrakenFuturesMarketPrice from "@/components/marketPrice/KrakenFuturesMarketPrice.vue";
  import OkexFuturesMarketPrice from "@/components/marketPrice/OkexFuturesMarketPrice.vue";
  import BinanceFuturesMarketPrice from "@/components/marketPrice/BinanceFuturesMarketPrice.vue";
  import AssetsTrendLineChart from "@/highcharts/AssetsTrendLineChart.vue";
  import { setInterval, clearInterval } from 'timers';
  import AppIcon from "@/components/Icon.vue";
  import AssetLine from "@/components/asset/AssetLine";
  import AssetSummary from "@/components/summary/CoinAssetSummary";
  import TradingRenkoChart from '@/highcharts/TradingRenkoChart.vue';
  import TradingCandleChart from '@/highcharts/TradingCandleChart.vue';
  import TradingHeikinAshiChart from '@/highcharts/TradingHeikinAshiChart.vue';
  import SwapCoinKillAndReverseAllDialog from '@/components/dialog/SwapCoinKillAndReverseAllDialog.vue';
  import SwapCoinKillAndReverseDialog from '@/components/dialog/SwapCoinKillAndReverseDialog.vue';
  import SwapCoinDeleteAllPendingEntryDialog from '@/components/dialog/SwapCoinDeleteAllPendingEntryDialog.vue';
  import SwapCoinDeletePendingEntryDialog from '@/components/dialog/SwapCoinDeletePendingEntryDialog.vue';
  import SwapCoinDeletePositionOrderDialog from '@/components/dialog/SwapCoinDeletePositionOrderDialog.vue';
  import TradingTypeChange from "@/components/trading/TradingTypeChange";
  import ChangePercentage from "@/components/marketPrice/ChangePercentage";
  import SearchCoin from '@/components/search/SearchCoin.vue';
  export default {
    data() {
      return {
        exchangeCode: this.$route.params.exchangeCode,
        symbol: this.$route.params.symbol,
        querySide: this.$route.query.side,
        queryStrategy: this.$route.query.strategy,
        queryPrice: this.$route.query.price,
        fullScreen: false,
        drawer: true,
        mini: false,
        symbols: [],
        pairs: [],
        tab: 'pendingEntryOrders',
        bottomNav: "trading",
        quoteCurrency: null,
        baseCurrency: null,
        nextOrder: "next1",
        orders: 'openPositionsAtExchanges',
        priceString: '0.0',
        sizeString: '0.0',
        slippageString: '0.0',
        takeProfitString: '0.0',
        stopLossString: '0.0',
        // 发送的订单表单
        orderForm: {
          exchangeId: null,
          exchangeName: this.$route.params.exchangeCode,
          symbol: null,
          side: 'open_long',
          orderType: 'limit',
          // 价格类型，默认手动/bbo为对手价
          priceType: 'manually',
          // leverage: 'none',
          price: 0,
          size: 0,
          renkoEntryMultiplier: 0,
          strategy: null,
          category: 'TP',
          takeProfitPrice: 0,
          trailingStopLevel1: 0,
          trailingStopLevel2: 0,
          trailingStopLevel3: 0,
          priceLevel2: 0,
          priceLevel3: 0,
          sendOrderStartDatetime: '',
          sendOrderStartTimestamp: 0
        },
        submitBtnLoading: false,
        ex4: [this.$t('futuresTrading.ex4')],
        expiry: [this.$t('futuresTrading.expiryCancelled'), this.$t('futuresTrading.expiryDay'), this.$t(
          'futuresTrading.expiryWeek'), this.$t('futuresTrading.expiryMonth'), this.$t(
          'futuresTrading.customExpiry')],
        pendingEntryOrdersHeader: [{
            sortable: false,
            text: this.$t('futuresTrading.exchange')
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.symbol')
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.side')
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.size'),
            align: 'end'
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.price'),
            align: 'end'
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.takeProfit'),
            align: 'end'
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.safetyNet'),
            align: 'end'
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.stopLoss'),
            align: 'end'
          },
          // { sortable: false, text: this.$t('futuresTrading.leverage') },
          {
            sortable: false,
            text: this.$t('futuresTrading.effectiveTime')
          },
          {
            sortable: false,
            text: this.$t('common.strategy')
          },
          {
            sortable: false,
            text: this.$t('common.category')
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.action')
          }
        ],
        pendingExitOrdersHeader: [{
            sortable: false,
            text: this.$t('futuresTrading.exchange')
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.symbol')
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.side')
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.size'),
            align: 'end'
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.price'),
            align: 'end'
          },
          // { sortable: false, text: this.$t('futuresTrading.leverage') },
          {
            sortable: false,
            text: this.$t('futuresTrading.effectiveTime')
          },
          {
            sortable: false,
            text: this.$t('common.strategy')
          },
          {
            sortable: false,
            text: this.$t('common.category')
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.action')
          }
        ],
        submittedOrderHeader: [{
            sortable: false,
            text: this.$t('futuresTrading.exchange')
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.symbol')
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.side')
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.size'),
            align: 'end'
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.price'),
            align: 'end'
          },
          // { sortable: false, text: this.$t('futuresTrading.leverage') },
          {
            sortable: false,
            text: this.$t('futuresTrading.status')
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.time')
          },
          {
            sortable: false,
            text: this.$t('common.strategy')
          },
          {
            sortable: false,
            text: this.$t('common.category')
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.action')
          }
        ],
        unfilledExchangeOrdersHeader: [{
            sortable: false,
            text: this.$t('futuresTrading.exchange')
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.symbol')
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.side')
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.size'),
            align: 'end'
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.price'),
            align: 'end'
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.takeProfit'),
            align: 'end'
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.safetyNet'),
            align: 'end'
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.stopLoss'),
            align: 'end'
          },
          // { sortable: false, text: this.$t('futuresTrading.leverage') },
          {
            sortable: false,
            text: this.$t('futuresTrading.time')
          },
          {
            sortable: false,
            text: this.$t('common.strategy')
          },
          {
            sortable: false,
            text: this.$t('common.category')
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.action')
          }
        ],
        filledExchangeOrdersHeader: [{
            sortable: false,
            text: this.$t('futuresTrading.exchange')
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.symbol')
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.side')
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.size'),
            align: 'end'
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.price'),
            align: 'end'
          },
          // { sortable: false, text: this.$t('futuresTrading.leverage') },
          {
            sortable: false,
            text: this.$t('futuresTrading.status')
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.time')
          },
          {
            sortable: false,
            text: this.$t('common.strategy')
          },
          {
            sortable: false,
            text: this.$t('common.category')
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.action')
          }
        ],
        openPositionsOrdersHeader: [{
            sortable: false,
            text: this.$t('futuresTrading.exchange')
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.symbol')
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.side')
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.size'),
            align: 'end'
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.price'),
            align: 'end'
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.takeProfit'),
            align: 'end'
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.safetyNet'),
            align: 'end'
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.stopLoss'),
            align: 'end'
          },
          // {
          //   sortable: false,
          //   text: this.$t('futuresTrading.takeProfitEffectiveTime')
          // },
          {
            sortable: false,
            text: this.$t('futuresTrading.snEffectiveTime')
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.stopLossEffectiveTime')
          },
          {
            sortable: false,
            text: this.$t('common.strategy')
          },
          {
            sortable: false,
            text: this.$t('common.category')
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.action')
          }
        ],
        openPositionsAtExchangesHeader: [{
            sortable: false,
            text: this.$t('futuresTrading.exchange')
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.symbol')
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.side')
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.size'),
            align: 'end'
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.avgPrice'),
            align: 'end',
            title: 'aaaa'
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.liquidationPrice'),
            align: 'end'
          },
          // { sortable: false, text: this.$t('futuresTrading.effectiveLvg'), class: ['text-xs-right'] },
          {
            sortable: false,
            text: this.$t('futuresTrading.roe'),
            align: 'end'
          },
          {
            sortable: false,
            text: this.$t('futuresTrading.pl'),
            align: 'end'
          }
        ],
        isSetApiKey: false,
        accounts: null,
        // 每页查询的数量
        pageSize: 10,
        // 订单搜索
        orderSearch: {
          exchange: null,
          symbol: null,
          side: null,
          strategy: null,
          category: null,
        },
        // 放置的进入订单
        pendingEntryOrders: [],
        pendingEntryOrdersTotalElements: 0,
        pendingEntryOrdersPageNo: 1,
        pendingEntryOrdersTotalPage: 0,
        pendingEntryOrdersExchangeSearch: null,
        pendingEntryOrdersSymbolSearch: null,
        // 放置的退出订单
        pendingExitOrders: [],
        pendingExitOrdersTotalElements: 0,
        pendingExitOrdersPageNo: 1,
        pendingExitOrdersTotalPage: 0,
        pendingExitOrdersExchangeSearch: null,
        pendingExitOrdersSymbolSearch: null,
        // 已提交到交易所的订单
        submittedOrders: [],
        submittedOrdersTotalElements: 0,
        submittedOrdersPageNo: 1,
        submittedOrdersTotalPage: 0,
        submittedOrdersExchangeSearch: null,
        submittedOrdersSymbolSearch: null,
        // 未成交的订单
        unfilledExchangeOrders: [],
        unfilledExchangeOrdersTotalElements: 0,
        unfilledExchangeOrdersPageNo: 1,
        unfilledExchangeOrdersTotalPage: 0,
        unfilledExchangeOrdersExchangeSearch: null,
        unfilledExchangeOrdersSymbolSearch: null,
        // 已成交的订单
        filledExchangeOrders: [],
        filledExchangeOrdersTotalElements: 0,
        filledExchangeOrdersPageNo: 1,
        filledExchangeOrdersTotalPage: 0,
        filledExchangeOrdersExchangeSearch: null,
        filledExchangeOrdersSymbolSearch: null,
        // 已持仓的订单
        openPositionsOrders: [],
        openPositionsOrdersTotalElements: 0,
        openPositionsOrdersPageNo: 1,
        openPositionsOrdersTotalPage: 0,
        openPositionsOrdersExchangeSearch: null,
        openPositionsOrdersSymbolSearch: null,
        // 交易所持仓
        openPositionsAtExchanges: [],
        openPositionsAtExchangesPagination: {
          descending: false,
          page: 1,
          rowsPerPage: 10,
        },
        openPositionsAtExchangesTotalElements: 0,
        openPositionsAtExchangesTotalPage: 0,
        openPositionsExchangeSearch: null,
        openPositionsSymbolSearch: null,
        stopLossEnabled: true,
        takeProfitEnabled: true,
        side: "open",
        orderSide: "buy",
        buttonSide: 'long',
        // 价格间隙过大的弹出框
        largePriceGapDialog: false,
        getOrdersTimers: null,
        orderConfirmationDialog: false,
        deleteUnsubmittedOrderDialog: false,
        cancelSubmittedOrderDialog: false,
        unsubmittedOrdersId: null,
        submittedOrdersId: null,
        submittedOrderFormLoading: false,
        deleteUnsubmittedLoading: false,
        cancelSubmittedLoading: false,
        // 修改订单弹框
        changeOrderDialog: false,
        changeOrderLoading: false,
        // 修改定时器弹框
        changeTimeDialog: false,
        changeTimeLoading: false,
        // 设置止损价
        trailingStopDialog: false,
        trailingStopLevel1: 0,
        trailingStopLevel2: 0,
        trailingStopLevel3: 0,
        priceLevel2: 0,
        priceLevel3: 0,
        // 显示的修改的期货订单
        showChangedFuturesOrder: {},
        // 修改的期货订单
        changedFuturesOrderForm: {},
        // 修改的期货止损价订单
        changedFuturesOrderTrailingStopForm: {
          trailingStopLevel1: 0,
          trailingStopLevel2: 0,
          trailingStopLevel3: 0,
          priceLevel2: 0,
          priceLevel3: 0
        },
        // 是否正在编辑订单
        isEditingFuturesOrder: true,
        trailingStopLevelRules: [
          val => val < 0 && 'Invalid parameter: ' + val + '%',
          val => val > 100 && 'Invalid parameter: ' + val + '%'
        ],
        // 分割订单弹框
        splitOrderDialog: false,
        splitOrderLoading: false,
        // 显示的分割的期货订单
        showSplitFuturesOrder: {},
        // 分割的期货订单
        splitFuturesOrderForm: {},
        // 分割的期货止损价订单
        splitFuturesOrderTrailingStopForm: {
          trailingStopLevel1: 0,
          trailingStopLevel2: 0,
          trailingStopLevel3: 0,
          priceLevel2: 0,
          priceLevel3: 0
        },
        // 退出仓位的期货订单弹框
        exitPositionOrderDialog: false,
        // 是否正在编辑退出仓位的订单
        isEditingExitPositionOrder: true,
        // 提交退出仓位订单的加载中
        exitPositionOrderLoading: false,
        // 显示的退出仓位的期货订单
        showExitPositionOrder: {},
        // 退出仓位的期货订单
        exitPositionOrderForm: {
          priceType: 'manually',
          price: 0
        },
        // 修改备注的弹框
        changeNoteDialog: false,
        // 修改备注加载中
        changeNoteLoading: false,
        // 修改备注参数
        changeNoteForm: {
          orderStatus: null,
          order: null,
          id: null,
          strategy: null,
          category: null
        },
        // 止损计划
        planVal: "1",
        currentTimeTimer: null,
        currentTime: null,
        currentTimestamp: 0,
        changeTimeOrder: {
          id: null,
          futuresOrderId: null,
          side: null,
          status: null,
          beFixedStopLossPrice: false,
          sendOrderEndSwitch: false,
          takeProfitEndSwitch: false,
          stopLoss1EndSwitch: false,
          stopLossEndSwitch: false,
          fixedStopLossEndSwitch: false,
          timestamp: null,
          sendOrderStartTimestamp: '',
          sendOrderEndTimestamp: '',
          takeProfitStartTimestamp: '',
          takeProfitEndTimestamp: '',
          stopLoss1StartTimestamp: '',
          stopLoss1EndTimestamp: '',
          stopLossStartTimestamp: '',
          stopLossEndTimestamp: '',
          fixedStopLossStartTimestamp: '',
          fixedStopLossEndTimestamp: ''
        },
        plResultSymbol: "",
        plResult: "",
        environment: config.environment,
        // 第一页加载状态
        pendingEntryLoading: false,
        // 第二页加载状态
        pendingExitLoading: false,
        // 第三页加载状态
        submittedExchangeOrdersLoading: false,
        // 第四页加载状态
        unfilledAtExchangesLoading: false,
        // 第五页加载状态
        filledExchangeOrdersLoading: false,
        // 第六页加载状态
        openPositionsLoading: false,
        // 第七页加载状态
        openPositionsAtExchangesLoading: false,
        // 价格可以变化
        priceIsChange: true,
        // 杠杆设置
        leverage: {},
        // 止盈价格变动次数
        formProfitPriceCount: 0,
        // 隐藏未持仓订单
        hideNonePositions: false,
        // 显示的子组件
        childComponent: 'candle',
        // 一键平仓的弹窗
        killAllOpenPositionsDialog: false,
        // 一键平仓的加载中
        killAllOpenPositionsLoading: false,
        // 一键平仓的倒计时
        killAllOpenPositionsCountdown: null,
        // 一键全部平仓倒计时定时器
        killAllOpenPositionsTimer: null,
        // 筛选一键平仓的弹窗
        killOpenPositionsDialog: false,
        // 筛选一键平仓的加载中
        killOpenPositionsLoading: false,
        // 筛选一键平仓的倒计时
        killOpenPositionsCountdown: null,
        // 筛选一键全部平仓倒计时定时器
        killOpenPositionsTimer: null,
        // 平仓并开反向单的弹窗
        sarDialog: false,
        // 平仓并开反向单的加载中
        sarLoading: false,
        // 平仓并开反向单的订单
        sarOrder: {},
        // 一键平仓并开反向单的弹窗
        karaDialog: false,
        // 筛选平仓并开反向单的弹窗
        karDialog: false,
        // Renko图止盈价
        renkoChartTakeProfitPrice: null,
        // Renko图止损价
        renkoChartStopLossPrice: null,
        // 删除全部的放置的进入订单弹窗
        deleteAllPendingEntryDialog: false,
        // 删除筛选的放置的进入订单弹窗
        deletePendingEntryDialog: false,
        // 删除仓位订单的弹窗
        deletePositionOrderDialog: false,
        // 删除仓位订单的期货主键
        deletePositionFuturesOrderId: null
      }
    },
    computed: {
      ...mapGetters(['exchanges', 'futuresSymbols', 'futuresDecimalNumbers', 'exchangeSupportedFuturesSymbols', 'darkMode', 'marketPrice', 'time', 'responsive', 'orderCategories', 'orderStrategies', 'contractFaceValue', 'orderSides', 'webSocketData', 'webSocketDataIndex', 'renkoMultipliers', 'networkStatus', 'strategies', 'strategyColors']),
      // 读市场价格的参数
      exchange() {
        const exchange = {
          exchangeCode: this.exchangeCode,
          tradingCategory: 'futures'
        }
        return exchange;
      },
      currentTimeZone() {
        if ('UTCTime' == this.time) {
          return 'UTC';
        } else {
          return 'local';
        }
      },
      pageRefreshDateTime: {
        get: function() {
          return formatISO8601Time(+new Date());
        },
        set: function(newValue) {
          return newValue;
        }
      },
      // size的美元价值
      sizeDollarValue() {
        if (this.orderForm.size > 0) {
          let faceValue = this.contractFaceValue[this.exchangeCode.toLowerCase()][this.symbol];
          let doller = this.formatNum((this.orderForm.size * faceValue).toFixed(2));
          return '$' + doller;
        }
      },
      // 是否禁用下单按钮
      isDisabledOrderButton() {
        if (this.orderForm.category != 'TP' && this.orderForm.side == 'open_long') {
          return true;
        }
        return false;
      },
      changedFuturesOrderFormPriceLevel2Result() {
        let percentage = ' [' + this.changedFuturesOrderTrailingStopForm.priceLevel2 + '%]';
        if ('open_long' == this.changedFuturesOrderForm.side) {
          return this.formatNum((this.changedFuturesOrderForm.price * (1 + Number(this.changedFuturesOrderForm.priceLevel2)))
            .toFixed(this.futuresDecimalNumbers[this.showChangedFuturesOrder.symbol])) + percentage;
        } else if ('open_short' == this.changedFuturesOrderForm.side) {
          return this.formatNum((this.changedFuturesOrderForm.price * (1 - Number(this.changedFuturesOrderForm.priceLevel2)))
            .toFixed(this.futuresDecimalNumbers[this.showChangedFuturesOrder.symbol])) + percentage;
        } else {
          return '--';
        }
      },
      changedFuturesOrderFormPriceLevel3Result() {
        let percentage = ' [' + this.changedFuturesOrderTrailingStopForm.priceLevel3 + '%]';
        if ('open_long' == this.changedFuturesOrderForm.side) {
          return this.formatNum((this.changedFuturesOrderForm.price * (1 + Number(this.changedFuturesOrderForm.priceLevel3)))
            .toFixed(this.futuresDecimalNumbers[this.showChangedFuturesOrder.symbol])) + percentage;
        } else if ('open_short' == this.changedFuturesOrderForm.side) {
          return this.formatNum((this.changedFuturesOrderForm.price * (1 - Number(this.changedFuturesOrderForm.priceLevel3)))
            .toFixed(this.futuresDecimalNumbers[this.showChangedFuturesOrder.symbol])) + percentage;
        } else {
          return '--';
        }
      },
      changedFuturesOrderFormTrailingStopLevel1Result() {
        let percentage = ' [' + this.changedFuturesOrderTrailingStopForm.trailingStopLevel1 + '%]';
        if ('open_long' == this.changedFuturesOrderForm.side) {
          return this.formatNum((this.changedFuturesOrderForm.price * (1 - Number(this.changedFuturesOrderForm.trailingStopLevel1)))
            .toFixed(this.futuresDecimalNumbers[this.showChangedFuturesOrder.symbol])) + percentage;
        } else if ('open_short' == this.changedFuturesOrderForm.side) {
          return this.formatNum((this.changedFuturesOrderForm.price * (1 + Number(this.changedFuturesOrderForm.trailingStopLevel1)))
            .toFixed(this.futuresDecimalNumbers[this.showChangedFuturesOrder.symbol])) + percentage;
        } else {
          return '--';
        }
      },
      changedFuturesOrderFormTrailingStopLevel2Result() {
        let percentage = ' [' + this.changedFuturesOrderTrailingStopForm.trailingStopLevel2 + '%]';
        if ('open_long' == this.changedFuturesOrderForm.side) {
          return this.formatNum((this.changedFuturesOrderForm.price * (1 + Number(this.changedFuturesOrderForm.priceLevel2)) *
              (1 - Number(this.changedFuturesOrderForm.trailingStopLevel2))).toFixed(this.futuresDecimalNumbers[this.showChangedFuturesOrder.symbol])) +
            percentage;
        } else if ('open_short' == this.changedFuturesOrderForm.side) {
          return this.formatNum((this.changedFuturesOrderForm.price * (1 - Number(this.changedFuturesOrderForm.priceLevel2)) *
              (1 + Number(this.changedFuturesOrderForm.trailingStopLevel2))).toFixed(this.futuresDecimalNumbers[this.showChangedFuturesOrder.symbol])) +
            percentage;
        } else {
          return '--';
        }
      },
      changedFuturesOrderFormTrailingStopLevel3Result() {
        let percentage = ' [' + this.changedFuturesOrderTrailingStopForm.trailingStopLevel3 + '%]';
        if ('open_long' == this.changedFuturesOrderForm.side) {
          return this.formatNum((this.changedFuturesOrderForm.price * (1 + Number(this.changedFuturesOrderForm.priceLevel3)) *
              (1 - Number(this.changedFuturesOrderForm.trailingStopLevel3))).toFixed(this.futuresDecimalNumbers[this.showChangedFuturesOrder.symbol])) +
            percentage;
        } else if ('open_short' == this.changedFuturesOrderForm.side) {
          return this.formatNum((this.changedFuturesOrderForm.price * (1 - Number(this.changedFuturesOrderForm.priceLevel3)) *
              (1 + Number(this.changedFuturesOrderForm.trailingStopLevel3))).toFixed(this.futuresDecimalNumbers[this.showChangedFuturesOrder.symbol])) +
            percentage;
        } else {
          return '--';
        }
      },
      formPrice() {
        return this.orderForm.price
      },
      // 开仓所需的保证金
      requiredMargin(){
        if (this.leverage.marginMode == 'cross') {
          if (this.orderForm.side == 'open_long' || this.orderForm.side == 'open_short') {
            return Number((Number(this.orderForm.size * this.contractFaceValue[this.exchangeCode.toLowerCase()][this.orderForm.symbol]) / this.orderForm.price / Number(this.leverage.longLeverage)).toFixed(8));
          }
        } else if(this.leverage.marginMode == 'fixed') {
          if (this.orderForm.side == 'open_long') {
            return Number((Number(this.orderForm.size * this.contractFaceValue[this.exchangeCode.toLowerCase()][this.orderForm.symbol]) / this.orderForm.price / Number(this.leverage.longLeverage)).toFixed(8));
          } else if (this.orderForm.side == 'open_short') {
            return Number((Number(this.orderForm.size * this.contractFaceValue[this.exchangeCode.toLowerCase()][this.orderForm.symbol]) / this.orderForm.price / Number(this.leverage.shortLeverage)).toFixed(8));
          }
        }
        return 0;
      }
    },
    components: { KrakenFuturesMarketPrice, OkexFuturesMarketPrice, BinanceFuturesMarketPrice, AppIcon, AssetLine, AssetsTrendLineChart, AssetSummary, TradingRenkoChart, TradingCandleChart, TradingHeikinAshiChart, SwapCoinKillAndReverseAllDialog, SwapCoinKillAndReverseDialog, SwapCoinDeleteAllPendingEntryDialog, SwapCoinDeletePendingEntryDialog, SwapCoinDeletePositionOrderDialog, TradingTypeChange, ChangePercentage, SearchCoin },
    created() {
      this.$store.dispatch("tradingHeaderTitleHandler", 'futures');
      this.$store.dispatch("tradingTypePageHandler", this.$t("header.futures"));
      this.isLogin();
      this.judgeUserRole();
      let id = this.getExchangeId(this.exchangeCode);
      this.orderForm.exchangeId = id;
      this.orderForm.symbol = this.symbol;
      this.getLocalStorage();
    },
    beforeDestroy() {
      if (this.getOrdersTimers) {
        clearInterval(this.getOrdersTimers)
      }
    },
    mounted() {
      this.isExistPage();
      this.hasApiKey();
      this.getAccounts();
      this.getAllFuturesOrders();
      if (this.side == "open") {
        this.plan("1");
      };
      // 查询杠杆
      this.getLeverage();
      setTimeout(() => {
        if (this.orderForm.strategy == null && this.strategies.length > 0) {
          this.orderForm.strategy = this.strategies[0];
        }
        this.judgeExchangeSupportedFuturesSymbols();
      }, 1000);
      // 时间
      if ('UTCTime' == this.time) {
        this.currentTimeTimer = setInterval(() => {
          this.currentTime = formatUTCTime(new Date());
          this.currentTimestamp = +new Date();
        }, 1000);
      } else {
        this.currentTimeTimer = setInterval(() => {
          this.currentTime = formatTime(new Date());
          this.currentTimestamp = +new Date();
        }, 1000);
      }
      // 第一次加载更新RenkoChart
      this.$refs.tradingRenkoChart.refresh(this.getBaseCurrency(this.symbol));
      // 读取地址栏的参数
      if (this.querySide) {
        if (this.querySide.toLowerCase() == 'long') {
          this.changeOrderSide('buy');
        } else if (this.querySide.toLowerCase() == 'short') {
          this.changeOrderSide('sell');
        }
      }
      if (this.queryStrategy) {
        this.orderForm.strategy = this.queryStrategy;
      }
      if (this.queryPrice != null &&  this.queryPrice >= 0) {
        this.orderForm.price = this.queryPrice;
      }
    },
    watch: {
      // 如果交易对更改 buy的时候是ask价 sell的时候是bid价
      symbol(newVal, oldVal) {
        if (this.marketPrice != null && this.marketPrice[this.symbol] != null && this.marketPrice[this.symbol].ask !=
          null && this.marketPrice[this.symbol].bid != null) {
          "buy" == this.orderSide ? this.orderForm.price = this.marketPrice[this.symbol].ask.price : this.orderForm.price =
            this.marketPrice[this.symbol].bid.price;
        }
        if (this.takeProfitEnabled == true) {
          if ("open_long" == this.orderForm.side) {
            this.orderForm.takeProfitPrice = Number(this.orderForm.price * 1.25).toFixed(this.futuresDecimalNumbers[
              this.symbol])
          }
          if ("open_short" == this.orderForm.side) {
            this.orderForm.takeProfitPrice = Number(this.orderForm.price * 0.75).toFixed(this.futuresDecimalNumbers[
              this.symbol]);
          }
        }
        if (this.symbol) {
          this.orderSearch.symbol = this.symbol;
        }
        // 查询杠杆
        this.getLeverage();
        // this.openPositionsOrdersSymbolSearch = this.symbol;
      },
      // 更改订单方向 buy的时候是ask价 sell的时候是bid价
      orderSide(newVal, oldVal) {
        if (newVal != oldVal) {
          if (this.marketPrice != null && this.marketPrice[this.symbol] != null) {
            if ("buy" == newVal) {
              this.orderForm.price = this.marketPrice[this.symbol].ask.price
            } else {
              this.orderForm.price = this.marketPrice[this.symbol].bid.price;
            }
          }
        }
        if (this.takeProfitEnabled == true) {
          if ("open_long" == this.orderForm.side) {
            this.orderForm.takeProfitPrice = Number(this.orderForm.price * 1.25).toFixed(this.futuresDecimalNumbers[
              this.symbol])
          }
          if ("open_short" == this.orderForm.side) {
            this.orderForm.takeProfitPrice = Number(this.orderForm.price * 0.75).toFixed(this.futuresDecimalNumbers[
              this.symbol]);
          }
        }
      },
      // 如果监听不为空,并且订单的价格为0 buy的时候是ask价 sell的时候是bid价
      marketPrice(v) {
        if (v != null && this.priceIsChange && this.orderForm.price == 0 && this.marketPrice != null && this.marketPrice[
            this.symbol] != null && this.marketPrice[this.symbol].bid != null && this.marketPrice[this.symbol].ask !=
          null) {
          "buy" == this.orderSide ? this.orderForm.price = this.marketPrice[this.symbol].ask.price : this.orderForm.price =
            this.marketPrice[this.symbol].bid.price;
          this.priceIsChange = false;
        }
        if (v != null && this.marketPrice != null && this.marketPrice[this.symbol] != null && this.marketPrice[this.symbol].current != null) {
          let title = '$' + this.formatNum(this.marketPrice[this.symbol].current.price) + ' | ' + this.symbol + ' | ' + this.$t('common.documentTitle');
          this.$store.dispatch('documentTitleHandler', title);
        }
      },
      // 如果交易所代码发生变化,将表单的值清空并重新根据市场价取值
      exchangeCode(v) {
        if (v) {
          let id = this.getExchangeId(v);
          this.orderForm.exchangeId = id;
          this.gotoExchange(v);
          this.hasApiKey();
          this.getAccounts();
          this.orderForm.price = 0;
          this.priceIsChange = true;
          // 查询杠杆
          this.getLeverage();
        }
      },
      $route() {
        this.symbol = this.$route.params.symbol;
        this.exchangeCode = this.$route.params.exchangeCode;
      },
      takeProfitEnabled(val) {
        if (val == false) {
          this.orderForm.takeProfitPrice = 0;
          this.takeProfitString = '0.0';
        }
        if (val == true) {
          if ("open_long" == this.orderForm.side) {
            this.orderForm.takeProfitPrice = Number(this.orderForm.price * 1.25).toFixed(this.futuresDecimalNumbers[
              this.symbol])
          }
          if ("open_short" == this.orderForm.side) {
            this.orderForm.takeProfitPrice = Number(this.orderForm.price * 0.75).toFixed(this.futuresDecimalNumbers[
              this.symbol]);
          }
        }
      },
      stopLossEnabled(val) {
        if (val == false) {
          this.stopLossString = '0.0';
        }
      },
      // 监听全屏更换分页数量
      fullScreen(newVal, oldVal) {
        if (newVal) {
          this.pageSize = 30;
          this.openPositionsAtExchangesPagination.rowsPerPage = 30;
        } else {
          this.pageSize = 10;
          this.openPositionsAtExchangesPagination.rowsPerPage = 10;
        }
        this.getAllFuturesOrders();
      },
      openPositionsExchangeSearch(val) {
        this.openPositionsAtExchanges = [];
        this.getOpenPositionsAtExchanges();
      },
      openPositionsSymbolSearch(val) {
        this.openPositionsAtExchanges = [];
        this.getOpenPositionsAtExchanges();
      },
      orderForm: {
        handler(newObject, oldObject) {
          this.orderForm.price = Number(this.orderForm.price);
          this.orderForm.size = Number(this.orderForm.size);
          // this.orderForm.takeProfitPrice = Number(this.orderForm.takeProfitPrice);
          if (this.takeProfitEnabled == true && this.orderForm.takeProfitPrice == 0 && this.formProfitPriceCount == 0) {
            this.formProfitPriceCount++;
            if ("open_long" == this.orderForm.side) {
              this.orderForm.takeProfitPrice = Number(this.orderForm.price * 1.25).toFixed(this.futuresDecimalNumbers[
                this.symbol])
            }
            if ("open_short" == this.orderForm.side) {
              this.orderForm.takeProfitPrice = Number(this.orderForm.price * 0.75).toFixed(this.futuresDecimalNumbers[
                this.symbol]);
            }
          }
        },
        immediate: true,
        deep: true
      },
      // 只监听下单的策略
      // 'orderForm.strategy': {
      //    handler: function(newVal, oldVal) {
      //     if (oldVal != null && newVal != null) {
      //       this.orderSearch.strategy = this.orderForm.strategy;
      //     }
      //    },
      //    immediate: false
      // },
      // 监听订单搜索
      orderSearch: {
        handler(newObject, oldObject) {
          this.pendingEntryOrdersPageNo = 1;
          this.pendingExitOrdersPageNo = 1;
          this.submittedOrdersPageNo = 1;
          this.unfilledExchangeOrdersPageNo = 1;
          this.filledExchangeOrdersPageNo = 1;
          this.openPositionsOrdersPageNo = 1;
          this.getAllFuturesOrders();
          // 切换为该交易对
          this.gotoFuturesOrder(this.orderSearch.symbol);
        },
        immediate: true,
        deep: true
      },
      // 只监听订单搜索的策略
      // 'orderSearch.strategy': {
      //    handler: function() {
      //     if (this.orderSearch.strategy) {
      //       this.orderForm.strategy = this.orderSearch.strategy;
      //     }
      //    },
      // },
      webSocketDataIndex(newVal, oldVal) {
        if (this.webSocketData) {
          this.getWebSocketData();
        }
      },
      formPrice(newVal, oldVal) {
        if (this.takeProfitEnabled == true && newVal != oldVal) {
          if ("open_long" == this.orderForm.side) {
            this.orderForm.takeProfitPrice = Number(this.orderForm.price * 1.25).toFixed(this.futuresDecimalNumbers[
              this.symbol])
          }
          if ("open_short" == this.orderForm.side) {
            this.orderForm.takeProfitPrice = Number(this.orderForm.price * 0.75).toFixed(this.futuresDecimalNumbers[
              this.symbol]);
          }
        }
      },
      trailingStopLevel1(newVal, oldValue) {
        if (newVal < 0 || newVal > 100) {
          this.trailingStopLevel1 = 0;
          this.$store.dispatch('snackbarMessageHandler', 'Invalid parameter: ' + newVal + ' %');
        } else {
          this.trailingStopLevel1 = Number(newVal);
        }
        this.orderForm.trailingStopLevel1 = newVal / 100;
        this.$forceUpdate();
      },
      trailingStopLevel2(newVal) {
        if (newVal < 0 || newVal > 100) {
          this.trailingStopLevel2 = 0;
          this.$store.dispatch('snackbarMessageHandler', 'Invalid parameter: ' + newVal + ' %');
        } else {
          this.trailingStopLevel2 = Number(newVal);
        }
        this.orderForm.trailingStopLevel2 = newVal / 100;
        this.$forceUpdate();
      },
      trailingStopLevel3(newVal) {
        if (newVal < 0 || newVal > 100) {
          this.trailingStopLevel3 = 0;
          this.$store.dispatch('snackbarMessageHandler', 'Invalid parameter: ' + newVal + ' %');
        } else {
          this.trailingStopLevel3 = Number(newVal);
        }
        this.orderForm.trailingStopLevel3 = newVal / 100;
        this.$forceUpdate();
      },
      priceLevel2(newVal) {
        if (newVal < 0 || newVal > 100) {
          this.priceLevel2 = 0;
          this.$store.dispatch('snackbarMessageHandler', 'Invalid parameter: ' + newVal + ' %');
        } else {
          this.priceLevel2 = Number(newVal);
        }
        this.orderForm.priceLevel2 = newVal / 100;
        this.$forceUpdate();
      },
      priceLevel3(newVal) {
        if (newVal < 0 || newVal > 100) {
          this.priceLevel3 = 0;
          this.$store.dispatch('snackbarMessageHandler', 'Invalid parameter: ' + newVal + ' %');
        } else {
          this.priceLevel3 = Number(newVal);
        }
        this.orderForm.priceLevel3 = newVal / 100;
        this.$forceUpdate();
      },
      // changedFuturesOrderForm: {
      //   handler(newObject, oldObject) {
      //     this.changedFuturesOrderTrailingStopForm.trailingStopLevel1 = Number(Number(newObject.trailingStopLevel1) * 100).toFixed(2);
      //     this.changedFuturesOrderTrailingStopForm.trailingStopLevel2 = Number(Number(newObject.trailingStopLevel2) * 100).toFixed(2);
      //     this.changedFuturesOrderTrailingStopForm.trailingStopLevel3 = Number(Number(newObject.trailingStopLevel3) * 100).toFixed(2);
      //     this.changedFuturesOrderTrailingStopForm.priceLevel2 = Number(Number(newObject.priceLevel2) * 100).toFixed(2);
      //     this.changedFuturesOrderTrailingStopForm.priceLevel3 = Number(Number(newObject.priceLevel3) * 100).toFixed(2);
      //   },
      //   immediate: true,
      //   deep: true
      // },
      // changedFuturesOrderTrailingStopForm: {
      //     handler(newObject, oldObject) {
      //       this.changedFuturesOrderForm.trailingStopLevel1 = Number(Number(newObject.trailingStopLevel1) / 100).toFixed(4);
      //       this.changedFuturesOrderForm.trailingStopLevel2 = Number(Number(newObject.trailingStopLevel2) / 100).toFixed(4);
      //       this.changedFuturesOrderForm.trailingStopLevel3 = Number(Number(newObject.trailingStopLevel3) / 100).toFixed(4);
      //       this.changedFuturesOrderForm.priceLevel2 = Number(Number(newObject.priceLevel2) / 100).toFixed(4);
      //       this.changedFuturesOrderForm.priceLevel3 = Number(Number(newObject.priceLevel3) / 100).toFixed(4);
      //     },
      //     immediate: true,
      //     deep: true
      // },
      // 监听止损比例方案
      planVal(newVal, oldVal) {
        if (newVal != oldVal) {
          this.plan(newVal)
        }
      },
      isEditingFuturesOrder(val) {
        this.changedFuturesOrderForm.trailingStopLevel1 = Number(Number(Number(this.changedFuturesOrderTrailingStopForm
          .trailingStopLevel1).toFixed(4)) / 100).toFixed(4);
        this.changedFuturesOrderForm.trailingStopLevel2 = Number(Number(Number(this.changedFuturesOrderTrailingStopForm
          .trailingStopLevel2).toFixed(4)) / 100).toFixed(4);
        this.changedFuturesOrderForm.trailingStopLevel3 = Number(Number(Number(this.changedFuturesOrderTrailingStopForm
          .trailingStopLevel3).toFixed(4)) / 100).toFixed(4);
        this.changedFuturesOrderForm.priceLevel2 = Number(Number(Number(this.changedFuturesOrderTrailingStopForm.priceLevel2)
          .toFixed(4)) / 100).toFixed(4);
        this.changedFuturesOrderForm.priceLevel3 = Number(Number(Number(this.changedFuturesOrderTrailingStopForm.priceLevel3)
          .toFixed(4)) / 100).toFixed(4);

        this.changedFuturesOrderTrailingStopForm.trailingStopLevel1 = (Number(this.changedFuturesOrderForm.trailingStopLevel1) *
          100).toFixed(2);
        this.changedFuturesOrderTrailingStopForm.trailingStopLevel2 = (Number(this.changedFuturesOrderForm.trailingStopLevel2) *
          100).toFixed(2);
        this.changedFuturesOrderTrailingStopForm.trailingStopLevel3 = (Number(this.changedFuturesOrderForm.trailingStopLevel3) *
          100).toFixed(2);
        this.changedFuturesOrderTrailingStopForm.priceLevel2 = (Number(this.changedFuturesOrderForm.priceLevel2) * 100)
          .toFixed(2);
        this.changedFuturesOrderTrailingStopForm.priceLevel3 = (Number(this.changedFuturesOrderForm.priceLevel3) * 100)
          .toFixed(2);
        //  changedFuturesOrderForm: {
        //   handler(newObject, oldObject) {
        //     this.changedFuturesOrderTrailingStopForm.trailingStopLevel1 = Number(Number(newObject.trailingStopLevel1) * 100).toFixed(2);
        //     this.changedFuturesOrderTrailingStopForm.trailingStopLevel2 = Number(Number(newObject.trailingStopLevel2) * 100).toFixed(2);
        //     this.changedFuturesOrderTrailingStopForm.trailingStopLevel3 = Number(Number(newObject.trailingStopLevel3) * 100).toFixed(2);
        //     this.changedFuturesOrderTrailingStopForm.priceLevel2 = Number(Number(newObject.priceLevel2) * 100).toFixed(2);
        //     this.changedFuturesOrderTrailingStopForm.priceLevel3 = Number(Number(newObject.priceLevel3) * 100).toFixed(2);
        //   },
        //   immediate: true,
        //   deep: true
        // },
        // changedFuturesOrderTrailingStopForm: {
        //     handler(newObject, oldObject) {
        //       this.changedFuturesOrderForm.trailingStopLevel1 = Number(Number(newObject.trailingStopLevel1) / 100).toFixed(4);
        //       this.changedFuturesOrderForm.trailingStopLevel2 = Number(Number(newObject.trailingStopLevel2) / 100).toFixed(4);
        //       this.changedFuturesOrderForm.trailingStopLevel3 = Number(Number(newObject.trailingStopLevel3) / 100).toFixed(4);
        //       this.changedFuturesOrderForm.priceLevel2 = Number(Number(newObject.priceLevel2) / 100).toFixed(4);
        //       this.changedFuturesOrderForm.priceLevel3 = Number(Number(newObject.priceLevel3) / 100).toFixed(4);
        //     },
        //     immediate: true,
        //     deep: true
        // },
      },
      networkStatus(newVal, oldVal) {
        // 网络重新连接时，刷新订单和账户
        if (newVal) {
          this.pageRefreshDateTime = formatISO8601Time(+new Date());
          this.getAccounts();
          this.getPendingEntryOrdersByPages(this.pendingEntryOrdersPageNo, false);
          this.getPendingExitOrdersByPages(this.pendingExitOrdersPageNo, false);
          this.getSubmittedOrdersByPages(this.submittedOrdersPageNo, false);
          this.getUnfilledExchangeOrdersByPages(this.unfilledExchangeOrdersPageNo, false);
          this.getFilledExchangeOrdersByPages(this.filledExchangeOrdersPageNo, false);
          this.getOpenPositionsOrdersByPages(this.openPositionsOrdersPageNo, false);
          this.getOpenPositionsAtExchanges();
        }
      },
      hideNonePositions(newVal, oldVal) {
        localStorage.setItem('iobots-hideFuturesNonePositions', newVal ? 1 : 0);
      },
      childComponent(newVal, oldVal) {
        localStorage.setItem('iobots-futuresChildComponent', newVal);
      }
    },
    methods: {
      hint(message) {
        this.$store.dispatch('snackbarMessageHandler', message);
      },
      // 是否存在该链接
      isExistPage() {
        let existExchangeCode = isExistExchangeCode(this.exchanges, this.exchangeCode);
        if (!existExchangeCode) {
          this.$router.push({
            name: 'page404'
          })
        }
        let existSymbol = isExistSymbol(this.futuresSymbols, this.symbol);
        if (!existSymbol) {
          this.$router.push({
            name: 'page404'
          })
        }
      },
      // 是否登录
      isLogin() {
        if (!this.user.token) {
          this.$router.push({
            name: 'home'
          });
        }
      },
      // 判断用户角色
      judgeUserRole() {
        if (this.user != null && this.user.role != 'TRADER') {
          this.$store.dispatch('snackbarMessageHandler', this.$t("common.notTrader"));
          this.$router.push({
            name: 'home'
          })
        }
      },
      // 判断交易所支持的交易对
      judgeExchangeSupportedFuturesSymbols() {
        if (this.exchangeSupportedFuturesSymbols != [] && this.exchangeSupportedFuturesSymbols[this.exchangeCode].length >
          0) {
          let symbols = this.exchangeSupportedFuturesSymbols[this.exchangeCode];
          for (let i = 0, len = symbols.length; i < len; i++) {
            if (symbols[i].symbol == this.symbol && symbols[i].supported == false) {
              this.symbol = 'PI_BTCUSD';
              this.$router.push({
                name: "futuresTrading",
                params: {
                  exchangeCode: this.exchangeCode,
                  symbol: this.symbol
                }
              });
            }
          }

        }
      },
      // 刷新所有订单
      refreshAllFuturesOrders() {
        if (!this.fullScreen) {
          // 不是全屏时候刷新摘要
          this.$refs.summary.refresh();
        }
        this.pendingEntryOrders = [],
        this.pendingExitOrders = [],
        this.submittedOrders = [],
        this.unfilledExchangeOrders = [],
        this.filledExchangeOrders = [],
        this.openPositionsOrders = [],
        this.openPositionsAtExchanges = [],
        this.getAllFuturesOrders();
      },
      // 查询所有订单
      getAllFuturesOrders() {
        this.pageRefreshDateTime = formatISO8601Time(+new Date());
        this.getAccounts();
        this.queryPendingEntryOrdersByPages(this.pendingEntryOrdersPageNo);
        this.queryPendingExitOrdersByPages(this.pendingExitOrdersPageNo);
        this.querySubmittedOrdersByPages(this.submittedOrdersPageNo);
        this.queryUnfilledExchangeOrdersByPages(this.unfilledExchangeOrdersPageNo);
        this.queryFilledExchangeOrdersByPages(this.filledExchangeOrdersPageNo);
        this.queryOpenPositionsOrdersByPages(this.openPositionsOrdersPageNo);
        this.getOpenPositionsAtExchanges();
        this.pendingEntryOrdersTotalElements = 0;
        this.pendingExitOrdersTotalElements = 0;
        this.submittedOrdersTotalElements = 0;
        this.unfilledExchangeOrdersTotalElements = 0;
        this.filledExchangeOrdersTotalElements = 0;
        this.openPositionsOrdersTotalElements = 0;
      },
      gotoFuturesOrder(symbol) {
        if (symbol) {
          let code = this.getExchangeCode(this.orderForm.exchangeId);
          this.orderForm.symbol = symbol;
          this.$router.push({
            name: 'futuresTrading',
            params: {
              exchangeCode: code,
              symbol: symbol
            }
          });
        }
      },
      // 选择交易所
      changeExchange(exchangeId) {
        let exchangeCode = null;
        for (let i = 0; i < this.exchanges.length; i++) {
          if (exchangeId == this.exchanges[i].id) {
            exchangeCode = this.exchanges[i].code;
            this.exchangeCode = exchangeCode;
            break;
          }
        }
      },
      // 跳转到指定的交易所链接
      gotoExchange(exchangeCode) {
        this.judgeExchangeSupportedFuturesSymbols();
        this.$router.push({
          name: "futuresTrading",
          params: {
            exchangeCode: exchangeCode,
            symbol: this.symbol
          }
        });
      },
      // 跳转到信号页面
      gotoMoreSignals() {
        // this.$router.push({
        //   name: 'moreSignals'
        // });
        this.$router.push({ name: 'home' });
      },
      // NextOrder3
      gotoNextOrder3() {
        this.nextOrder = "next3";
        this.cancelDialog();
      },
      // 改变方向
      changeSide(side) {
        // open/close
        this.side = side;
        this.buttonSide = "open" == side ? ("buy" == this.orderSide ? "long" : "short") : this.orderSide;
        this.orderForm.side = this.side + '_' + this.buttonSide;
        if (side == "close") {
          this.planVal = "0";
        } else {
          this.planVal = "1";
        }
      },
      // 改变订单方向
      changeOrderSide(orderSide) {
        // buy/sell
        this.orderSide = orderSide;
        this.buttonSide = "open" == this.side ? ("buy" == orderSide ? "long" : "short") : this.orderSide;
        this.orderForm.side = this.side + '_' + this.buttonSide;
      },
      // 提交订单
      submitOrder() {
        this.formProfitPriceCount = 0;
        // 发送下单请求
        this.submittedOrderFormLoading = true;
        let api = config.baseUrl + '/order/futures/private/order';
        this.orderForm.sendOrderStartTimestamp = parseISO8601ToTimestamp(this.orderForm.sendOrderStartDatetime);
        this.$http.post(api, this.orderForm).then(response => {
          let data = response.data;
          this.$store.dispatch('snackbarMessageHandler', data.message);
          this.cancelLoading();
          if (200 == data.code) {
            let exchangeId = getExchangeIdByCode(this.exchanges, this.exchangeCode);
            this.orderForm.exchangeId = exchangeId;
            this.orderForm.orderType = "limit";
            this.orderForm.symbol = this.symbol;
            if (this.marketPrice != null && this.marketPrice[this.symbol] != null && this.marketPrice[this.symbol].ask !=
              null && this.marketPrice[this.symbol].bid != null) {
              if ("buy" == this.orderSide) {
                this.orderForm.price = this.marketPrice[this.symbol].ask.price
              } else {
                this.orderForm.price = this.marketPrice[this.symbol].bid.price;
              }
            }
            this.orderForm.size = 0;
            this.pendingEntryOrdersPageNo = 1;
            this.takeProfitEnabled = true;
            this.orderForm.takeProfitPrice = 0;
            // this.getAllFuturesOrders();
            this.cancelLoading();
            this.nextOrder = "next1";
            this.submittedOrderFormLoading = false;
          }
        }, error => {
          this.cancelLoading();
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
          this.submittedOrderFormLoading = false;
        });
      },
      //分页查询放置的进入订单
      getPendingEntryOrdersByPages(pendingEntryOrdersPageNo, isNeedLoading) {
        if (isNeedLoading) {
          this.pendingEntryLoading = true;
          this.pendingEntryOrders = [];
        }
        let api = config.baseUrl + '/order/futures/private/orders/pendingEntry?pageNo=' + (pendingEntryOrdersPageNo - 1) +
          "&pageSize=" + this.pageSize;
        if (this.orderSearch.exchange) {
          api = api + '&exchange=' + this.orderSearch.exchange;
        }
        if (this.orderSearch.symbol) {
          api = api + '&symbol=' + this.orderSearch.symbol;
        }
        if (this.orderSearch.side) {
          api = api + '&side=' + this.orderSearch.side;
        }
        if (this.orderSearch.strategy) {
          api = api + '&strategy=' + this.orderSearch.strategy;
        }
        if (this.orderSearch.category) {
          api = api + '&category=' + this.orderSearch.category;
        }
        this.$http.get(api).then(response => {
          let data = response.data;
          if (data.code == 200) {
            this.pendingEntryOrders = data.result.orderList;
            let notes = data.result.orderNotes;
            // 将交易所id转为名字
            for (let i = 0, iLength = this.pendingEntryOrders.length; i < iLength; i++) {
              this.pendingEntryOrders[i].exchangeName = this.getExchangeNameById(this.pendingEntryOrders[i].exchangeId);
              switch (this.pendingEntryOrders[i].category) {
                case 'IOB':
                  this.pendingEntryOrders[i].bgColor = 'palered--text';
                  break;
                case 'TA':
                  this.pendingEntryOrders[i].bgColor = 'palegreen--text';
                  break;
                case 'MR':
                  this.pendingEntryOrders[i].bgColor = 'paleyellow--text';
                  break;
                case 'TP':
                  this.pendingEntryOrders[i].bgColor = 'red--text';
                  break;
                default:
                  this.pendingEntryOrders[i].bgColor = '';
                  break;
              }
              // for (let j = 0, jLength = notes.length; j < jLength; j++) {
              //   if(this.pendingEntryOrders[i].id == notes[j].orderId){
              //     this.pendingEntryOrders[i].note = notes[j].note;
              //     switch (notes[j].note){
              //       case 'IOB': this.pendingEntryOrders[i].bgColor = 'palered--text'; break;
              //       case 'TA': this.pendingEntryOrders[i].bgColor = 'palegreen--text'; break;
              //       case 'MR': this.pendingEntryOrders[i].bgColor = 'paleyellow--text'; break;
              //       case 'TP': this.pendingEntryOrders[i].bgColor = 'red--text'; break;
              //       default: break;
              //     }
              //   }
              // }
            }
            this.pendingEntryOrdersTotalElements = data.result.totalElements;
            this.pendingEntryOrdersTotalPage = Math.ceil(data.result.totalElements / this.pageSize);
            this.pendingEntryLoading = false;
          } else {
            this.pendingEntryOrders = [];
            this.pendingEntryOrdersTotalElements = 0;
            this.pendingEntryLoading = false;
          }
        });
      },
      //分页查询放置的退出订单
      getPendingExitOrdersByPages(pendingExitOrdersPageNo, isNeedLoading) {
        if (isNeedLoading) {
          this.pendingExitLoading = true;
          this.pendingExitOrders = [];
        }
        let api = config.baseUrl + '/order/futures/private/orders/pendingExit?pageNo=' + (pendingExitOrdersPageNo - 1) +
          "&pageSize=" + this.pageSize;
        if (this.orderSearch.exchange) {
          api = api + '&exchange=' + this.orderSearch.exchange;
        }
        if (this.orderSearch.symbol) {
          api = api + '&symbol=' + this.orderSearch.symbol;
        }
        if (this.orderSearch.side) {
          api = api + '&side=' + this.orderSearch.side;
        }
        if (this.orderSearch.strategy) {
          api = api + '&strategy=' + this.orderSearch.strategy;
        }
        if (this.orderSearch.category) {
          api = api + '&category=' + this.orderSearch.category;
        }
        this.$http.get(api).then(response => {
          let data = response.data;
          if (data.code == 200) {
            this.pendingExitOrders = data.result.orderList;
            let notes = data.result.orderNotes;
            // 将交易所id转为名字
            for (let i = 0, iLength = this.pendingExitOrders.length; i < iLength; i++) {
              this.pendingExitOrders[i].exchangeName = this.getExchangeNameById(this.pendingExitOrders[i].exchangeId);
              switch (this.pendingExitOrders[i].category) {
                case 'IOB':
                  this.pendingExitOrders[i].bgColor = 'palered--text';
                  break;
                case 'TA':
                  this.pendingExitOrders[i].bgColor = 'palegreen--text';
                  break;
                case 'MR':
                  this.pendingExitOrders[i].bgColor = 'paleyellow--text';
                  break;
                case 'TP':
                  this.pendingExitOrders[i].bgColor = 'red--text';
                  break;
                default:
                  this.pendingExitOrders[i].bgColor = '';
                  break;
              }
              // for (let j = 0, jLength = notes.length; j < jLength; j++) {
              //   if(this.pendingExitOrders[i].id == notes[j].orderId){
              //     this.pendingExitOrders[i].note = notes[j].note;
              //     switch (notes[j].note){
              //       case 'IOB': this.pendingExitOrders[i].bgColor = 'palered--text'; break;
              //       case 'TA': this.pendingExitOrders[i].bgColor = 'palegreen--text'; break;
              //       case 'MR': this.pendingExitOrders[i].bgColor = 'paleyellow--text'; break;
              //       case 'TP': this.pendingExitOrders[i].bgColor = 'red--text'; break;
              //       default: break;
              //     }
              //   }
              // }
            }
            this.pendingExitOrdersTotalElements = data.result.totalElements;
            this.pendingExitOrdersTotalPage = Math.ceil(data.result.totalElements / this.pageSize);
            this.pendingExitLoading = false;
          } else {
            this.pendingExitOrders = [];
            this.pendingExitOrdersTotalElements = 0;
            this.pendingExitLoading = false;
          }
        });
      },
      //分页查询已提交至交易所的订单
      getSubmittedOrdersByPages(submittedOrdersPageNo, isNeedLoading) {
        if (isNeedLoading) {
          this.submittedExchangeOrdersLoading = true;
          this.submittedOrders = [];
        }
        let api = config.baseUrl + '/order/futures/private/orders/submitted?pageNo=' + (submittedOrdersPageNo - 1) +
          "&pageSize=" + this.pageSize;
        if (this.orderSearch.exchange) {
          api = api + '&exchange=' + this.orderSearch.exchange;
        }
        if (this.orderSearch.symbol) {
          api = api + '&symbol=' + this.orderSearch.symbol;
        }
        if (this.orderSearch.side) {
          api = api + '&side=' + this.orderSearch.side;
        }
        if (this.orderSearch.strategy) {
          api = api + '&strategy=' + this.orderSearch.strategy;
        }
        if (this.orderSearch.category) {
          api = api + '&category=' + this.orderSearch.category;
        }
        this.$http.get(api).then(response => {
          let data = response.data;
          if (data.code == 200) {
            this.submittedOrders = data.result.orderList;
            this.submittedExchangeOrdersLoading = false;
            let notes = data.result.orderNotes;
            // 将交易所id转为名字
            for (let i = 0, iLength = this.submittedOrders.length; i < iLength; i++) {
              this.submittedOrders[i].exchangeName = this.getExchangeNameById(this.submittedOrders[i].exchangeId);
              switch (this.submittedOrders[i].category) {
                case 'IOB':
                  this.submittedOrders[i].bgColor = 'palered--text';
                  break;
                case 'TA':
                  this.submittedOrders[i].bgColor = 'palegreen--text';
                  break;
                case 'MR':
                  this.submittedOrders[i].bgColor = 'paleyellow--text';
                  break;
                case 'TP':
                  this.submittedOrders[i].bgColor = 'red--text';
                  break;
                default:
                  this.submittedOrders[i].bgColor = '';
                  break;
              }
              // for (let j = 0, jLength = notes.length; j < jLength; j++) {
              //   if(this.submittedOrders[i].id == notes[j].orderId){
              //     this.submittedOrders[i].note = notes[j].note;
              //     switch (notes[j].note){
              //       case 'IOB': this.submittedOrders[i].bgColor = 'palered--text'; break;
              //       case 'TA': this.submittedOrders[i].bgColor = 'palegreen--text'; break;
              //       case 'MR': this.submittedOrders[i].bgColor = 'paleyellow--text'; break;
              //       case 'TP': this.submittedOrders[i].bgColor = 'red--text'; break;
              //       default: break;
              //     }
              //   }
              // }
            }
            this.submittedOrdersTotalElements = data.result.totalElements;
            this.submittedOrdersTotalPage = Math.ceil(data.result.totalElements / this.pageSize);
          } else {
            this.submittedOrders = [];
            this.submittedOrdersTotalElements = 0;
            this.submittedExchangeOrdersLoading = false;
          }
        });
      },
      //分页查询在交易所未成交的订单
      getUnfilledExchangeOrdersByPages(unfilledExchangeOrdersPageNo, isNeedLoading) {
        if (isNeedLoading) {
          this.unfilledAtExchangesLoading = true;
          this.unfilledExchangeOrders = [];
        }
        let api = config.baseUrl + '/order/futures/private/orders/unfilled?pageNo=' + (unfilledExchangeOrdersPageNo - 1) +
          "&pageSize=" + this.pageSize;
        if (this.orderSearch.exchange) {
          api = api + '&exchange=' + this.orderSearch.exchange;
        }
        if (this.orderSearch.symbol) {
          api = api + '&symbol=' + this.orderSearch.symbol;
        }
        if (this.orderSearch.side) {
          api = api + '&side=' + this.orderSearch.side;
        }
        if (this.orderSearch.strategy) {
          api = api + '&strategy=' + this.orderSearch.strategy;
        }
        if (this.orderSearch.category) {
          api = api + '&category=' + this.orderSearch.category;
        }
        this.$http.get(api).then(response => {
          let data = response.data;
          if (data.code == 200) {
            this.unfilledExchangeOrders = data.result.orderList;
            let notes = data.result.orderNotes;
            // 将交易所id转为名字
            for (let i = 0, iLength = this.unfilledExchangeOrders.length; i < iLength; i++) {
              this.unfilledExchangeOrders[i].exchangeName = this.getExchangeNameById(this.unfilledExchangeOrders[i]
                .exchangeId);
              switch (this.unfilledExchangeOrders[i].category) {
                case 'IOB':
                  this.unfilledExchangeOrders[i].bgColor = 'palered--text';
                  break;
                case 'TA':
                  this.unfilledExchangeOrders[i].bgColor = 'palegreen--text';
                  break;
                case 'MR':
                  this.unfilledExchangeOrders[i].bgColor = 'paleyellow--text';
                  break;
                case 'TP':
                  this.unfilledExchangeOrders[i].bgColor = 'red--text';
                  break;
                default:
                  this.unfilledExchangeOrders[i].bgColor = '';
                  break;
              }
              // for (let j = 0, jLength = notes.length; j < jLength; j++) {
              //   if(this.unfilledExchangeOrders[i].id == notes[j].orderId){
              //     this.unfilledExchangeOrders[i].note = notes[j].note;
              //     switch (notes[j].note){
              //       case 'IOB': this.unfilledExchangeOrders[i].bgColor = 'palered--text'; break;
              //       case 'TA': this.unfilledExchangeOrders[i].bgColor = 'palegreen--text'; break;
              //       case 'MR': this.unfilledExchangeOrders[i].bgColor = 'paleyellow--text'; break;
              //       case 'TP': this.unfilledExchangeOrders[i].bgColor = 'red--text'; break;
              //       default: break;
              //     }
              //   }
              // }
            }
            this.unfilledExchangeOrdersTotalElements = data.result.totalElements;
            this.unfilledExchangeOrdersTotalPage = Math.ceil(data.result.totalElements / this.pageSize);
            this.unfilledAtExchangesLoading = false;
          } else {
            this.unfilledExchangeOrders = [];
            this.unfilledExchangeOrdersTotalElements = 0;
            this.unfilledAtExchangesLoading = false;
          }
        });
      },
      //分页查询在交易所已成交的订单
      getFilledExchangeOrdersByPages(filledExchangeOrdersPageNo, isNeedLoading) {
        if (isNeedLoading) {
          this.filledExchangeOrdersLoading = true;
          this.filledExchangeOrders = [];
        }
        let api = config.baseUrl + '/order/futures/private/orders/filled?pageNo=' + (filledExchangeOrdersPageNo - 1) +
          "&pageSize=" + this.pageSize;
        if (this.orderSearch.exchange) {
          api = api + '&exchange=' + this.orderSearch.exchange;
        }
        if (this.orderSearch.symbol) {
          api = api + '&symbol=' + this.orderSearch.symbol;
        }
        if (this.orderSearch.side) {
          api = api + '&side=' + this.orderSearch.side;
        }
        if (this.orderSearch.strategy) {
          api = api + '&strategy=' + this.orderSearch.strategy;
        }
        if (this.orderSearch.category) {
          api = api + '&category=' + this.orderSearch.category;
        }
        this.$http.get(api).then(response => {
          let data = response.data;
          if (data.code == 200) {
            this.filledExchangeOrders = data.result.orderList;
            this.filledExchangeOrdersLoading = false;
            let notes = data.result.orderNotes;
            // 将交易所id转为名字
            for (let i = 0, iLength = this.filledExchangeOrders.length; i < iLength; i++) {
              this.filledExchangeOrders[i].exchangeName = this.getExchangeNameById(this.filledExchangeOrders[i].exchangeId);
              switch (this.filledExchangeOrders[i].category) {
                case 'IOB':
                  this.filledExchangeOrders[i].bgColor = 'palered--text';
                  break;
                case 'TA':
                  this.filledExchangeOrders[i].bgColor = 'palegreen--text';
                  break;
                case 'MR':
                  this.filledExchangeOrders[i].bgColor = 'paleyellow--text';
                  break;
                case 'TP':
                  this.filledExchangeOrders[i].bgColor = 'red--text';
                  break;
                default:
                  this.filledExchangeOrders[i].bgColor = '';
                  break;
              }
              // for (let j = 0, jLength = notes.length; j < jLength; j++) {
              //   if(this.filledExchangeOrders[i].id == notes[j].orderId){
              //     this.filledExchangeOrders[i].note = notes[j].note;
              //     switch (notes[j].note){
              //       case 'IOB': this.filledExchangeOrders[i].bgColor = 'palered--text'; break;
              //       case 'TA': this.filledExchangeOrders[i].bgColor = 'palegreen--text'; break;
              //       case 'MR': this.filledExchangeOrders[i].bgColor = 'paleyellow--text'; break;
              //       case 'TP': this.filledExchangeOrders[i].bgColor = 'red--text'; break;
              //       default: break;
              //     }
              //   }
              // }
            }
            this.filledExchangeOrdersTotalElements = data.result.totalElements;
            this.filledExchangeOrdersTotalPage = Math.ceil(data.result.totalElements / this.pageSize);
          } else {
            this.filledExchangeOrders = [];
            this.filledExchangeOrdersTotalElements = 0;
            this.filledExchangeOrdersLoading = false;
          }
        });
      },
      //分页查询在交易所持仓的订单
      getOpenPositionsOrdersByPages(openPositionsOrdersPageNo, isNeedLoading) {
        if (isNeedLoading) {
          this.openPositionsLoading = true;
          this.openPositionsOrders = [];
        }
        let api = config.baseUrl + '/order/futures/private/orders/openPositions?pageNo=' + (openPositionsOrdersPageNo -
          1) + "&pageSize=" + this.pageSize;
        if (this.orderSearch.exchange) {
          api = api + '&exchange=' + this.orderSearch.exchange;
        }
        if (this.orderSearch.symbol) {
          api = api + '&symbol=' + this.orderSearch.symbol;
        }
        if (this.orderSearch.side) {
          api = api + '&side=' + this.orderSearch.side;
        }
        if (this.orderSearch.strategy) {
          api = api + '&strategy=' + this.orderSearch.strategy;
        }
        if (this.orderSearch.category) {
          api = api + '&category=' + this.orderSearch.category;
        }
        this.$http.get(api).then(response => {
          let data = response.data;
          if (data.code == 200) {
            this.openPositionsOrders = data.result.orderList;
            let notes = data.result.orderNotes;
            // 将交易所id转为名字
            for (let i = 0, iLength = this.openPositionsOrders.length; i < iLength; i++) {
              this.openPositionsOrders[i].exchangeName = this.getExchangeNameById(this.openPositionsOrders[i].exchangeId);
              switch (this.openPositionsOrders[i].category) {
                case 'IOB':
                  this.openPositionsOrders[i].bgColor = 'palered--text';
                  break;
                case 'TA':
                  this.openPositionsOrders[i].bgColor = 'palegreen--text';
                  break;
                case 'MR':
                  this.openPositionsOrders[i].bgColor = 'paleyellow--text';
                  break;
                case 'TP':
                  this.openPositionsOrders[i].bgColor = 'red--text';
                  break;
                default:
                  this.openPositionsOrders[i].bgColor = '';
                  break;
              }
              // for (let j = 0, jLength = notes.length; j < jLength; j++) {
              //   if(this.openPositionsOrders[i].id == notes[j].orderId){
              //     this.openPositionsOrders[i].note = notes[j].note;
              //     switch (notes[j].note){
              //       case 'IOB': this.openPositionsOrders[i].bgColor = 'palered--text'; break;
              //       case 'TA': this.openPositionsOrders[i].bgColor = 'palegreen--text'; break;
              //       case 'MR': this.openPositionsOrders[i].bgColor = 'paleyellow--text'; break;
              //       case 'TP': this.openPositionsOrders[i].bgColor = 'red--text'; break;
              //       default: break;
              //     }
              //   }
              // }
            }
            this.openPositionsOrdersTotalElements = data.result.totalElements;
            this.openPositionsOrdersTotalPage = Math.ceil(data.result.totalElements / this.pageSize);
            this.openPositionsLoading = false;
          } else {
            this.openPositionsOrders = [];
            this.openPositionsOrdersTotalElements = 0;
            this.openPositionsLoading = false;
          }
        });
      },
      // 查询持仓信息
      getOpenPositionsAtExchanges() {
        this.openPositionsAtExchangesLoading = true;
        let api = config.baseUrl + '/exchange/futures/private/getOpenPositions?1=1';
        if (this.openPositionsExchangeSearch) {
          api = api + '&exchange=' + this.openPositionsExchangeSearch;
        }
        if (this.openPositionsSymbolSearch) {
          api = api + '&symbol=' + this.openPositionsSymbolSearch;
        }
        this.$http.get(api).then(response => {
          let data = response.data;
          this.openPositionsAtExchangesLoading = false;
          if (data.code == 200) {
            this.openPositionsAtExchanges = data.result;
            this.openPositionsAtExchangesTotalElements = data.result.length;
            this.openPositionsAtExchangesTotalPage = Math.ceil(this.openPositionsAtExchangesTotalElements / this.pageSize);
          } else {
            this.openPositionsAtExchanges = [];
            this.openPositionsAtExchangesTotalElements = 0;
            this.openPositionsAtExchangesTotalPage = 0;
          }
        }, error => {
          this.openPositionsAtExchanges = [];
          this.openPositionsAtExchangesLoading = false;
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
        })
      },
      // 根据订单Id删除未提交的订单
      deleteUnsubmittedOrderById(id) {
        this.unsubmittedOrdersId = id;
        this.deleteUnsubmittedOrderDialog = true;
      },
      // 根据订单ID删除已提交的订单
      deleteSubmittedOrderById(id) {
        this.submittedOrdersId = id;
        this.cancelSubmittedOrderDialog = true;
      },
      // 删除未提交的订单
      deleteUnsubmittedOrder() {
        let id = this.unsubmittedOrdersId;
        this.deleteUnsubmittedLoading = true;
        let api = config.baseUrl + '/order/futures/private/deleteUnsubmitted';
        let param = {
          id: id
        };
        this.$http.post(api, param).then(response => {
          let data = response.data;
          this.cancelLoading();
          if (data.code == 200) {
            // 删除成功
            this.deleteUnsubmittedOrderDialog = false;
            this.pendingEntryOrdersPageNo = 1;
            this.pendingExitOrdersPageNo = 1;
            // this.getAllFuturesOrders();
            this.$store.dispatch('snackbarMessageHandler', 'Success');
          } else {
            this.$store.dispatch('snackbarMessageHandler', data.message);
          }
        }, error => {
          this.cancelLoading();
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
        });
      },
      // 取消已提交的订单
      cancelSubmittedOrder() {
        let id = this.submittedOrdersId;
        this.cancelSubmittedLoading = true;
        let api = config.baseUrl + '/order/futures/private/cancelSubmitted';
        let param = {
          id: id
        };
        this.$http.post(api, param).then(response => {
          let data = response.data;
          this.cancelLoading();
          if (data.code == 200) {
            // 取消成功
            this.submittedOrdersPageNo = 1;
            this.unfilledExchangeOrdersPageNo = 1;
            // this.getAllFuturesOrders();
            this.$store.dispatch('snackbarMessageHandler', 'Success');
          } else {
            this.$store.dispatch('snackbarMessageHandler', data.message);
          }
        }, error => {
          this.cancelLoading();
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
        });
      },
      //关闭加载中
      cancelLoading() {
        this.submitBtnLoading = false;
        this.orderConfirmationDialog = false;
        this.deleteUnsubmittedLoading = false;
        this.cancelSubmittedOrderDialog = false;
        this.cancelSubmittedLoading = false;
        this.submittedOrderFormLoading = false;
      },
      //关闭弹窗
      cancelDialog() {
        this.dialog = false;
        this.cancelLoading();
      },
      // 是否存在订单
      isExistOrder() {
        this.submittedOrderFormLoading = true;
        if (this.$refs.orderForm.validate()) {
          let api = config.baseUrl + '/order/futures/private/isExistOrder?symbol=' + this.orderForm.symbol;
          this.$http.get(api).then(response => {
            let data = response.data;
            // this.cancelLoading();
            if (data.code == 200) {
              if (data.result == true) {
                // 存在订单，显示提示消息
                this.submittedOrderFormLoading = false;
                // this.cancelDialog();
                this.$store.dispatch('snackbarMessageHandler', (this.$t("futuresTrading.isExistOrderMsgHandler")));
              } else {
                // 不存在订单，新增订单
                this.submitOrder();
              }
            }
          }, error => {
            this.cancelLoading();
            this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
          });
        } else {
          this.$store.dispatch('snackbarMessageHandler', (this.$t("futuresTrading.invalidParameterMsgHandler")));
        }
      },
      // 判断参数是否正确
      judgeParameters(isShowLargePriceGap) {
        if (this.isDisabledOrderButton) {
          return;
        }
        this.largePriceGapDialog = false;
        if (!this.isSetApiKey) {
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.noSetApiKey'));
          return;
        }
        if (this.$refs.orderForm.validate()) {
          if (this.orderForm.strategy == null || this.orderForm.strategy == '') {
            this.$store.dispatch('snackbarMessageHandler', (this.$t("futuresTrading.invalidStrategyMsgHandler")));
            this.cancelLoading();
            return;
          }
          if (this.orderForm.category == null || this.orderForm.category == '') {
            this.$store.dispatch('snackbarMessageHandler', (this.$t("futuresTrading.invalidCategoryMsgHandler")));
            this.cancelLoading();
            return;
          }
          // 不是对手价时候判断价格
          if (this.orderForm.priceType != 'bbo' && this.orderForm.price <= 0) {
            this.$store.dispatch('snackbarMessageHandler', (this.$t("futuresTrading.invalidPriceMsgHandler")));
            this.cancelLoading();
            return;
          }
          if (this.orderForm.size <= 0) {
            this.$store.dispatch('snackbarMessageHandler', (this.$t("futuresTrading.invalidVolume")));
            this.cancelLoading();
            return;
          }
          if (this.orderForm.priceType != 'bbo' && this.orderForm.takeProfitPrice < 0) {
            this.$store.dispatch('snackbarMessageHandler', (this.$t("futuresTrading.invalidTakeProfitPrice")));
            this.cancelLoading();
            return;
          }
          // 是否显示大的价格间隙，不显示则直接跳过，不是BBO才需要判断
          if (isShowLargePriceGap && this.orderForm.priceType != 'bbo') {
            let currentPrice = 0;
            if (this.marketPrice != null && this.marketPrice[this.symbol] != null && this.marketPrice[this.symbol].current !=
              null) {
              currentPrice = Number(this.marketPrice[this.symbol].current.price);
            }
            if (currentPrice > 0 && (this.orderForm.price < currentPrice * 0.75 || this.orderForm.price > currentPrice *
                1.25)) {
              this.largePriceGapDialog = true;
              return;
            }
          }
          let ts = +new Date();
          this.orderForm.sendOrderStartDatetime = formatISO8601Time(ts);
          this.orderForm.sendOrderStartTimestamp = ts;
          if ('open' == this.side) {
            // 显示设置移动止损的弹框
            this.trailingStopDialog = true;
          } else {
            // 显示详情
            this.orderConfirmationDialog = true;
          }
        }
      },
      // 手机判断参数是否正确
      mobileJudgeParameters(isShowLargePriceGap) {
        if (this.isDisabledOrderButton) {
          return;
        }
        this.largePriceGapDialog = false;
        if (!this.isSetApiKey) {
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.noSetApiKey'));
          return;
        }
        if (this.$refs.mobileOrderForm.validate()) {
          if (this.orderForm.strategy == null || this.orderForm.strategy == '') {
            this.$store.dispatch('snackbarMessageHandler', (this.$t("futuresTrading.invalidStrategyMsgHandler")));
            this.cancelLoading();
            return;
          }
          if (this.orderForm.category == null || this.orderForm.category == '') {
            this.$store.dispatch('snackbarMessageHandler', (this.$t("futuresTrading.invalidCategoryMsgHandler")));
            this.cancelLoading();
            return;
          }
          if (this.orderForm.priceType != 'bbo' && this.orderForm.price <= 0) {
            this.$store.dispatch('snackbarMessageHandler', (this.$t("futuresTrading.invalidPriceMsgHandler")));
            this.cancelLoading();
            return;
          }
          if (this.orderForm.size <= 0) {
            this.$store.dispatch('snackbarMessageHandler', (this.$t("futuresTrading.invalidVolume")));
            this.cancelLoading();
            return;
          }
          if (this.orderForm.priceType != 'bbo' && this.orderForm.takeProfitPrice < 0) {
            this.$store.dispatch('snackbarMessageHandler', this.$t("futuresTrading.invalidTakeProfitPrice"));
            this.cancelLoading();
            return;
          }
          // 是否显示大的价格间隙，不显示则直接跳过，不是BBO才需要判断
          if (isShowLargePriceGap && this.orderForm.priceType != 'bbo') {
            let currentPrice = 0;
            if (this.marketPrice != null && this.marketPrice[this.symbol] != null && this.marketPrice[this.symbol].current !=
              null) {
              currentPrice = Number(this.marketPrice[this.symbol].current.price);
            }
            if (currentPrice > 0 && (this.orderForm.price < currentPrice * 0.75 || this.orderForm.price > currentPrice *
                1.25)) {
              this.largePriceGapDialog = true;
              return;
            }
          }
          let ts = +new Date();
          this.orderForm.sendOrderStartDatetime = formatISO8601Time(ts);
          this.orderForm.sendOrderStartTimestamp = ts;
          if ('open' == this.side) {
            // 显示设置移动止损的弹框
            this.nextOrder = "next3";
          } else {
            // 显示详情
            this.orderConfirmationDialog = true;
          }
        }
      },
      // 显示设置移动止损的弹框
      showTrailingStopDialog() {
        this.orderConfirmationDialog = false;
        this.submitBtnLoading = false;
        this.trailingStopDialog = true;
      },
      // 设置移动止损等级
      setTrailingStop() {
        if (this.orderForm.category == 'MR') {
          this.plan('MR');
        }
        this.trailingStopDialog = false;
        this.orderConfirmationDialog = true;
        this.submitBtnLoading = true;
      },
      // 查询是否存在ApiKey
      hasApiKey() {
        let api = config.baseUrl + "/exchange/futures/private/hasApiKey?exchange=" + this.exchangeCode;
        this.$http.get(api).then(response => {
          let data = response.data;
          if (data.code == 200) {
            this.isSetApiKey = true;
          } else {
            this.isSetApiKey = false;
            this.$store.dispatch('snackbarMessageHandler', data.message);
          }
        }, error => {
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
        });
      },
      // 获取账户余额
      getAccounts() {
        let api = config.baseUrl + "/exchange/" + this.exchangeCode + "/futures/private/accounts";
        this.$http.get(api).then(response => {
          let data = response.data;
          if (data.code == 200) {
            this.accounts = data.result;
          } else {
            this.$store.dispatch('snackbarMessageHandler', data.message);
          }
        }, error => {
          // this.isSetApiKey = false;
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
        });
      },
      // 格式化时间
      formatDate(timestamp) {
        if ('UTCTime' == this.time) {
          return formatUTCTime(timestamp);
        } else {
          return formatTime(timestamp);
        }
      },
      // 交易所Id转为交易所Code
      getExchangeCode(exhcangId) {
        for (let i = 0; i < this.exchanges.length; i++) {
          if (exhcangId == this.exchanges[i].id) {
            return this.exchanges[i].code;
          }
        }
      },
      // 交易所code转为交易所id
      getExchangeId(code) {
        for (let i = 0; i < this.exchanges.length; i++) {
          if (code == this.exchanges[i].code) {
            return this.exchanges[i].id;
          }
        }
      },
      // 交易所code转为交易所id
      getExchangeName(code) {
        for (let i = 0; i < this.exchanges.length; i++) {
          if (code == this.exchanges[i].code) {
            return this.exchanges[i].name;
          }
        }
      },
      // 交易所id转为交易所name
      getExchangeNameById(id) {
        return getExchangeNameById(this.exchanges, id);
      },
      // 格式化数字
      formatNum(num) {
        return formatNumber(num);
      },
      // 格式化订单方向
      formatSide(side) {
        return formatOrderSide(side);
      },
      // 显示修改订单的弹框
      showChangeOrderDialog(futuresOrder) {
        // 将对象序列化再解析目的是为了复制一个对象
        this.showChangedFuturesOrder = JSON.parse(JSON.stringify(futuresOrder));
        this.changedFuturesOrderForm = JSON.parse(JSON.stringify(futuresOrder));
        // 默认设置为手动价格
        this.changedFuturesOrderForm.priceType = 'manually';
        this.changedFuturesOrderTrailingStopForm.trailingStopLevel1 = Number(Number(this.changedFuturesOrderForm.trailingStopLevel1) *
          100).toFixed(2);
        this.changedFuturesOrderTrailingStopForm.trailingStopLevel2 = Number(Number(this.changedFuturesOrderForm.trailingStopLevel2) *
          100).toFixed(2);
        this.changedFuturesOrderTrailingStopForm.trailingStopLevel3 = Number(Number(this.changedFuturesOrderForm.trailingStopLevel3) *
          100).toFixed(2);
        this.changedFuturesOrderTrailingStopForm.priceLevel2 = Number(Number(this.changedFuturesOrderForm.priceLevel2) *
          100).toFixed(2);
        this.changedFuturesOrderTrailingStopForm.priceLevel3 = Number(Number(this.changedFuturesOrderForm.priceLevel3) *
          100).toFixed(2);
        this.changeOrderDialog = true;
        // 调用common.js的函数
        let exchange = getExchangeCodeById(this.exchanges, futuresOrder.exchangeId);
        let faceValue = this.contractFaceValue[exchange.toLowerCase()][futuresOrder.symbol];
        this.changedFuturesOrderForm.exchange = exchange;
        this.changedFuturesOrderForm.faceValue = faceValue;
        if (this.changedFuturesOrderForm.size > 0) {
          let doller = this.formatNum((this.changedFuturesOrderForm.size * faceValue).toFixed(2));
          this.changedFuturesOrderForm.sizeDollarValue = '$' + doller;
        } else {
          this.changedFuturesOrderForm.sizeDollarValue = null;
        }
        //  changedFuturesOrderForm: {
        //   handler(newObject, oldObject) {
        //     this.changedFuturesOrderTrailingStopForm.trailingStopLevel1 = Number(Number(newObject.trailingStopLevel1) * 100).toFixed(2);
        //     this.changedFuturesOrderTrailingStopForm.trailingStopLevel2 = Number(Number(newObject.trailingStopLevel2) * 100).toFixed(2);
        //     this.changedFuturesOrderTrailingStopForm.trailingStopLevel3 = Number(Number(newObject.trailingStopLevel3) * 100).toFixed(2);
        //     this.changedFuturesOrderTrailingStopForm.priceLevel2 = Number(Number(newObject.priceLevel2) * 100).toFixed(2);
        //     this.changedFuturesOrderTrailingStopForm.priceLevel3 = Number(Number(newObject.priceLevel3) * 100).toFixed(2);
        //   },
        //   immediate: true,
        //   deep: true
        // },
        // changedFuturesOrderTrailingStopForm: {
        //     handler(newObject, oldObject) {
        //       this.changedFuturesOrderForm.trailingStopLevel1 = Number(Number(newObject.trailingStopLevel1) / 100).toFixed(4);
        //       this.changedFuturesOrderForm.trailingStopLevel2 = Number(Number(newObject.trailingStopLevel2) / 100).toFixed(4);
        //       this.changedFuturesOrderForm.trailingStopLevel3 = Number(Number(newObject.trailingStopLevel3) / 100).toFixed(4);
        //       this.changedFuturesOrderForm.priceLevel2 = Number(Number(newObject.priceLevel2) / 100).toFixed(4);
        //       this.changedFuturesOrderForm.priceLevel3 = Number(Number(newObject.priceLevel3) / 100).toFixed(4);
        //     },
        //     immediate: true,
        //     deep: true
        // },
      },
      // 显示修改订单定时器的弹框
      showChangeTimeDialog(futuresOrder) {
        if (futuresOrder == null) {
          this.$store.dispatch('snackbarMessageHandler', 'The order was not found.');
        }
        this.changeTimeOrder.id = futuresOrder.id;
        this.changeTimeOrder.side = futuresOrder.side;
        this.changeTimeOrder.status = futuresOrder.status;
        this.changeTimeOrder.futuresOrderId = futuresOrder.futuresOrderId;
        this.changeTimeOrder.beFixedStopLossPrice = futuresOrder.beFixedStopLossPrice;
        this.changeTimeOrder.sendOrderEndSwitch = (futuresOrder.sendOrderEndTimestamp == -1);
        this.changeTimeOrder.takeProfitEndSwitch = (futuresOrder.takeProfitEndTimestamp == -1);
        this.changeTimeOrder.stopLoss1EndSwitch = (futuresOrder.stopLoss1EndTimestamp == -1);
        this.changeTimeOrder.stopLossEndSwitch = (futuresOrder.stopLossEndTimestamp == -1);
        this.changeTimeOrder.fixedStopLossEndSwitch = (futuresOrder.fixedStopLossEndTimestamp == -1);
        this.changeTimeOrder.timestamp = formatISO8601Time(futuresOrder.timestamp);
        this.changeTimeOrder.sendOrderStartTimestamp = formatISO8601Time(futuresOrder.sendOrderStartTimestamp);
        this.changeTimeOrder.takeProfitStartTimestamp = formatISO8601Time(futuresOrder.takeProfitStartTimestamp);
        this.changeTimeOrder.stopLoss1StartTimestamp = formatISO8601Time(futuresOrder.stopLoss1StartTimestamp);
        this.changeTimeOrder.stopLossStartTimestamp = formatISO8601Time(futuresOrder.stopLossStartTimestamp);
        this.changeTimeOrder.fixedStopLossStartTimestamp = formatISO8601Time(futuresOrder.fixedStopLossStartTimestamp);
        if (futuresOrder.sendOrderEndTimestamp == -1) {
          this.changeTimeOrder.sendOrderEndTimestamp = formatISO8601Time(+new Date() + 3600 * 1000 * 24 * 30);
        } else {
          this.changeTimeOrder.sendOrderEndTimestamp = formatISO8601Time(futuresOrder.sendOrderEndTimestamp);
        }
        if (futuresOrder.takeProfitEndTimestamp == -1) {
          this.changeTimeOrder.takeProfitEndTimestamp = formatISO8601Time(+new Date() + 3600 * 1000 * 24 * 30);
        } else {
          this.changeTimeOrder.takeProfitEndTimestamp = formatISO8601Time(futuresOrder.takeProfitEndTimestamp);
        }
        if (futuresOrder.stopLoss1EndTimestamp == -1) {
          this.changeTimeOrder.stopLoss1EndTimestamp = formatISO8601Time(+new Date() + 3600 * 1000 * 24 * 30);
        } else {
          this.changeTimeOrder.stopLoss1EndTimestamp = formatISO8601Time(futuresOrder.stopLoss1EndTimestamp);
        }
        if (futuresOrder.stopLossEndTimestamp == -1) {
          this.changeTimeOrder.stopLossEndTimestamp = formatISO8601Time(+new Date() + 3600 * 1000 * 24 * 30);
        } else {
          this.changeTimeOrder.stopLossEndTimestamp = formatISO8601Time(futuresOrder.stopLossEndTimestamp);
        }
        if (futuresOrder.fixedStopLossEndTimestamp == -1) {
          this.changeTimeOrder.fixedStopLossEndTimestamp = formatISO8601Time(+new Date() + 3600 * 1000 * 24 * 30);
        } else {
          this.changeTimeOrder.fixedStopLossEndTimestamp = formatISO8601Time(futuresOrder.fixedStopLossEndTimestamp);
        }
        this.changeTimeDialog = true;
      },
      // 提交修改时间订单的请求
      submitChangeTimeOrder() {
        this.changeTimeLoading = true;
        let changeTimeOrderParam = {
          id: this.changeTimeOrder.id,
          futuresOrderId: this.changeTimeOrder.futuresOrderId,
          sendOrderStartTimestamp: parseISO8601ToTimestamp(this.changeTimeOrder.sendOrderStartTimestamp),
          sendOrderEndTimestamp: this.changeTimeOrder.sendOrderEndSwitch ? -1 : parseISO8601ToTimestamp(this.changeTimeOrder
            .sendOrderEndTimestamp),
          takeProfitStartTimestamp: parseISO8601ToTimestamp(this.changeTimeOrder.takeProfitStartTimestamp),
          takeProfitEndTimestamp: this.changeTimeOrder.takeProfitEndSwitch ? -1 : parseISO8601ToTimestamp(this.changeTimeOrder
            .takeProfitEndTimestamp),
          stopLoss1StartTimestamp: parseISO8601ToTimestamp(this.changeTimeOrder.stopLoss1StartTimestamp),
          stopLoss1EndTimestamp: this.changeTimeOrder.stopLoss1EndSwitch ? -1 : parseISO8601ToTimestamp(this.changeTimeOrder
            .stopLoss1EndTimestamp),
          stopLossStartTimestamp: parseISO8601ToTimestamp(this.changeTimeOrder.stopLossStartTimestamp),
          stopLossEndTimestamp: this.changeTimeOrder.stopLossEndSwitch ? -1 : parseISO8601ToTimestamp(this.changeTimeOrder
            .stopLossEndTimestamp),
          fixedStopLossStartTimestamp: parseISO8601ToTimestamp(this.changeTimeOrder.fixedStopLossStartTimestamp),
          fixedStopLossEndTimestamp: this.changeTimeOrder.fixedStopLossEndSwitch ? -1 : parseISO8601ToTimestamp(this.changeTimeOrder
            .fixedStopLossEndTimestamp),
        };
        let api = config.baseUrl + '/order/futures/private/edit/time';
        this.$http.post(api, changeTimeOrderParam).then(response => {
          let data = response.data;
          this.changeTimeLoading = false;
          this.changeTimeDialog = false;
          if (data.code == 200) {
            this.changeOrderDialog = false;
            // this.getAllFuturesOrders();
            this.$store.dispatch('snackbarMessageHandler', data.message);
          } else {
            this.$store.dispatch('snackbarMessageHandler', data.message);
            this.changeOrderDialog = false;
          }
        }, error => {
          this.changeTimeLoading = false;
          this.changeTimeDialog = false;
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
        })
      },
      // 提交改变的期货订单请求
      submitChangedFuturesOrder() {
        this.changeOrderLoading = true;
        let futuresOrderParam = {
          id: this.changedFuturesOrderForm.id,
          priceType: this.changedFuturesOrderForm.priceType,
          price: Number(this.changedFuturesOrderForm.price),
          size: Number(this.changedFuturesOrderForm.size),
          renkoEntryMultiplier: Number(this.changedFuturesOrderForm.renkoEntryMultiplier),
          renkoExitMultiplier: Number(this.changedFuturesOrderForm.renkoExitMultiplier),
          takeProfitPrice: Number(this.changedFuturesOrderForm.takeProfitPrice),
          beFixedStopLossPrice: this.changedFuturesOrderForm.beFixedStopLossPrice,
          fixedStopLossPrice: Number(this.changedFuturesOrderForm.fixedStopLossPrice),
          trailingStopLevel1: Number(this.changedFuturesOrderForm.trailingStopLevel1),
          trailingStopLevel2: Number(this.changedFuturesOrderForm.trailingStopLevel2),
          trailingStopLevel3: Number(this.changedFuturesOrderForm.trailingStopLevel3),
          priceLevel2: Number(this.changedFuturesOrderForm.priceLevel2),
          priceLevel3: Number(this.changedFuturesOrderForm.priceLevel3)
        };
        let api = config.baseUrl + '/order/futures/private/edit/order';
        this.$http.post(api, futuresOrderParam).then(response => {
          let data = response.data;
          this.changeOrderLoading = false;
          this.isEditingFuturesOrder = true;
          if (data.code == 200) {
            this.changeOrderDialog = false;
            // this.getAllFuturesOrders();
            this.$store.dispatch('snackbarMessageHandler', data.message);
          } else {
            this.$store.dispatch('snackbarMessageHandler', data.message);
            this.changeOrderDialog = false;
          }
        }, error => {
          this.changeOrderLoading = false;
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
          this.changeOrderDialog = false;
        });
      },
      // 显示退出仓位订单的弹框
      showExitPositionOrderDialog(futuresOrder) {
        // 将对象序列化再解析目的是为了复制一个对象
        this.showExitPositionOrder = JSON.parse(JSON.stringify(futuresOrder));
        this.exitPositionOrderForm.price = 0;
        this.exitPositionOrderForm.priceType = 'manually';
        this.isEditingExitPositionOrder = true;
        this.exitPositionOrderDialog = true;
      },
      // 获得退出仓位订单时的对手价
      getExitPositionBestBidOffer() {
        if (this.marketPrice != null && this.marketPrice[this.showExitPositionOrder.symbol] != null) {
          if ('open_long' == this.showExitPositionOrder.side) {
            this.exitPositionOrderForm.price = Number(this.marketPrice[this.showExitPositionOrder.symbol].bid.price);
          } else if ('open_short' == this.showExitPositionOrder.side) {
            this.exitPositionOrderForm.price = Number(this.marketPrice[this.showExitPositionOrder.symbol].ask.price);
          } else {
            this.$store.dispatch('snackbarMessageHandler', 'Invalid Side');
            this.isEditingExitPositionOrder = true;
          }
        }
      },
      // 发送退出仓位的订单
      submitAddExitFuturesOrder() {
        if (this.exitPositionOrderForm.price <= 0) {
          this.$store.dispatch('snackbarMessageHandler', 'Invalid Price');
          this.isEditingExitPositionOrder = true;
          return;
        }
        this.exitPositionOrderForm.price = Number(this.exitPositionOrderForm.price);
        this.exitPositionOrderForm.size = this.showExitPositionOrder.size;
        this.exitPositionOrderForm.symbol = this.showExitPositionOrder.symbol;
        this.exitPositionOrderForm.exchangeId = this.showExitPositionOrder.exchangeId;
        // 退出哪单就将哪一单赋值给自己的父级订单主键
        this.exitPositionOrderForm.futuresOrderId = this.showExitPositionOrder.futuresOrderId;
        this.exitPositionOrderForm.id = this.showExitPositionOrder.id;
        if ('open_long' == this.showExitPositionOrder.side) {
          this.exitPositionOrderForm.side = 'close_sell';
        } else if ('open_short' == this.showExitPositionOrder.side) {
          this.exitPositionOrderForm.side = 'close_buy';
        } else {
          this.$store.dispatch('snackbarMessageHandler', 'Invalid Side');
          this.isEditingExitPositionOrder = true;
          return;
        }
        this.exitPositionOrderLoading = true;
        let api = config.baseUrl + '/order/futures/private/addExitFuturesOrder';
        this.$http.post(api, this.exitPositionOrderForm).then(response => {
          let data = response.data;
          this.exitPositionOrderLoading = false;
          this.isEditingExitPositionOrder = true;
          if (data.code == 200) {
            this.exitPositionOrderDialog = false;
            // this.getAllFuturesOrders();
          } else {
            this.$store.dispatch('snackbarMessageHandler', data.message);
          }
        }, error => {
          this.exitPositionOrderLoading = false;
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
        });
      },
      // 发送退出仓位的订单
      submitExitOrder() {
        this.exitPositionOrderForm.price = Number(this.exitPositionOrderForm.price);
        this.exitPositionOrderForm.id = this.showExitPositionOrder.id;
        this.exitPositionOrderLoading = true;
        let api = config.baseUrl + '/order/futures/private/exit';
        this.$http.post(api, this.exitPositionOrderForm).then(response => {
          let data = response.data;
          this.exitPositionOrderLoading = false;
          this.isEditingExitPositionOrder = true;
          if (data.code == 200) {
            this.exitPositionOrderDialog = false;
            // this.getAllFuturesOrders();
          } else {
            this.$store.dispatch('snackbarMessageHandler', data.message);
          }
        }, error => {
          this.exitPositionOrderLoading = false;
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
        });
      },
      // 将驼峰格式化为单词组
      formatStr(str) {
        str = str.replace(/([A-Z])/g, "-$1").toLowerCase();
        var a = str.split("-");
        var re = "";
        for (var i = 0; i < a.length; i++) {
          if (i == 0) {
            re = a[i].slice(0, 1).toUpperCase() + a[i].slice(1);
          } else {
            re = re + " " + a[i].slice(0, 1).toUpperCase() + a[i].slice(1);
          }
        }
        return re;
      },
      // 止损价百分比计划设置
      plan(plan) {
        switch (plan) {
          case "0":
            this.trailingStopLevel1 = 0;
            this.priceLevel2 = 0;
            this.trailingStopLevel2 = 0;
            this.priceLevel3 = 0;
            this.trailingStopLevel3 = 0;
            break;
          case "1":
            this.trailingStopLevel1 = 15;
            this.priceLevel2 = 8;
            this.trailingStopLevel2 = 10;
            this.priceLevel3 = 10;
            this.trailingStopLevel3 = 8;
            break;
          case "2":
            this.trailingStopLevel1 = 10;
            this.priceLevel2 = 5;
            this.trailingStopLevel2 = 8;
            this.priceLevel3 = 10;
            this.trailingStopLevel3 = 5;
            break;
          case "3":
            this.trailingStopLevel1 = 15;
            this.priceLevel2 = 8;
            this.trailingStopLevel2 = 10;
            this.priceLevel3 = 15;
            this.trailingStopLevel3 = 8;
            break;
          case "4":
            this.trailingStopLevel1 = 20;
            this.priceLevel2 = 10;
            this.trailingStopLevel2 = 15;
            this.priceLevel3 = 20;
            this.trailingStopLevel3 = 10;
            break;
          case "5":
            this.trailingStopLevel1 = 0;
            this.priceLevel2 = 100;
            this.trailingStopLevel2 = 100;
            this.priceLevel3 = 100;
            this.trailingStopLevel3 = 100;
            break;
          case "MR":
            this.priceLevel2 = 100;
            this.trailingStopLevel2 = this.trailingStopLevel1;
            this.priceLevel3 = 100;
            this.trailingStopLevel3 = this.trailingStopLevel1;
            break;
          default:
            break;
        }
      },
      // 显示切割订单的弹出框
      showSplitOrderDialog(futuresOrder) {
        // 将对象序列化再解析目的是为了复制一个对象
        this.showSplitFuturesOrder = JSON.parse(JSON.stringify(futuresOrder));
        this.splitFuturesOrderForm = JSON.parse(JSON.stringify(futuresOrder));
        this.splitFuturesOrderTrailingStopForm.trailingStopLevel1 = Number(Number(this.splitFuturesOrderForm.trailingStopLevel1) *
          100).toFixed(2);
        this.splitFuturesOrderTrailingStopForm.trailingStopLevel2 = Number(Number(this.splitFuturesOrderForm.trailingStopLevel2) *
          100).toFixed(2);
        this.splitFuturesOrderTrailingStopForm.trailingStopLevel3 = Number(Number(this.splitFuturesOrderForm.trailingStopLevel3) *
          100).toFixed(2);
        this.splitFuturesOrderTrailingStopForm.priceLevel2 = Number(Number(this.splitFuturesOrderForm.priceLevel2) *
          100).toFixed(2);
        this.splitFuturesOrderTrailingStopForm.priceLevel3 = Number(Number(this.splitFuturesOrderForm.priceLevel3) *
          100).toFixed(2);
        this.splitOrderDialog = true;
        // 调用common.js的函数
        let exchange = getExchangeCodeById(this.exchanges, futuresOrder.exchangeId);
        let faceValue = this.contractFaceValue[exchange.toLowerCase()][futuresOrder.symbol];
        this.splitFuturesOrderForm.exchange = exchange;
        this.splitFuturesOrderForm.faceValue = faceValue;
        if (this.splitFuturesOrderForm.size > 0) {
          let doller = this.formatNum((this.splitFuturesOrderForm.size * faceValue).toFixed(2));
          this.splitFuturesOrderForm.sizeDollarValue = '$' + doller;
        } else {
          this.splitFuturesOrderForm.sizeDollarValue = null;
        }
      },
      // 关闭分割订单的弹出框
      closeSplitOrderDialog() {
        this.splitOrderDialog = false;
        this.splitOrderLoading = false;
        this.splitFuturesOrderTrailingStopForm.trailingStopLevel1 = 0;
        this.splitFuturesOrderTrailingStopForm.trailingStopLevel2 = 0;
        this.splitFuturesOrderTrailingStopForm.trailingStopLevel3 = 0;
        this.splitFuturesOrderTrailingStopForm.priceLevel2 = 0;
        this.splitFuturesOrderTrailingStopForm.priceLevel3 = 0;
        this.showSplitFuturesOrder = {};
        this.splitFuturesOrderForm = {};
      },
      // 切割已全部成交的持仓订单
      splitFuturesOrder() {
        this.splitOrderLoading = true;
        this.splitFuturesOrderForm.trailingStopLevel1 = Number(Number(Number(this.splitFuturesOrderTrailingStopForm.trailingStopLevel1)
          .toFixed(4)) / 100).toFixed(4);
        this.splitFuturesOrderForm.trailingStopLevel2 = Number(Number(Number(this.splitFuturesOrderTrailingStopForm.trailingStopLevel2)
          .toFixed(4)) / 100).toFixed(4);
        this.splitFuturesOrderForm.trailingStopLevel3 = Number(Number(Number(this.splitFuturesOrderTrailingStopForm.trailingStopLevel3)
          .toFixed(4)) / 100).toFixed(4);
        this.splitFuturesOrderForm.priceLevel2 = Number(Number(Number(this.splitFuturesOrderTrailingStopForm.priceLevel2)
          .toFixed(4)) / 100).toFixed(4);
        this.splitFuturesOrderForm.priceLevel3 = Number(Number(Number(this.splitFuturesOrderTrailingStopForm.priceLevel3)
          .toFixed(4)) / 100).toFixed(4);
        let futuresOrderParam = {
          id: this.splitFuturesOrderForm.id,
          size: Number(this.splitFuturesOrderForm.size),
          takeProfitPrice: Number(this.splitFuturesOrderForm.takeProfitPrice),
          trailingStopLevel1: Number(this.splitFuturesOrderForm.trailingStopLevel1),
          trailingStopLevel2: Number(this.splitFuturesOrderForm.trailingStopLevel2),
          trailingStopLevel3: Number(this.splitFuturesOrderForm.trailingStopLevel3),
          priceLevel2: Number(this.splitFuturesOrderForm.priceLevel2),
          priceLevel3: Number(this.splitFuturesOrderForm.priceLevel3)
        };
        let api = config.baseUrl + '/order/futures/private/split';
        this.$http.post(api, futuresOrderParam).then(response => {
          let data = response.data;
          this.splitOrderLoading = false;
          this.$store.dispatch('snackbarMessageHandler', data.message);
          if (data.code == 200) {
            this.closeSplitOrderDialog();
            // this.getAllFuturesOrders();
          }
        }, error => {
          this.splitOrderLoading = false;
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
        });
      },
      // 显示修改备注的弹框
      showChangeNoteDialog(orderStatus, order) {
        if (order) {
          if (order.strategy) {
            this.changeNoteForm.strategy = order.strategy;
          } else {
            this.changeNoteForm.strategy = this.strategies[0];
          }
          if (order.category) {
            this.changeNoteForm.category = order.category;
          } else {
            this.changeNoteForm.category = this.orderCategories[0];
          }
          this.changeNoteForm.id = order.id;
          this.changeNoteForm.orderStatus = orderStatus;
          this.changeNoteForm.order = order;
          this.changeNoteDialog = true;
        }
      },
      // 跳转到批量编辑订单页面
      gotoBatchOrder() {
        if (this.user.token) {
          if (this.user.role == 'TRADER') {
            this.$router.push({
              name: "batchOrder"
            });
          } else {
            this.$store.dispatch('snackbarMessageHandler', this.$t("header.snackbarMessage"));
          }
        } else {
          this.gotoSignIn();
        }
      },
      // 复制到剪切板
      copyToClipboard(message) {
        this.$copyText(message).then(res => {
          // 复制成功
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.copied'));
        }).catch(err => {
          // 复制失败
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.copyFailed'));
        })
      },
      // 计算止盈预估收益/损失
      calcTakeProfitEstimatedPL(futuresTrading) {
        if (futuresTrading != null) {
          if (futuresTrading.takeProfitPrice == 0) {
            return '--';
          }
          let pl = 0;
          if (futuresTrading.side == 'open_long') {
            pl = (futuresTrading.takeProfitPrice / futuresTrading.price - 1) * futuresTrading.size * this.contractFaceValue[
              futuresTrading.exchangeName.toLowerCase()][futuresTrading.symbol];
          } else if (futuresTrading.side == 'open_short') {
            pl = (1 - futuresTrading.takeProfitPrice / futuresTrading.price) * futuresTrading.size * this.contractFaceValue[
              futuresTrading.exchangeName.toLowerCase()][futuresTrading.symbol];
          } else {
            return '--'
          }
          pl = Number(pl.toFixed(2));
          if (pl < 0) {
            pl = -pl;
            pl = pl.toFixed(2);
            pl = this.formatNum(pl);
            return '($' + pl + ')';
          } else {
            pl = pl.toFixed(2);
            pl = this.formatNum(pl);
            return '$' + pl;
          }
        } else {
          return '--'
        }
      },
      // 计算安全网预估收益/损失
      calcSafetyNetEstimatedPL(futuresTrading) {
        if (futuresTrading != null) {
          if (futuresTrading.stopLossPrice1 == 0) {
            return '--';
          }
          let pl = 0;
          if (futuresTrading.side == 'open_long') {
            pl = (futuresTrading.stopLossPrice1 / futuresTrading.price - 1) * futuresTrading.size * this.contractFaceValue[
              futuresTrading.exchangeName.toLowerCase()][futuresTrading.symbol];
          } else if (futuresTrading.side == 'open_short') {
            pl = (1 - futuresTrading.stopLossPrice1 / futuresTrading.price) * futuresTrading.size * this.contractFaceValue[
              futuresTrading.exchangeName.toLowerCase()][futuresTrading.symbol];
          } else {
            return '--'
          }
          pl = Number(pl.toFixed(2));
          if (pl < 0) {
            pl = -pl;
            pl = pl.toFixed(2);
            pl = this.formatNum(pl);
            return '($' + pl + ')';
          } else {
            pl = pl.toFixed(2);
            pl = this.formatNum(pl);
            return '$' + pl;
          }
        } else {
          return '--'
        }
      },
      // 计算预估收益/损失
      calcEstimatedPL(futuresTrading) {
        if (futuresTrading != null) {
          if (futuresTrading.beFixedStopLossPrice) {
            let pl = 0;
            // 是固定止损
            if (futuresTrading.fixedStopLossPrice == 0) {
              return '--';
            }
            if (futuresTrading.side == 'open_long') {
              pl = (futuresTrading.fixedStopLossPrice / futuresTrading.price - 1) * futuresTrading.size * this.contractFaceValue[
                futuresTrading.exchangeName.toLowerCase()][futuresTrading.symbol];
            } else if (futuresTrading.side == 'open_short') {
              pl = (1 - futuresTrading.fixedStopLossPrice / futuresTrading.price) * futuresTrading.size * this.contractFaceValue[
                futuresTrading.exchangeName.toLowerCase()][futuresTrading.symbol];
            } else {
              return '--'
            }
            pl = Number(pl.toFixed(2));
            if (pl < 0) {
              pl = -pl;
              pl = pl.toFixed(2);
              pl = this.formatNum(pl);
              return '($' + pl + ')';
            } else {
              pl = pl.toFixed(2);
              pl = this.formatNum(pl);
              return '$' + pl;
            }
          } else {
            // 是追踪止损
            if (futuresTrading.stopLossPrice == 0) {
              return '--';
            }
            let pl = 0;
            if (futuresTrading.side == 'open_long') {
              pl = (futuresTrading.stopLossPrice / futuresTrading.price - 1) * futuresTrading.size * this.contractFaceValue[
                futuresTrading.exchangeName.toLowerCase()][futuresTrading.symbol];
            } else if (futuresTrading.side == 'open_short') {
              pl = (1 - futuresTrading.stopLossPrice / futuresTrading.price) * futuresTrading.size * this.contractFaceValue[
                futuresTrading.exchangeName.toLowerCase()][futuresTrading.symbol];
            } else {
              return '--'
            }
            pl = Number(pl.toFixed(2));
            if (pl < 0) {
              pl = -pl;
              pl = pl.toFixed(2);
              pl = this.formatNum(pl);
              return '($' + pl + ')';
            } else {
              pl = pl.toFixed(2);
              pl = this.formatNum(pl);
              return '$' + pl;
            }
          }
        } else {
          return '--'
        }
      },
      // 打开交易所页面
      openExchangePage() {
        let url = '';
        switch (this.exchangeCode) {
          case 'kraken': url = 'https://futures.kraken.com/trade'; break;
          case 'okex': url = 'https://www.okex.com/derivatives/swap'; break;
          case 'binance': url = 'https://www.binance.com/en/delivery/btcusd_perpetual'; break;
          case 'ftx': url = 'https://ftx.com/markets/future'; break;
          default: break;
        }
        window.open(url, "_blank");
      },
      // 计算改变订单的Size的美元价值
      calcChangeOrderSizeDollarValue() {
        if (this.changedFuturesOrderForm != null) {
          if (this.changedFuturesOrderForm.size > 0) {
            let doller = this.formatNum((this.changedFuturesOrderForm.size * this.changedFuturesOrderForm.faceValue).toFixed(
              2));
            this.changedFuturesOrderForm.sizeDollarValue = '$' + doller;
          } else {
            this.changedFuturesOrderForm.sizeDollarValue = null;
          }
        }
      },
      // 计算分割订单的Size的美元价值
      calcSplitOrderSizeDollarValue() {
        if (this.splitFuturesOrderForm != null) {
          if (this.splitFuturesOrderForm.size > 0) {
            let doller = this.formatNum((this.splitFuturesOrderForm.size * this.splitFuturesOrderForm.faceValue).toFixed(
              2));
            this.splitFuturesOrderForm.sizeDollarValue = '$' + doller;
          } else {
            this.splitFuturesOrderForm.sizeDollarValue = null;
          }
        }
      },
      // 获得Renko的类别
      getCategoriesByStrategy(strategy) {
        switch (strategy) {
          case 'PP':
          case 'CPP':
            return this.orderCategories;
          default:
            let categories = ['TP'];
            return categories;
        }
      },
      // 更新类别
      updateStrategyAndCategory() {
        this.changeNoteLoading = true;
        let api = config.baseUrl + '/order/futures/private/edit/strategyAndCategory';
        let param = {
          id: this.changeNoteForm.id,
          strategy: this.changeNoteForm.strategy,
          category: this.changeNoteForm.category
        };
        this.$http.put(api, param).then(response => {
          let data = response.data;
          this.changeNoteLoading = false;
          this.changeNoteDialog = false;
          if (data.code == 200) {
            let orderList = null;
            switch (this.changeNoteForm.orderStatus) {
              case 'pendingEntry':
                orderList = this.pendingEntryOrders;
                break;
              case 'pendingExit':
                orderList = this.pendingExitOrders;
                break;
              case 'submitted':
                orderList = this.submittedOrders;
                break;
              case 'unfilled':
                orderList = this.unfilledExchangeOrders;
                break;
              case 'filled':
                orderList = this.filledExchangeOrders;
                break;
              case 'openPositions':
                orderList = this.openPositionsOrders;
                break;
              default:
                break;
            }
            if (orderList) {
              for (let i = 0, len = orderList.length; i < len; i++) {
                if (orderList[i].id == this.changeNoteForm.id) {
                  orderList[i].strategy = this.changeNoteForm.strategy;
                  orderList[i].category = this.changeNoteForm.category;
                  switch (this.changeNoteForm.category) {
                    case 'IOB':
                      orderList[i].bgColor = 'palered--text';
                      break;
                    case 'TA':
                      orderList[i].bgColor = 'palegreen--text';
                      break;
                    case 'MR':
                      orderList[i].bgColor = 'paleyellow--text';
                      break;
                    case 'TP':
                      orderList[i].bgColor = 'red--text';
                      break;
                    default:
                      orderList[i].bgColor = '';
                      break;
                  }
                  break;
                }
              }
            }
            this.changeNoteForm.id = null;
          } else {
            this.$store.dispatch('snackbarMessageHandler', data.message);
          }
        }, error => {
          this.changeNoteLoading = false;
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
        })
      },
      // 查询显示下单按钮
      getOrderButtonSide() {
        if (this.buttonSide == 'long') {
          return this.$t('common.long');
        } else if (this.buttonSide == 'short') {
          return this.$t('common.short');
        } else if (this.buttonSide == 'buy') {
          return this.$t('common.buy');
        } else if (this.buttonSide == 'sell') {
          return this.$t('common.sell');
        }
      },
      // 聚焦下一个输入框
      nextFocus(className, event) {
        let current = event.target;
        let elements = document.querySelectorAll("." + className + " input");
        for (let i = 0, len = elements.length; i < len; i++) {
          if (current == elements[i] && i < len - 1) {
            elements[i + 1].focus();
            return;
          }
          if (i == len - 1) {
            switch (className) {
              case 'orderForm':
                this.judgeParameters();
                break;
              case 'stopLossDialog':
                this.setTrailingStop();
                break;
              default:
                break;
            }
          }
        }
      },
      // 处理订单列表
      handleOrderList(orderList) {
        for (let i = 0, iLength = orderList.length; i < iLength; i++) {
          orderList[i].exchangeName = this.getExchangeNameById(orderList[i].exchangeId);
          switch (orderList[i].category) {
            case 'IOB':
              orderList[i].bgColor = 'palered--text';
              break;
            case 'TA':
              orderList[i].bgColor = 'palegreen--text';
              break;
            case 'MR':
              orderList[i].bgColor = 'paleyellow--text';
              break;
            case 'TP':
              orderList[i].bgColor = 'red--text';
              break;
            default:
              orderList[i].bgColor = '';
              break;
          }
        }
      },
      // 查询杠杆
      getLeverage(){
        let api = config.baseUrl + '/order/futures/private/leverage?exchange=' + this.exchangeCode + '&symbol=' + this.orderForm.symbol;
        this.$http.get(api).then(response => {
          let data = response.data;
          if (data.code == 200) {
            this.leverage = data.result;
          } else {
            this.$store.dispatch('snackbarMessageHandler', data.message);
          }
        }, error => {
          this.cancelLoading();
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
        });
      },
      queryPendingEntryOrdersByPages(pageNo) {
        this.getPendingEntryOrdersByPages(pageNo, true);
      },
      queryPendingExitOrdersByPages(pageNo) {
        this.getPendingExitOrdersByPages(pageNo, true);
      },
      querySubmittedOrdersByPages(pageNo) {
        this.getSubmittedOrdersByPages(pageNo, true);
      },
      queryUnfilledExchangeOrdersByPages(pageNo) {
        this.getUnfilledExchangeOrdersByPages(pageNo, true);
      },
      queryFilledExchangeOrdersByPages(pageNo) {
        this.getFilledExchangeOrdersByPages(pageNo, true);
      },
      queryOpenPositionsOrdersByPages(pageNo) {
        this.getOpenPositionsOrdersByPages(pageNo, true);
      },
      // 获取WebSocket数据
      getWebSocketData() {
        if (this.webSocketData == null) {
          return;
        }
        // 是否为订单数据
        let isOrderData = false;
        if (this.webSocketData.PENDING_ENTRY_FUTURES_ORDER) {
          this.getPendingEntryOrdersByPages(this.pendingEntryOrdersPageNo, false);
          // let pendingEntryOrders = this.webSocketData.PENDING_ENTRY_FUTURES_ORDER.orderList;
          // this.handleOrderList(pendingEntryOrders);
          // this.pendingEntryOrdersPageNo = 1;
          // this.pendingEntryOrdersTotalElements = this.webSocketData.PENDING_ENTRY_FUTURES_ORDER.totalElements;
          // this.pendingEntryOrdersTotalPage = Math.ceil(this.pendingEntryOrdersTotalElements/this.pageSize);
          // this.pendingEntryOrders = pendingEntryOrders;
          isOrderData = true;
        }
        if (this.webSocketData.PENDING_EXIT_FUTURES_ORDER) {
          this.getPendingExitOrdersByPages(this.pendingExitOrdersPageNo, false);
          // let pendingExitOrders = this.webSocketData.PENDING_EXIT_FUTURES_ORDER.orderList;
          // this.handleOrderList(pendingExitOrders);
          // this.pendingExitOrdersPageNo = 1;
          // this.pendingExitOrdersTotalElements = this.webSocketData.PENDING_EXIT_FUTURES_ORDER.totalElements;
          // this.pendingExitOrdersTotalPage = Math.ceil(this.pendingExitOrdersTotalElements/this.pageSize);
          // this.pendingExitOrders = pendingExitOrders;
          isOrderData = true;
        }
        if (this.webSocketData.SUBMITTED_FUTURES_ORDER) {
          this.getSubmittedOrdersByPages(this.submittedOrdersPageNo, false);
          // let submittedOrders = this.webSocketData.SUBMITTED_FUTURES_ORDER.orderList;
          // this.handleOrderList(submittedOrders);
          // this.submittedOrdersPageNo = 1;
          // this.submittedOrdersTotalElements = this.webSocketData.SUBMITTED_FUTURES_ORDER.totalElements;
          // this.submittedOrdersTotalPage = Math.ceil(this.submittedOrdersTotalElements/this.pageSize);
          // this.submittedOrders = submittedOrders;
          isOrderData = true;
        }
        if (this.webSocketData.UNFILLED_FUTURES_ORDER) {
          this.getUnfilledExchangeOrdersByPages(this.unfilledExchangeOrdersPageNo, false);
          // let unfilledOrders = this.webSocketData.UNFILLED_FUTURES_ORDER.orderList;
          // this.handleOrderList(unfilledOrders);
          // this.unfilledExchangeOrdersPageNo = 1;
          // this.unfilledExchangeOrdersTotalElements = this.webSocketData.UNFILLED_FUTURES_ORDER.totalElements;
          // this.unfilledExchangeOrdersTotalPage = Math.ceil(this.unfilledExchangeOrdersTotalElements/this.pageSize);
          // this.unfilledExchangeOrders = unfilledOrders;
          isOrderData = true;
        }
        if (this.webSocketData.FILLED_FUTURES_ORDER) {
          this.getFilledExchangeOrdersByPages(this.filledExchangeOrdersPageNo, false);
          // let filledOrders = this.webSocketData.FILLED_FUTURES_ORDER.orderList;
          // this.handleOrderList(filledOrders);
          // this.filledExchangeOrdersPageNo = 1;
          // this.filledExchangeOrdersTotalElements = this.webSocketData.FILLED_FUTURES_ORDER.totalElements;
          // this.filledExchangeOrdersTotalPage = Math.ceil(this.filledExchangeOrdersTotalElements/this.pageSize);
          // this.filledExchangeOrders = filledOrders;
          isOrderData = true;
        }
        if (this.webSocketData.OPEN_POSITIONS_FUTURES_ORDER) {
          this.getOpenPositionsOrdersByPages(this.openPositionsOrdersPageNo, false);
          // let openPositionsOrders = this.webSocketData.OPEN_POSITIONS_FUTURES_ORDER.orderList;
          // this.handleOrderList(openPositionsOrders);
          // this.openPositionsOrdersPageNo = 1;
          // this.openPositionsOrdersTotalElements = this.webSocketData.OPEN_POSITIONS_FUTURES_ORDER.totalElements;
          // this.openPositionsOrdersTotalPage = Math.ceil(this.openPositionsOrdersTotalElements/this.pageSize);
          // this.openPositionsOrders = openPositionsOrders;
          isOrderData = true;
        }
        if (this.webSocketData.OPEN_POSITIONS_AT_EXCHANGES_FUTURES_ORDER) {
          this.getOpenPositionsAtExchanges();
          isOrderData = true;
        }
        if (isOrderData) {
          // 是订单数据，才清除webSocketData，否则可能是其他数据
          this.$store.dispatch('webSocketDataHandler', null);
        }
      },
      // 获取基础货币
      getBaseCurrency(symbol) {
        if (symbol) {
          return symbol.replace(/PI_/, '').replace(/USD/, '');
        }
        return symbol;
      },
      // 查询本地缓存
      getLocalStorage() {
        let hideNonePositions = localStorage.getItem('iobots-hideFuturesNonePositions');
        if (hideNonePositions) {
          this.hideNonePositions = hideNonePositions == 1;
        } else {
          localStorage.setItem('iobots-hideFuturesNonePositions', 0);
        }
        let childComponent = localStorage.getItem('iobots-futuresChildComponent');
        if (childComponent) {
          this.childComponent = childComponent;
        } else {
          localStorage.setItem('iobots-futuresChildComponent', 'candle');
        }
      },
      // 获取价格跳点
      getTickSize(symbol) {
        if (symbol) {
          return Number(Math.pow(10, -this.futuresDecimalNumbers[symbol]).toFixed(this.futuresDecimalNumbers[symbol]));
        }
        return 1;
      },
      // 一键全部平仓
      killAllOpenPositions() {
        this.killAllOpenPositionsLoading = true;
        let api = config.baseUrl + '/order/futures/private/killAll';
        this.$http.delete(api).then(response => {
          let data = response.data;
          this.killAllOpenPositionsLoading = false;
          this.killAllOpenPositionsDialog = false;
          this.$store.dispatch('snackbarMessageHandler', data.message);
          let countdown = 15;
          this.killAllOpenPositionsTimer = setInterval(() => {
            this.killAllOpenPositionsCountdown = ' ' + countdown + 'S ';
            countdown--;
            if (countdown < 0) {
              this.clearIntervalKillAllOpenPositionsTimer();
            }
          }, 1000);
        }, error => {
          this.killAllOpenPositionsLoading = false;
          this.killAllOpenPositionsDialog = false;
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
        });
      },
      // 清除一键全部平仓倒计时定时器
      clearIntervalKillAllOpenPositionsTimer(){
        this.killAllOpenPositionsCountdown = null;
        if (this.killAllOpenPositionsTimer) {
          clearInterval(this.killAllOpenPositionsTimer);
          this.killAllOpenPositionsTimer = null;
        }
      },
      // 筛选一键平仓
      killOpenPositions() {
        this.killOpenPositionsLoading = true;
        let api = config.baseUrl + '/order/futures/private/kill?1=1';
        if (this.orderSearch.exchange) {
          api = api + '&exchange=' + this.orderSearch.exchange;
        }
        if (this.orderSearch.symbol) {
          api = api + '&symbol=' + this.orderSearch.symbol;
        }
        if (this.orderSearch.side) {
          api = api + '&side=' + this.orderSearch.side;
        }
        if (this.orderSearch.strategy) {
          api = api + '&strategy=' + this.orderSearch.strategy;
        }
        if (this.orderSearch.category) {
          api = api + '&category=' + this.orderSearch.category;
        }
        this.$http.delete(api).then(response => {
          let data = response.data;
          this.killOpenPositionsLoading = false;
          this.killOpenPositionsDialog = false;
          this.$store.dispatch('snackbarMessageHandler', data.message);
          let countdown = 15;
          this.killOpenPositionsTimer = setInterval(() => {
            this.killOpenPositionsCountdown = ' ' + countdown + 'S ';
            countdown--;
            if (countdown < 0) {
              this.clearIntervalKillOpenPositionsTimer();
            }
          }, 1000);
        }, error => {
          this.killOpenPositionsLoading = false;
          this.killOpenPositionsDialog = false;
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
        });
      },
      // 清除一键全部平仓倒计时定时器
      clearIntervalKillOpenPositionsTimer(){
        this.killOpenPositionsCountdown = null;
        if (this.killOpenPositionsTimer) {
          clearInterval(this.killOpenPositionsTimer);
          this.killOpenPositionsTimer = null;
        }
      },
      // 接收改变的renko策略
      receiveChangeRenkoStrategy(strategy){
        if (strategy) {
          this.orderForm.strategy = strategy;
        }
      },
      // 接收的renko价格
      receiveRenkoPrice(price){
        if (price >= 0) {
          this.orderForm.price = price;
        }
      },
      // 刷新止盈和止损价
      receiveResetTakeAndStopPrice(){
        this.renkoChartTakeProfitPrice = 0;
        this.renkoChartStopLossPrice = 0;
      },
      // 输入订单价格
      enterOrderPrice(price){
        if (price >= 0) {
          this.orderForm.price = price;
        }
      },
      // 根据策略查询颜色
      getColorByStrategy(strategy){
        return this.strategyColors[strategy];
      },
      // 显示“平仓并开反向单”的弹窗
      showSARDialog(order){
        if (order) {
          this.sarOrder = order;
          this.sarDialog = true;
        }
      },
      // 显示“删除持仓订单”的弹窗
      showDeletePositionOrderDialog(futuresOrderId){
        if (futuresOrderId) {
          this.deletePositionFuturesOrderId = futuresOrderId;
          this.deletePositionOrderDialog = true;
        }
      },
      // 提交SAR请求
      submitSAR(){
        this.sarLoading = true;
        let api = config.baseUrl + '/order/futures/private/sar';
        let order = {
          id: this.sarOrder.id
        };
        this.$http.put(api, order).then(response => {
          let data = response.data;
          this.sarLoading = false;
          this.sarDialog = false;
          this.$store.dispatch('snackbarMessageHandler', data.message);
        }, error => {
          this.sarLoading = false;
          this.sarDialog = false;
          this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
        });
      },
      // 接收的KARA弹窗
      receiveKARADialog(karaDialog){
        this.karaDialog = karaDialog;
      },
      // 接收的KAR弹窗
      receiveKARDialog(karDialog){
        this.karDialog = karDialog;
      },
      // 接收的DeleteAllPendingEntry弹窗
      receiveDeleteAllPendingEntryDialog(deleteAllPendingEntryDialog){
        this.deleteAllPendingEntryDialog = deleteAllPendingEntryDialog;
      },
      // 接收的DeletePendingEntry弹窗
      receiveDeletePendingEntryDialog(deletePendingEntryDialog){
        this.deletePendingEntryDialog = deletePendingEntryDialog;
      },
      // 接受的DeletePositionOrder弹窗
      receiveDeletePositionOrderDialog(deletePositionOrderDialog){
        this.deletePositionOrderDialog = deletePositionOrderDialog;
      },
      // 改变价格类型
      changePriceType(action){
        switch(action){
          case 'new':
            // 新增订单
            if(this.orderForm.priceType == 'manually'){
              this.orderForm.priceType = 'bbo';
            } else {
              this.orderForm.priceType = 'manually';
            }
            break;
          case 'edit':
            // 编辑订单
            if(this.changedFuturesOrderForm.priceType == 'manually'){
              this.changedFuturesOrderForm.priceType = 'bbo';
            } else {
              this.changedFuturesOrderForm.priceType = 'manually';
            }
            break;
          default: break;
        }
      },
      // 发射Renko图表价格
      emitRenkoChartPrice(order){
        if (order == null) {
          return;
        }
        this.renkoChartTakeProfitPrice = order.takeProfitPrice;
        if (order.beFixedStopLossPrice) {
          // 固定止损
          this.renkoChartStopLossPrice = order.fixedStopLossPrice;
        } else {
          // 移动止损
          this.renkoChartStopLossPrice = order.stopLossPrice;
        }
      },
      // 查询放置的进退入订单的价格
      getOpenPrices(){
        let openLongPrices = [];
        let openShortPrices = [];
        // 放置的进入订单
        for(let i = 0, len = this.pendingEntryOrders.length; i < len; i++){
          let order = this.pendingEntryOrders[i];
          if (order.symbol == this.symbol) {
            if (order.side == 'open_long') {
              openLongPrices.push(order.price);
            } else if (order.side == 'open_short') {
              openShortPrices.push(order.price);
            }
          }
        }
        // 未成交的订单
        for(let i = 0, len = this.unfilledExchangeOrders.length; i < len; i++){
          let order = this.unfilledExchangeOrders[i];
          if (order.symbol == this.symbol) {
            if (order.side == 'open_long') {
              openLongPrices.push(order.price);
            } else if (order.side == 'open_short') {
              openShortPrices.push(order.price);
            }
          }
        }
        // 结果对象
        let result = {
          openLongPrices: openLongPrices,
          openShortPrices: openShortPrices
        };
        return result;
      },
      // 查询持仓的止盈和止损的价格
      getOpenPositionsPrices(){
        let entryLongPrices = [];
        let entryShortPrices = [];
        let takeProfitPrices = [];
        let stopLossPrices = [];
        for(let i = 0, len = this.openPositionsOrders.length; i < len; i++){
          let order = this.openPositionsOrders[i];
          if (order.symbol == this.symbol) {
            if (order.side == 'open_long') {
              entryLongPrices.push(order.price);
            } else if (order.side == 'open_short') {
              entryShortPrices.push(order.price);
            }
            if (order.takeProfitPrice > 0) {
              takeProfitPrices.push(order.takeProfitPrice);
            }
            if (order.beFixedStopLossPrice) {
              // 固定止损
              stopLossPrices.push(order.fixedStopLossPrice);
            } else {
              // 移动止损
              stopLossPrices.push(order.stopLossPrice);
            }
          }
        }
        // 结果对象
        let result = {
          entryLongPrices: entryLongPrices,
          entryShortPrices: entryShortPrices,
          takeProfitPrices: takeProfitPrices,
          stopLossPrices: stopLossPrices
        }
        return result;
      },
    },
    beforeDestroy() {
      this.$store.dispatch('documentTitleHandler', this.$t("common.documentTitle"));
    },
  }
</script>
<style scoped>
</style>