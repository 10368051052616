<template>
    <div>
        <!-- *******************删除持仓订单的弹窗******************* -->
        <v-row>
          <v-dialog v-model="deletePositionOrderDialog" persistent max-width="490" no-click-animation>
            <v-card>
              <v-card-title class="grey lighten-2">
                <span class="black--text font-md">{{$t("common.delete")}}</span>
                <v-spacer></v-spacer>
                <v-btn light icon @click="closeDeletePositionOrderDialog">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-container class="pb-0">
                <v-row justify="center">
                  <v-col cols="12">
                    <span>{{ $t('futuresTrading.deletePositionOrderMessage') }}</span>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="price" :label="$t('trading.deletePositionOrderPriceMessage')" ></v-text-field>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
              </v-container>
              <v-card-actions class="pr-5">
                <v-spacer></v-spacer>
                <v-btn small tile class="blue white--text" :loading="deleteLoading" @click="submitDelete">{{$t("common.ok")}}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
    </div>
</template>
<style>

</style>
<script>
    import Vue from 'vue'
    import config from '@/config.js';
    import { mapGetters } from "vuex";
    export default {
        data(){
            return{
              // 加载中
              deleteLoading: false,
              // 价格
              price: null
            }
        },
        props:[ 'deletePositionOrderDialog', 'deletePositionFuturesOrderId'],
        created(){

        },
        mounted(){

        },
        computed: {
          ...mapGetters(['exchanges']),
        },
        watch:{

        },
        methods: {
          // 提交请求
          submitDelete(){
            if (this.price == null || this.price <= 0) {
              this.$store.dispatch('snackbarMessageHandler', this.$t('trading.invalidPrice'));
              return;
            }
            this.deleteLoading = true;
            let api = config.baseUrl + '/order/futures/private/order/position/delete?futuresOrderId=' + this.deletePositionFuturesOrderId + '&price=' + this.price;
            this.$http.delete(api).then(response => {
              let data = response.data;
              this.closeDeletePositionOrderDialog();
              this.$store.dispatch('snackbarMessageHandler', data.message);
            }, error => {
              this.closeDeletePositionOrderDialog();
              this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
            });
          },
          // 关闭弹窗
          closeDeletePositionOrderDialog(){
            this.price = null;
            this.deleteLoading = false;
            this.$emit("closeDeletePositionOrderDialog", false);
          },
        },
    }
</script>
