<template>
    <div>
        <!-- *******************一键全平仓并开反向单的弹窗******************* -->
        <v-row>
          <v-dialog v-model="karaDialog" persistent max-width="490" no-click-animation>
            <v-card>
              <v-card-title class="grey lighten-2">
                <span class="black--text font-md">{{$t("common.killAndReverseAll")}}</span>
                <v-spacer></v-spacer>
                <v-btn light icon @click="closeKARADialog">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-container class="pb-0">
                <v-row justify="center">
                  <v-col cols="12">
                    <span>{{ $t('futuresTrading.karaMessage') }}</span>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
              </v-container>
              <v-card-actions class="pr-5">
                <v-spacer></v-spacer>
                <v-btn small tile class="blue white--text" :loading="karaLoading" @click="submitKARA">{{$t("common.ok")}}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
    </div>
</template>
<style>

</style>
<script>
    import Vue from 'vue'
    import config from '@/config.js';
    export default {
        data(){
            return{
              // 加载中
              karaLoading: false
            }
        },
        // karaDialog: false
        props:[ 'karaDialog' ],
        created(){

        },
        mounted(){

        },
        computed: {

        },
        watch:{

        },
        methods: {
          // 提交请求
          submitKARA(){
            this.karaLoading = true;
            let api = config.baseUrl + '/order/futures/private/kara';
            this.$http.put(api).then(response => {
              let data = response.data;
              this.closeKARADialog();
              this.$store.dispatch('snackbarMessageHandler', data.message);
            }, error => {
              this.closeKARADialog();
              this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
            });
          },
          // 关闭弹窗
          closeKARADialog(){
            this.karaLoading = false;
            this.$emit("closeKARADialog", false);
          }
        },
    }
</script>
